import Base64js from 'base64-js'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import Organization from '~/models/directories/Organization'
import TaxReports from '~/modules/reports/models/TaxReports'

const signPluginService = {
  mixins: [edsIitCheckboxDialog],
  methods: {
    async synchronizeOrganization (organization) {
      let afterSyncData = null
      await this.useEdsIitCheckboxDialog({
        pluginTitles: {
          options: this.$t('signPlugin.syncDataWithSfs')
        },
        hints: {
          options: this.$t('signPlugin.sync'),
          fileSign: this.$t('signPlugin.directorSign'),
          smartId: this.$t('signPlugin.smartId')
        },
        onConfirm: async ({ error, noReturn, sign, edsKeyData }) => {
          try {
            if (error) {
              return !noReturn ? this.synchronizeOrganization(organization) : null
            }

            const EDRPOUCode = this._.get(edsKeyData, 'edrpouCode') || this._.get(this.$Organization, 'edrpou', null)
            const DRFOCode = this._.get(edsKeyData, 'drfoCode') || this._.get(this.$Organization, 'edrpou', null)

            let signedData = null
            if (typeof sign === 'function') {
              const dataToSign = EDRPOUCode || DRFOCode
              signedData = await sign(dataToSign, {
                fallbackMethod: () => this.synchronizeOrganization(organization)
              })
            }
            if (!signedData) {
              return null
            }
            const payload = {
              EDRPOUCode,
              DRFOCode,
              sign: signedData
            }
            if (payload.sign) {
              afterSyncData = await this.$store._actions.synchronizeOrganization[0]({
                id: organization.id,
                data: {
                  sign: payload.sign,
                  EDRPOUCode,
                  DRFOCode
                }
              })
              await Organization.api().read(this._.get(this.$Organization, 'id', null))
            }
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
      return afterSyncData
    },
    async synchronizeTaxReports () {
      await this.useEdsIitCheckboxDialog({
        pluginTitles: {
          options: this.$t('signPlugin.syncDataWithSfs')
        },
        hints: {
          options: this.$t('signPlugin.sync'),
          fileSign: this.$t('signPlugin.directorSign'),
          smartId: this.$t('signPlugin.smartId')
        },
        onConfirm: async ({ error, noReturn, sign, edsKeyData }) => {
          try {
            if (error) {
              return !noReturn ? this.synchronizeTaxReports() : null
            }

            const EDRPOUCode = this._.get(edsKeyData, 'edrpouCode') || this._.get(this.$Organization, 'edrpou', null)
            const DRFOCode = this._.get(edsKeyData, 'drfoCode') || this._.get(this.$Organization, 'edrpou', null)

            let signedData = null
            if (typeof sign === 'function') {
              const dataToSign = EDRPOUCode || DRFOCode
              signedData = await sign(dataToSign, {
                fallbackMethod: this.synchronizeTaxReports
              })
            }
            if (!signedData) {
              return null
            }
            const payload = {
              EDRPOUCode,
              DRFOCode,
              sign: signedData
            }
            if (payload.sign) {
              await TaxReports.api().synchronization({
                sign: payload.sign,
                EDRPOUCode: EDRPOUCode || DRFOCode, // It's only for INDIVIDUALS
                DRFOCode: DRFOCode || EDRPOUCode // It's only for INDIVIDUALS
              })
            }
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
    },
    async sendEntityTaxOrder (params = {}) {
      try {
        const {
          entity,
          type,
          data,
          orderModel,
          model,
          showSuccessMessage = true,
          sendToDps = true,
          order
        } = params
        let createdOrder = null
        await this.useEdsIitCheckboxDialog({
          fallbackMethod: () => this.sendEntityTaxOrder(params),
          checkAuthService: true,
          createReportService: true,
          onConfirm: async ({ error, noReturn, reportService, fallbackMethod }) => {
            try {
              if (error) {
                return !noReturn ? this.sendEntityTaxOrder(params) : null
              }

              let successMessage = null
              const commonData = {
                HKBOS: reportService.getHKBOSField({
                  type,
                  entity: this._.get(orderModel, 'entity'),
                  sentry: this.$sentry,
                  organization: this.$Organization
                })
              }

              const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

              if (!re.test(commonData.HKBOS)) {
                this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
                return
              }

              if (!commonData.HKBOS) {
                this.$handlers.error(this.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), this)
                return
              }

              const payload = {
                ...commonData,
                ...data
              }

              if (type === 'removal') {
                createdOrder = this._.get(await orderModel.api().removeFromTax(payload), `entities.${orderModel.entity}[0]`, null)
                successMessage = `entityActions.${type}.${orderModel.entity}`
              } else if (type === 'modify') {
                createdOrder = this._.get(await orderModel.api().modifyInTax(payload), `entities.${orderModel.entity}[0]`, null)
                successMessage = `entityActions.${type}.${orderModel.entity}`
              } else if (type === 'keyReplacement') {
                createdOrder = this._.get(await orderModel.api().keyReplacement(payload), `entities.${orderModel.entity}[0]`, null)
                successMessage = `entityActions.${type}.${orderModel.entity}`
              } else if (type === 'sendToDps') {
                createdOrder = order
                successMessage = `entityActions.create.${orderModel.entity}`
              }

              if (sendToDps) {
                // Start sending to DPS
                const xmlResponse = await orderModel.api().xmlDoc(createdOrder)
                const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
                const fname = this._.get(xmlResponse, 'response.data.fname', null)
                const signData = await reportService.getEnvelopedData({
                  dataToSign: xml,
                  verifyKey: true,
                  fallbackMethod
                })
                if (!signData) {
                  this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
                  return null
                }
                const taxId = this._.get(await orderModel.api().taxRegister(createdOrder, { signData, fname }), 'response.data.response.taxId', null)
                const encodedTaxId = new TextEncoder().encode(String(taxId))
                const signedTaxId = await reportService.getEnvelopedData({
                  dataToSign: encodedTaxId,
                  isXml: false,
                  fallbackMethod
                })
                if (!signedTaxId) {
                  this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
                  return null
                }
                await orderModel.api().signTaxId(createdOrder, { tax_id: signedTaxId })
                // End sending to DPS
              }

              if (model && entity) {
                await model.api().read(this._.get(entity, 'id'))
              }

              if (showSuccessMessage) {
                this.$notification.success(successMessage)
              }
              return true
            } catch (e) {
              this.$handlers.error(e, this)
            }
          }
        })
        return createdOrder
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}

export default signPluginService
