export default {
  Add: 'Додати',
  'A help section is under development': 'Довідковий розділ знаходиться у розробці',
  'Select all': 'Вибрати всі',
  Actions: 'Дії',
  Activate: 'Активувати',
  Active: 'Активна',
  'Active ': 'Активний',
  Inactive: 'Неактивний',
  'Add outlet': 'Додати торгову точку',
  'Add row': 'Додати рядок',
  Additional: 'Додатковий {type}',
  All: 'Усі',
  'All groups': 'Всі групи',
  Amount: 'Кількість',
  Barcode: 'штрихкод | штрихкоду',
  Barcodes: 'Штрихкоди',
  Cancel: 'Скасувати',
  'Cancel selected': 'Скасувати вибір',
  'Card of': 'Картка',
  Change: 'Змінити',
  'Send eTTN by sms': 'Відправити чек по SMS',
  'Add comment to receipt': 'Додати коментар до чеку',
  'Connect SMS billing for eTTN': 'Підключіть відправку чека на SMS у налаштуваннях кабінету',
  'Change password': 'Зміна пароля',
  Cleanup: 'Очистити',
  'Net turnover': 'Чистий обіг',
  'Data from Z-reports': 'Дані по Z-звітам',
  'Actual revenue': 'Фактична виручка',
  Code: 'Код',
  Comment: 'Коментар',
  Confirm: 'Підтвердити',
  'Confirm password': 'Підтвердження пароля',
  'Confirmation of password': 'Підтвердження пароля',
  Continue: 'Продовжити',
  'Create receipt': 'Видати чек',
  'Creating of': 'Створення',
  Date: 'Дата',
  Deactivate: 'Деактивувати',
  'Depositsign login': 'Логін користувача в DepositSign',
  'Depositsign password': 'Пароль в Depositsign',
  'Depositsign client id': 'ClientId в Depositsign',
  'DRFO Code': 'ДРФО код',
  'Password for depositsign key': 'Пароль для ключа в Depositsign',
  delete: 'видалити',
  Description: 'Опис',
  District: 'Район',
  Download: 'Завантажити',
  'E-mail': 'E-mail',
  'E-mail (login)': 'Ел. пошта (логін)',
  edit: 'редагувати',
  'Editing of': 'Редагування',
  Employee: 'Касира',
  'Employee data': 'дані касира',
  Employees: 'Касири',
  Error: 'Помилка',
  'Export to CSV': 'Експорт у CSV',
  'Key expire date': 'Ключ дійсний до',
  'First name': "Ім'я",
  'Forgot password?': 'Забули пароль?',
  'Forgot your password?': 'Забули свій пароль?',
  'Full name': 'ПІБ',
  'Group taxes': 'Податкова група',
  Goods: 'Товари',
  Good: 'Товар | товару',
  'Good subtitle': 'Тут ви можете створити список товарів/послуг, щоб швидко їх додавати до чеку в меню “Продаж”',
  Group: 'Група',
  'Help section': 'Довідковий розділ',
  'Import to CSV': 'Імпорт у CSV',
  Individual: 'Фізична особа',
  'Last name': 'Прізвище',
  'Legal entity': 'Юридична особа',
  'Loading... Please wait': 'Завантаження... Зачекайте, будь ласка',
  Login: 'Вхід',
  'Login ': 'Логін',
  'Main menu': 'Головне меню',
  Name: 'Найменування',
  'New group': 'Нова група',
  'New password': 'Новий пароль',
  No: 'Ні',
  'No items were found': 'Елементи не знайдені',
  Number: 'Номер',
  'Only view': 'Тільки перегляд',
  Other: 'Інше',
  Outlet: 'торгова точка | торгову точку | торгової точки',
  'Outlet ': 'торгову точку',
  Outlets: 'Торгові точки',
  outlet: 'торгова точка',
  Password: 'Пароль',
  'Password successfully updated!': 'Пароль успішно оновлено!',
  Payments: 'Платежі',
  Permissions: 'Дозволи',
  Phone: 'Телефон',
  Price: 'Ціна',
  Print: 'Роздрукувати',
  Profile: 'Профіль',
  'Profile successfully updated!': 'Налаштування профілю успішно збережено',
  Region: 'Область',
  'Registration address': 'Адреса реєстрації',
  Report: 'Звіт',
  Reports: 'Звіти',
  Return: 'Повернення',
  Role: 'Роль | Ролі',
  'Role Name': 'Назва ролі',
  Roles: 'Ролі',
  'Rows per page': 'Кількість записів на сторінці',
  Save: 'Зберегти',
  Search: 'Пошук',
  Select: 'Обрати',
  Send: 'Надіслати',
  'Setting of password': 'Встановлення пароля',
  Setup: 'Налаштування',
  Status: 'Статус',
  'Table is empty': 'У поданій таблиці немає даних',
  Tax: 'податкову ставку | податкової ставки',
  Label: 'Мітка',
  'Tax name': 'Назва податку',
  'Tax rate': 'Податкова ставка',
  'Tax ': 'податкову ставку',
  'Extra rate': 'Додатковий збір',
  Taxes: 'Податкові ставки',
  Edit: 'Редагувати',
  'To login page': 'На сторінку входу',
  'To access the settings of any element, click on the lock.': 'Для відкриття доступу до налаштувань будь-якого елемента натиснути на замок.',
  Uktzed: 'УКТ ЗЕД',
  'Update password': 'Оновити пароль',
  User: 'Користувач',
  Users: 'Користувачі',
  Admin: 'Адміністратор',
  Restorations: 'Відновлення',
  'Restorations organization': 'Відновлення організації',
  'Visited at': 'Останній візит',
  Yes: 'Так',
  'You are successfully authorized!': 'Ви успішно авторизовані!',
  'You are successfully registered!': 'Ви успішно зареєстровані!',
  'Your password was successfully set, please log in with your new password.': 'Ваш пароль було успішно встановлено, будь ласка, авторизуйтесь використовуючи ваш новий пароль.',
  choose: 'Обрати',
  YES_RECEIPT: 'Виданий',
  NO_RECEIPT: 'Невиданий',
  WAITING_PAYMENT: 'Платіж ще неоплачений',
  'Import operation has not yet been completed': 'Операція імпорту ще не виконана',
  'Export operation has not yet been completed': 'Операція експорту ще не виконана',
  'Delete operation has not yet been completed': 'Операція видалення ще не виконана',
  'Unknown operation': 'Невідома операція',
  'Are you sure you want to leave this page? Unsaved data may be lost during import.': 'Ви впевнені, що хочете залишити цю сторінку? Незбережені дані можуть бути втрачені під час імпорту.',
  'Are you sure you want to leave this page? Unsaved data may be lost during export.': 'Ви впевнені, що хочете залишити цю сторінку? Незбережені дані можуть бути втрачені під час експорту.',
  'Are you sure you want to leave this page? Unsaved data may be lost during deletion.': 'Ви впевнені, що хочете залишити цю сторінку? Незбережені дані можуть бути втрачені під час видалення.',
  'Are you sure you want to leave this page? Unsaved data may be lost': 'Ви впевнені, що хочете залишити цю сторінку? Незбережені дані можуть бути втрачені.',
  Leave: 'Покинути сторінку',
  'Oops, something went wrong': 'Упс, щось пішло не так',
  'The previous import operation has not yet been completed. Please wait for it to complete and try again.': 'Попередня операція імпорту ще не завершена. Будь ласка, зачекайте її завершення і спробуйте ще раз.',
  'The previous export operation has not yet been completed. Please wait for it to complete and try again.': 'Попередня операція експорту ще не завершена. Будь ласка, зачекайте її завершення і спробуйте ще раз.',
  'The previous deletion operation has not yet been completed. Please wait for it to complete and try again.': 'Попередня операція видалення ще не завершена. Будь ласка, зачекайте її завершення і спробуйте ще раз.',
  'The previous operation has not yet been completed. Please wait for it to complete and try again.': 'Попередня операція ще не завершена. Будь ласка, зачекайте її завершення і спробуйте ще раз.',
  'The system uploads goods': 'Система завантажує товари',
  'The system prepares a file for export': 'Система готує файл для експорту',
  'The system deletes goods': 'Система видаляє товари',
  'This may take a few minutes if there are a lot of goods': 'Це може зайняти кілька хвилин, якщо товарів багато',
  'This may take a few minutes if there are a lot of goods. Once complete, the file will be automatically downloaded to your device': 'Це може зайняти кілька хвилин, якщо товарів багато. Після завершення файл автоматично завантажиться на ваш пристрій',
  'This may take some time.': 'Це може зайняти деякий час.',
  'The file for export is ready': 'Файл для експорту готовий',
  'Goods have been uploaded': 'Товари завантажено',
  'Goods have been successfully deleted': 'Товари успішно видалені',
  'The operation is completed': 'Операція виконана',
  'You can continue working with exported goods': 'Ви можете продовжити роботу з експортованими товарами',
  'You can continue working with the goods': 'Ви можете продовжити роботу з товарами',
  'You can continue working': 'Ви можете продовжити роботу',
  'Please wait, the system is preparing the file for import': 'Зачекайте, система готує файл для імпорту',
  'Please wait, the system is preparing the file for export': 'Зачекайте, система готує файл для експорту',
  'Please wait, the system is loading the goods': 'Зачекайте, система завантажує товари',
  'The file for import is ready': 'Файл для імпорту готовий',
  'Goods successfully deleted': 'Товари успішно видалено',
  'e-input-dynamic': {
    header: 'Максимальна кількість рядків в шапці чека {max}',
    footer: 'Максимальна кількість рядків в підвалі чека {max}'
  },
  employee: 'Касир',
  good: 'Товар',
  'm-orm-assign-dialog': {
    't-part-1': 'Призначення',
    't-part-2': 'для',
    'n-part-1': 'Призначені',
    'n-part-2': 'для',
    title: '@:m-orm-assign-dialog.t-part-1 {whom} @:m-orm-assign-dialog.t-part-2 {type} "{name}"',
    notification: '@:m-orm-assign-dialog.n-part-1 {whom} були успішно оновлені!'
  },
  'm-orm-card-dialog': {
    title: 'Картка товару "{name}"'
  },
  'm-orm-view-dialog': {
    title: '{type} {name}'
  },
  'm-orm-delete-dialog': {
    't-part-1': 'Ви дійсно бажаєте видалити',
    't-part-2': 'з довідника?',
    title: '@:m-orm-delete-dialog.t-part-1 {type} "{name}"',
    'n-part-1': 'Ви видалили',
    notification: '@:m-orm-delete-dialog.n-part-1 {type} "{name}"!'
  },
  'm-orm-activate-dialog': {
    't-part-1': 'Ви дійсно бажаєте активувати',
    't-part-2': 'Ви дійсно бажаєте розморозити',
    title: '@:m-orm-activate-dialog.t-part-1 {type} "{name}"',
    'n-part-1': 'Ви активували',
    't-text-1': 'Бачимо, що наразі ваш акаунт вже в друге потребує розблокування. Просимо вас сплатити рахунок протягом 5 днів, інакше зробити розблокування в третій раз вже буде не можливо, на жаль',
    't-text-2': 'Бачимо, що ваш акаунт потребує розблокування вже втретє. Підкажіть, можливо у вас виникають якісь труднощі, повʼязані з оплатою? Чи можемо ми вам якось допомогти? з приводу розблокування - мені потрібно звернутися за уточненнями, чи можливо це зробити, чи зручно вам бути зачекати?',
    notification: '@:m-orm-activate-dialog.n-part-1 {type} "{name}"!',
    defrost: '@:m-orm-activate-dialog.t-part-2 "{name}"?'
  },
  'm-orm-deactivate-dialog': {
    't-part-1': 'Ви дійсно бажаєте деактивувати',
    title: '@:m-orm-deactivate-dialog.t-part-1 {type} "{name}"',
    'n-part-1': 'Ви деактивували',
    notification: '@:m-orm-deactivate-dialog.n-part-1 {type} "{name}"!'
  },
  'm-orm-reset-pincode-dialog': {
    title: 'Ви дійсно бажаєте скинути пінкод для касира?',
    notification: 'Пінкод для касира було змінено на {pinCode}'
  },
  'm-orm-reset-password-dialog': {
    title: 'Скинути пароль для {type} "{name}"',
    notification: 'Пароль  для касира було змінено'
  },
  'm-orm-copy-dialog': {
    't-part-1': 'Створити копію',
    title: '@:m-orm-copy-dialog.t-part-1 {type} "{name}" ?',
    'n-part-1': 'Копія ',
    'n-part-2': 'була успішно створена!',
    notification: '@:m-orm-copy-dialog.n-part-1 {type} "{name}" @:m-orm-copy-dialog.n-part-2'
  },
  'm-orm-synchronization-dialog': {
    title: 'Синхронізувати всі дані на робочому місці?',
    part1: 'Увага!',
    part2: 'Дана операція призначена для першого запуску робочого місця. Виконуйте тільки, якщо впевнені у своїх діях',
    part3: 'При неправильному використанні операція призведе до задвоення даних на робочому місці'
  },
  'm-orm-toggle-status-dialog': {
    't-deactivation-part-1': 'Ви впевнені, що бажаєте деактивувати',
    't-activations-part-1': 'Ви впевнені, що бажаєте активувати',
    't-part-3': 'зі всіма вкладеними',
    title: {
      deactivation: '@:m-orm-toggle-status-dialog.t-deactivation-part-1 {type} "{name}" @:m-orm-toggle-status-dialog.t-part-3 {children}?',
      activation: '@:m-orm-toggle-status-dialog.t-activations-part-1 {type} "{name}" @:m-orm-toggle-status-dialog.t-part-3 {children}?'
    },
    'n-deactivated-part-1': 'Ви деактивували',
    'n-activated-part-1': 'Ви активували',
    notification: {
      deactivation: '@:m-orm-toggle-status-dialog.n-deactivated-part-1 {type} "{name}"!',
      activation: '@:m-orm-toggle-status-dialog.n-activated-part-1 {type} "{name}"!'
    }
  },
  'new document': 'новий документ',
  user: 'Користувача',
  'was created!': 'було створено!',
  'was deactivated!': 'було деактивовано!',
  'was updated!': 'було оновлено!',
  '{0}-{1} of {2}': '{0}-{1} з {2}',
  'Currency selection for the directory': 'Вибір валют для довідника',
  'Frequently used units': 'Часто вживані одиниці виміру',
  'Other units': 'Інші одниці виміру',
  'Hide other units': 'Приховати інші одиниці виміру',
  'Show other units': 'Показати інші одиниці виміру',
  Organizations: 'Організації',
  Organization: 'Організацію',
  organization: 'організація',
  'Organization ': 'Організація',
  users: 'Користувачі',
  Edrpou: 'ЄДРПОУ',
  EDRPOU: 'ЄДРПОУ',
  'Tax number': 'Податковий номер',
  Owner: 'Власник',
  Director: 'Директор',
  DRFOCode: 'ІПН',
  'Create organization': 'Створити організацію',
  'Create owner': 'Створити власника',
  'Public key id': 'ID сертифікату ЕЦП',
  'Short name': 'Коротка назва',
  Next: 'Далі',
  'reset password': 'Скинути пароль',
  deactivate: 'деактивувати',
  activate: 'активувати',
  'My organization': 'Моя організація',
  'My organizations': 'Мої організації',
  'Synchronize with DPS': 'Синхронізувати дані з ДПС',
  'Certificate id': 'Ідентифікатор сертифікату',
  'Organization successfully synchronized': 'Синхронізовано: <br/> ТТ - {outletsSync} (нових - {outletsNew}, деактивовано - {outletsDeactivated}), <br/> кас - {cashRegisters}, <br/> касирів - {employees}',
  'Organization data': 'Дані організації',
  'Cash management': 'Управління касами',
  Cashier: 'Касир',
  Address: 'Адреса',
  'Fiscal number': 'Фіскальний номер',
  type: 'Тип',
  Type: 'Тип',
  'Cash registers': 'Каси',
  'Cash register': 'Касу | Каса',
  'Cash register ': 'Каса',
  'License key': 'Ключ ліцензії',
  Shifts: 'Зміни',
  Shift: 'Зміни',
  Receipts: 'Чеки',
  Receipt: 'Чек',
  'Shift number': 'Номер зміни',
  'Opening date': 'Дата відкриття',
  'Closing date': 'Дата закриття',
  'EDS keys': 'Ключі ЕЦП',
  Payment: 'Оплата',
  'Receipt count': 'Кількість чеків',
  Selling: 'Продаж',
  'Date/time': 'Дата/час',
  'Create cash register': 'Створення каси',
  active: 'активна',
  'Signature type': 'Тип підпису',
  'Report type': 'Тип звіту',
  'Authorize in SFS': 'Авторизація в ДПС',
  'reset pin code': 'Скинути пінкод',
  Reset: 'Скинути',
  'Pin code ': 'Пін-код',
  'order ': 'заявку',
  Mode: 'Вид',
  'Send to sfs': 'Відправити в ДПС',
  'Error when sending to tax. Try again later': 'Помилка в дпс. Спробуйте пізніше',
  'Total Amount': 'Сума',
  View: 'Переглянути',
  'Prro type': 'Тип ПРРО',
  'Read data from key': 'Зчитати з ключа ЕЦП',
  or: 'або',
  'API error please contact the manager': 'Помилка серверу, будь ласка зв\'яжіться з менеджером',
  'The requested resource does not exist': 'Запитуваний ресурс не існує',
  'Employee ': 'касира',
  'Sale type': 'Тип продажу',
  'Tax rates': 'Податкові ставки',
  'The USREOU codes do not match. Please choose another': 'Коди ЄДРПОУ не співпадають. Оберіть інший ключ',
  'Outlet  ': 'Торгова точка',
  'Update data': 'Оновити дані',
  doc_saved_kvt_1: 'На реєстрації в податковій',
  awaiting_send_to_tax: 'Очікується відправка в ДПС',
  doc_adopted_kvt_2: 'Зареєстровано в ДПС',
  error_from_tax_kvt_2: 'Помилка реєстрації в ДПС',
  error: 'Помилка реєстрації в ДПС',
  'Successfully sent to Tax': 'Успішно відправлено в ДПС',
  Koatuu: 'Код КОАТУУ',
  'Street and number': 'Вулиця та номер будинку',
  'State object': 'Стан об\'єкту оподаткування',
  'Type of rights': 'Вид права на об’єкт оподаткування',
  'Type object': 'Тип об\'єкту оподаткування',
  'Tax code': 'Код ДПІ',
  'Please login as cashier': 'Авторизація касира',
  'Person type': 'Тип платника',
  individual: 'Фізична особа підприємець',
  juridical: 'Юридична особа',
  Auth: 'Авторизація',
  Xreport: 'Х-звіт',
  Zreport: 'Z-звіт',
  'Date of created': 'Дата створення',
  'Registration number': 'Реєстраційний номер',
  'Date of accounting': 'Дата взяття на облік',
  'Select dpi': 'Вибір відділення ДПС',
  'Data successfully updated': 'Дані успішно оновлено',
  entitySynchronization: {
    employee: 'Синхронізовано {count} касирів (нових: {new}, деактивовано: {deactivated})',
    cashregisters: 'Синхронізовано {count} кас (нових: {new}, деактивовано: {deactivated})',
    outlet: 'Синхронізовано {count} торгових точок (нових: {new}, деактивовано: {deactivated})',
    promocashregisters: 'Синхронізовано {count} РРО (нових: {new})'
  },
  'Error occurred. Try again later or contact with administrator': 'Сталася помилка. Спробуйте пізніше або зв\'яжіться з адміністратором',
  'Copied to clipboard': 'Скопійовано в буфер обміну',
  'Cashier type': 'Тип касира',
  'Public key ID': 'Ідентифікатор ключа',
  ShiftOpen: 'Зміна відкрита',
  CashIn: 'Внести готівку',
  CashOut: 'Видати готівку',
  Close: 'Закрити',
  serviceReceipt: {
    cashIn: {
      title: 'Внести готівку',
      label: 'Сума внесення',
      btnText: 'Внести',
      successNotification: 'Заявка на службове внесення {amount} грн відправлена',
      approvedNotification: 'Службове внесення {amount} грн здійснено',
      rejectedNotification: 'Службове внесення {amount} грн не здійснено'
    },
    cashOut: {
      title: 'Видати готівку',
      label: 'Сума винесення',
      btnText: 'Видати',
      successNotification: 'Заявка на службове винесення {amount} грн відправлена',
      approvedNotification: 'Службове винесення {amount} грн здійснено',
      rejectedNotification: 'Службове винесення {amount} грн не здійснено'
    }
  },
  Letter: 'Літера',
  'Tax rate code': 'Код податку',
  'Tax rate (Only for VAT)': 'Податкова ставка (Для ПДВ)',
  'Extra rate (excise duty)': 'Додатковий збір (Акцизний збір)',
  'Tax code (Enter manually)': 'Унікальний код податку (вкажіть самостійно)',
  'Fiscal date': 'Дата фіскалізації',
  'No account': 'Немає акаунта',
  'To register': 'Зареєструватися',
  'Enter your phone, email and come up with a password': 'Введіть ваш телефон, електронну пошту та придумайте пароль',
  'Public offer': 'Публічної оферти',
  'By clicking': 'Натискаючи',
  'you agree to the terms of the': 'ви погоджуєтесь з умовами',
  'Thank you': 'Дякуємо',
  'we received the following data from the SFS': 'ми отримали в ДПС наступні дані',
  'Get data automatically': 'Отримати автоматично',
  'Company information': 'Дані про компанію',
  'not to enter information manually': 'Щоб не вводити інформацію вручну, давайте отримаємо її автоматично за допомогою ЕЦП(КЕП). Цю дію треба підтвердити електронно-цифровим підписом.',
  'Choose how to generate checks': 'Оберіть спосіб формування чеків, який підходить саме для вас',
  'can be changed in the future': 'в майбутньому можна змінити',
  'Web-page or mobile application': 'Web-сторінка або мобільний додаток',
  'Ready-made solution for small businesses': 'Ідеально для малого бізнесу — коли кількість чеків невелика або немає часу на інтеграцію',
  'checks per month': 'до {count} чеків / міс. | більше {count} чеків / міс.',
  'Completion of registration': 'Завершення реєстрації',
  'Go to the portal and start working with the most convenient software checkouts': 'Переходьте на портал, та починайте роботу з найзручнішими програмними касами',
  'Select Uktzed code': 'Виберіть код УКТ ЗЕД',
  City: 'Місто',
  'Upload from file csv': 'Імпортувати з CSV файлу',
  'Import new goods from csv file': 'Імпорт нових товарів з csv файлу',
  'Select csv file': 'Оберіть csv файл',
  'Imported goods': 'Створено/оновлено товарів',
  'Integration with the checkbox API': 'Інтеграція через API Checkbox',
  'Suitable for online shopping and retail': 'Якщо вам потрібна якась особлива інтеграція — наприклад, для Інтернет-магазинів або власних програмних продуктів',
  'Checkbox checkout': 'Інтеграція з вашою системою без програмування',
  'Suitable for retail': 'Якщо ви вже користуєтесь якоюсь фронтальною системою, то інсталяція Checkbox_Каса повністю замінить для вас касовий апарат. Це легко зробити без програмістів, а ваші співробітники продовжуть працювати у звичному інтерфейсі',
  'Organization exists': 'Організація з цим ЄДРПОУ вже зареєстрована',
  'File fields': 'Поля файлу',
  'Code (article)': 'Код (артикул)',
  required: 'обов`язкове',
  'Price (uah)': 'Ціна (грн)',
  'can be empty': 'може бути пустим',
  'Barcode ': 'Штрихкод',
  'Values must be separated by ";"': 'Використовуйте роздільник “;”',
  'First row is header': 'Перша строка - заголовок',
  'Refund goods': 'Повернення товару',
  'E. mail': 'Ел. пошта',
  'Please log in': 'Будь ласка, виконайте вхід',
  'The amount of transactions for the current year': 'Сума операцій за поточний рік',
  Checks: 'Чеків',
  yesterday: 'вчора',
  today: 'сьогодні',
  'Operations return for period': 'Оборот за період',
  total: 'всього',
  opened: 'відкрито',
  Welcome: 'Вітаємо',
  'follow a few simple steps to get started': 'виконайте декілька простих кроків, щоб почати роботу',
  'To create': 'Створіть',
  Created: 'Cтворена | Cтворен | Cтворено',
  'The cash register will be': 'Каса буде',
  'added to the outlet': 'додаватись до торгової точки',
  'registered with the SFS': 'зареєстрована в ДПС',
  'To add': 'Додайте',
  Added: 'Додана | Додан | Додано',
  'Added ': 'Доданий',
  Installed: 'Iнстальована | Iнстальован | Iнстальовано',
  'Requires EDS cashier or el. seal of the organization': 'Потрібний ЕЦП касира або ел. печатка організації',
  'To install': 'Інсталюйте',
  Checkbox_cashbox: 'Checkbox_Каса',
  Checkbox_signature: 'Checkbox_Підпис',
  'For automatic signature': 'Для автоматичного підпису',
  'For formation': 'Для формування',
  'If you are a VAT or excise tax payer': 'Якщо ви платник ПДВ чи акцизного податку',
  Specify: 'Вкажіть',
  Specified: 'Вказана | Вказан | Вказано',
  Instructions: 'Інструкція',
  'Instructions ': 'Інструкції',
  'Implement the interaction with the API': 'Реалізуйте взаємодію з API',
  'To interact with Checkbox via API': 'Для взаємодії з Checkbox по API',
  'API interaction implemented': 'Взаємодію з API реалізовано',
  'All is good': 'Все добре',
  'All steps have been successfully completed. Now you can use the service in full.': 'Усi кроки успiшно виконанi. Тепер ви можете користуватися сервiсом у повному обсязi.',
  'Detailed instructions are in the menu': 'Детальні інструкції знаходяться в меню',
  'Receipts archive': 'Архів чеків',
  'Work shifts': 'Робочі зміни',
  'My cash register': 'Моя каса',
  'Search receipt by FN': 'Пошук по фіскальному номеру',
  Count: 'Кількість',
  Cost: 'Вартість',
  'No receipt found. Check the correctness of the entered fiscal number': 'Чек не знайдено, перевірте правильність введеного фіскального номеру.',
  Refund: 'Повернути',
  'Add employee': 'Додати касира',
  'Come up with a cashier login': 'Придумайте логін касира',
  'Come up with a cashier password': 'Придумайте пароль касира',
  'Name of the cashier': 'Назва касира',
  'Choose the type of cashier': 'Оберіть тип касира',
  'Download the cashier\'s key or electronic seal': 'Завантажте ключ касира або електронну печатку, які будуть використовуватись для підпису',
  'This action must be signed by the EDS of the director': 'Ця дія має бути підписана ЕЦП директора',
  'Add goods': 'Додати товар',
  'Outlet name': 'Назва торгової точки',
  'Another type': 'Інший тип',
  'Add cash register': 'Додати касу',
  'We entered the number automatically, but you can change it': 'Ми вказали номер автоматично, але ви можете його змінити',
  'Before you add a cash register, add an outlet': 'Перед тим, як додати касу, будь ласка, додайте торгову точку',
  'Outlet type ': 'Тип торгової точки',
  'To update the information, please synchronize with the traffic police': 'Для оновлення інформації, будь ласка виконайте синхронізацію з ДПС',
  Click: 'Натисніть',
  here: 'тут',
  'if you use the ACSK Deposit Sign for the signature': 'якщо для підпису будете використовувати АЦСК Deposit Sign',
  'Please use the EDS key instead of seal': 'Будь ласка, використайте ключ ЕЦП замість печатки',
  'Specify the EDS key ID': 'Вкажіть ідентифікатор ключа ЕЦП',
  'How to find the key id –': 'Як знайти ідентифікатор ключа ЕЦП –',
  'To download': 'Завантажте',
  'Product list': 'Cписок товарів',
  'if you do not need to add goods': 'якщо товари додавати не потрібно',
  'Type of point of sale': 'Вид права на торгову точку',
  'Address (COATUU Code)': 'Адреса (Код КОАТУУ)',
  'Address (KATOTTG Code)': 'Адреса (Код за КАТОТТГ)',
  Choose: 'Виберіть',
  'For example, hotels': 'Наприклад, “Кафе Марія”',
  'For example, Zhylyanska street, 41a': 'Наприклад, вулиця Жилянська, 41а',
  'Own or rent': 'Володієте чи орендуєте',
  'Choose the one that suits you best': 'Оберіть той, що найбільше підходить вам',
  'State Tax Service': 'Державна податкова служба',
  'Choose the one that serves the area where the outlet is located': 'Оберіть ту, що обслуговує район, в якому знаходиться торгова точка',
  'It will be used for the cashier to enter the mobile application or on my.checkbox.in.ua': 'Буде використовуватись для входу касира в мобільний додаток або на my.checkbox.ua',
  'It will be used for the cashier to enter the mobile application or on': 'Буде використовуватись для входу касира в мобільний додаток або на',
  Hint: 'Підказка',
  'after 3-5 minutes update page or click the "Update Data" button to make sure': 'через 3-5 хвилин натисніть кнопку “Синхронізувати дані з ДПС” щоб переконатись, що',
  tableFooterHint: {
    'Cash register': 'каса зареєстрована | касу',
    'Employee ': 'касир зареєстрований | касира',
    Outlet: 'торгова точка зареєстрована | торгову точку'
  },
  'in sfs': 'в податковій',
  'Cash register number': 'Номер каси',
  'Shift already closed by other user. Try again.': 'Зміна закрита іншим користувачем. Спробуйте знову',
  'Unavailable to open shift. Please, contact with support': 'Неможливо відкрити зміну. Будь ласка зв\'яжіться з техпідтримкою',
  'Error when open shift:': 'Помилка при відкритті зміни:',
  'Error when close shift:': 'Помилка при закритті зміни:',
  'Error when get receipt:': 'Помилка при видачі чека:',
  'Close shift': 'Закрити зміну',
  'Do you really want to close the shift': 'Ви дійсно бажаєте закрити зміну?',
  'Do you really want to close the shift. You have not null balance.': 'Ви дійсно бажаєте закрити зміну? <br/>Ви маєте залишок на балансі: ',
  'Search in goods catalog': 'Пошук по каталогу товарів',
  'Sent to email': 'Відправити на e-mail',
  'Receive Cash': 'Отримано готівки',
  'Open shift': 'Відкрити зміну',
  cash: 'Готівка',
  card: 'Картка',
  'Z-report': 'Z-звіт',
  'X-report': 'X-звіт',
  'file example': 'зразок файлу',
  'Test product': 'Тестовой товар',
  Opening: 'Відкриття',
  Closing: 'Закриття',
  'Requested preview not exist. Please contact with manager.': 'Текстове представлення не знайдено. Будь ласка зв\'яжіться з адміністратором',
  'View receipt': 'Переглянути чек',
  'View receipts': 'Переглянути чеки',
  'View z-report': 'Переглянути z-звіт',
  'Choose region': 'Виберіть область',
  'Choose city': 'Виберіть місто',
  'Choose district': 'Виберіть район',
  'Choose territory': 'Виберіть територіальну громаду',
  'Choose settlement': 'Виберіть населений пункт',
  'Choose settlement district': 'Виберіть район населеного пункту',
  'For Windows': 'Для Windows',
  'For Linux i386': 'Для Linux i386',
  'For Linux x86_64': 'Для Linux x86_64',
  'Specify the letter that will be used to indicate the tax in the check, for VAT it is usually the letter A': 'Буде використовуватись для позначення податку в чеку, для ПДВ зазвичай це літера А',
  'Specify the tax rate, for example, - 20': 'Вкажіть розмір податкової ставки, наприклад, – 20',
  'Enter the name of the tax, for example, - VAT': 'Вкажіть назву податку, наприклад, – ПДВ',
  'Specify the amount of the additional fee, for example, for excise tax - 5': 'Вкажіть розмір додаткового збору, наприклад, для акцизного податку – 5',
  'Any value that will be passed from your front system': 'Довільне значення, яке буде передаватись з вашої фронт-системи',
  SERVICE_OUT: 'Службова видача',
  'SERVICE_OUT(Test receipt)': 'Службова видача (тестовий чек)',
  SERVICE_IN: 'Службове внесення',
  'SERVICE_IN(Test receipt)': 'Службове внесення (тестовий чек)',
  RETURN: 'Повернення',
  'RETURN(Test receipt)': 'Повернення (тестовий чек)',
  SELL: 'Продаж',
  'SELL(Test receipt)': 'Продаж (тестовий чек)',
  SERVICE_CURRENCY: 'Службовий чек валютообміну',
  CURRENCY_EXCHANGE: 'Чек валютообміну',
  'SERVICE_CURRENCY(Test receipt)': 'Службовий чек валютообміну (тестовий чек)',
  'CURRENCY_EXCHANGE(Test receipt)': 'Чек валютообміну (тестовий чек)',
  'sign inactive': 'Будь ласка, запустіть CheckboxПідпис, щоб працювати з касою',
  Okay: 'Добре',
  'Access denied': 'Доступ заборонено',
  'The point of sale is still registered in the tax': 'Торгова точка ще на реєстрації в податковій',
  'After 2-3 minutes, click "Update data"': 'Через 2-3 хвилини натисніть "Синхронізувати дані з ДПС" в меню торгові точки, ми отримаємо інформацію про реєстрацію торгової точки в ДПС. Після цього можна буде створити касу',
  'On your account': 'На вашому рахунку',
  'Next payment': 'Наступна оплата',
  before: 'до',
  uah: 'грн',
  'Cash registers invoice': 'Рахунок за каси',
  'Pay by card': 'Сплатити карткою',
  'Create an invoice': 'Сформувати рахунок',
  'Number of checks limit exceeded': 'Кількість перевищень ліміту чеків',
  'Number of cash registers': 'Кількість кас',
  Period: 'Період',
  'An error occurred while paying. Please try again or contact your administrator': 'При оплаті виникла помилка. Спробуйте ще раз або зв\'яжіться з адміністратором',
  'Thank you! Payment made successfully.': 'Дякуємо! Оплата здійснена успішно.',
  'm-dialog-no-cash-register-and-cashier': {
    sale: 'Перед тим як перейти до продажу, будь ласка, створіть касу та касира',
    refund: 'Перед тим як перейти до повернення товару, будь ласка, створіть касу та касира'
  },
  'Physical key error': 'Ми не знайшли зареєстрованої організації в податковій за цим ключем, спробуйте використати ключ ЕЦП, який прив’язаний до вашої організації',
  'Try with EDS key': 'Спробувати з іншим ключем ЕЦП',
  'Select type of sign receipt': 'Оберіть спосіб підпису чеків',
  'I run the Checkbox Signature software locally': 'Запускаю ПЗ CheckboxПідпис локально',
  'Load EDS key in browser': 'Завантажити ключ ЕЦП в браузер',
  'Selected key do not matched with selected cashier. Please select right EDS Key': 'Обраний ключ ЕЦП не відповідає обраному касиру. Будь ласка, оберіть правильний ключ ЕЦП',
  'You already have created Shift which need to sign. Please run sign agent to do it': 'Ви вже маєте створену зміну, яку потрібно підписати. Запустіть агент підпису для цього',
  'Something wrong with key': 'Щось не так з ключем ЕЦП, спробуйте використати ключ ЕЦП, який прив’язаний до вашої організації, або напишіть нам в підтримку',
  'Periodic report': 'Періодичний звіт',
  'Create report': 'Створити звіт',
  'Select date': 'Оберіть дату',
  'Select cash register': 'Оберіть касу',
  'How to register': 'Як зареєструвати',
  'Return to admin': 'Повернутися в адмінку',
  'Add one more organization': 'Додати ще одну організацію',
  dialogs: {
    information: {
      addOneMoreOrganization: 'Щоб не вводити дані про компанію вручну, ми отримаємо їх автоматично. Цю дію треба підтвердити ЕЦП організації, яку ви додаєте',
      youHaveOpenedShiftInPast: 'Ви маєте не закриту зміну у цього касира',
      youHaveShiftInOtherCashRegister: 'Обраний касир має відкриту зміну на іншій касі з ФН {shiftFn}. Для роботи з обраною касою, будь ласка, закрийте зміну на попередній.',
      loginOccupiedByAnotherUser: 'Логін "{login}" зайнятий іншим користувачем, рекомендуємо Вам логін "{freeLogin}"'
    }
  },
  'Select eds key': 'Оберіть ключ ЕЦП',
  'Organization not found in tax. Choose another key': 'Організація не знайдена в ДПС. Оберіть інший ключ',
  'Organization not found': 'Вказана оганізація не зареєстрована в системі',
  'Current user already owned this organization': 'Ви вже є власником даної організації',
  'Current user already manage this organization': 'Вказана організація вже э в списку Ваших організацій',
  'User is not a manager of this organization': 'Користувач не має доступу до вказаної організації',
  'Delete all goods': 'Видалити всі товари',
  'Shift closing confirmation': 'Підтвердження закриття зміни',
  'Confirmation cash register creating': 'Підтвердження створення каси',
  'Outlet without name': 'Ви намагаєтесь створити касу з торговою точкою, в якої не має поля “найменування”. Додайте поле “найменування” до торгової точки. Ви можете це зробити, відредагувавши її на сторінці \'Торгові точки\' або створити касу з торговою точкою в якій присутнє поле “найменування”.',
  'Are you sure to delete all goods': 'Підтверджуючи видалення, ви видаляєте не лише обрані товари, але й усі інші. Це безповоротна дія, яку неможливо скасувати.',
  Approve: 'Підтвердити',
  Dismiss: 'Відхилити',
  'The number of deleted goods is ': 'Кількість видалених товарів - ',
  Video: 'Відео',
  'Payment ': 'Платіж | Платежу',
  'Purpose of payment': 'Призначення платежу',
  Payer: 'Платник',
  'Payment code': 'Платіжний код',
  'Old payment code': 'Старий платіжний код',
  'Set discount amount': 'Вкажіть розмір знижки',
  Discount: 'Знижка',
  'Select payment type': 'Оберіть спосіб оплати',
  'Payment type': 'Спосіб оплати',
  Sum: 'Сума',
  bonus: 'Бонуси',
  credit: 'Кредит',
  'The amount on the account': 'Сума на рахунку',
  'Billing day': 'Білінговий день',
  'Cash registers amount': 'Кількість кас',
  'Promo code purpose': 'Призначення',
  Applied: 'Застосований',
  Invoice: 'Рахунок',
  Invoices: 'Рахунки',
  'Issue checks in the sales menu': 'Видавайте чеки в меню продаж',
  'Checks issued': 'Чеки видані',
  'Open the shift and issue checks': 'Відкрийте зміну та видавайте чеки',
  'Licence key QR code': 'QR код ключа ліцензії',
  'Select employee': 'Оберіть касира',
  'Upload a signature to your browser from the Sale menu': 'Завантажте підпис у браузер в меню "Продаж"',
  'Select type of closing shift': 'Оберіть спосіб закриття зміни',
  'Create receipt...': 'Видача чека...',
  'Shift is not opened. Please, open, the shift that to create the receipt': 'Зміна не відкрита. Відкрийте, будь ласка, зміну для того, щоб видати чек.',
  'Shift closed successfully': 'Зміна успішно закрита',
  'Billing status': 'Статус білінгу',
  'Resume work': 'Відновити роботу',
  'Unavailable to close shift. Please, contact with support': 'Неможливо закрити зміну. Спробуйте пізніше або зв\'яжіться з адміністратором',
  'Email for invoices': 'Ел. пошта для рахунків',
  'Phone for support': 'Телефон для служби підтримки',
  'Receipts report': 'Звіт по чекам',
  'Automatic change closure': 'Час автоматичного закриття зміни',
  'Need to close shift reminder': 'Нагадування про незакриті зміни',
  'Sign out': 'Вихід',
  'Closing time': 'Час закриття',
  'Remind shift close time': 'Час відправки нагадування про незакриту зміну',
  'Automatic shift closing has been successfully set!': 'Автоматичне закриття зміни успішно встановлено!',
  'Automatic letter remind shift close time has been successfully set!': 'Час відправки нагадування про незакриті зміни успішно встановлено!',
  'Automatic letter remind shift close time sending has been successfully deactivated!': ' Нагадування про незакриті зміни деактивовано!',
  'Automatic shift closing has been successfully deactivated!': 'Автоматичне закриття зміни успішно деактивовано!',
  'First, register a new organization on the portal. You can then add it': 'Спочатку зареєструйте нову організацію на порталі. Після цього її можна буде додати',
  'Are you sure to close shift?': 'Ви впевнені що хочете закрити дану зміну?',
  OPENED: 'Відкрита',
  CLOSED: 'Закрита',
  CLOSING: 'Закриття',
  OPENING: 'Відкриття',
  CREATED: 'Створена',
  'Receipt discount': 'Знижка на чек',
  'Receipt extra charge': 'Надбавка на чек',
  'Total sum': 'Сума разом',
  'Refund sum': 'Сума повернення',
  'Select outlet': 'Оберіть торгову точку',
  'Reports z-report': 'Звіт по Z-звітам',
  'Set deposit sign token': 'Вкажіть токен доступу та пароль до ключа Depositsign',
  'Deposit sign token': 'Токен доступу до ключа',
  'Unvalid depositsign token. Try again': 'Неправильный токен доступу до ключа Depositsign. Спробуйте ще раз',
  'Deposit sign key password': 'Пароль до ключа Deposit Sign',
  'This password is not valid. Enter another': 'Пароль доступу до ключа не вірний.',
  'This token is not valid. Enter another': 'Не вдалося знайти ключ. Перевірте правильність токену',
  'To issue checks, please pay the bill for the use of cash registers': 'Для видачі чеків, будь ласка, оплатіть рахунок на користування касами',
  'Issuance of checks is blocked. To resume work, please pay for the use of cash registers': 'Видача чеків заблокована. Для відновлення роботи, будь-ласка, зробіть оплату за користування касами',
  'Please make the payment for the next period before': 'Будь ласка, зробіть платіж за наступний період до',
  discount: 'знижка | знижку | знижки',
  'Edit discount': 'Редагувати знижку',
  'Discount has been successfully set': 'Знижка успішно встановлена',
  'The read private key does not support EDS (DSTU 4145). Please use another EDS key': 'Зчитаний особистий ключ не підтримує ЕЦП (ДСТУ 4145). Будь ласка, використайте інший ключ ЕЦП',
  'Tax Bills': 'Податкові накладні',
  'Tax Bill': 'Податкова накладна',
  'Excises report': 'Звіт по акцизам',
  'Receiver name': 'Імя отримувача',
  'Receiver EDRPOU': 'ЄДПРОУ отримувача',
  'Receiver tax number': 'Податковий номер отримувача',
  'Tax bill was updated successfully': 'Податкова накладна успішно відредагована',
  'Tax bill was successfully sent to sfs': 'Податкова накладна успішно відправлена в дпс',
  'Sum details': 'Деталі накладної',
  'Send tax bill': 'Відправка податкової накладної',
  'Send way bill': 'Зберегти та відправити в дпс',
  'Sum (uah)': 'Сума (грн)',
  'Rules of work with cash register': 'Правила роботи з касою',
  'Answers to questions': 'Відповіді на питання',
  Important: 'Важливо',
  'The CheckboxSignature program must be running to close the change automatically': 'Для автоматичного закриття зміни програма CheckboxПідпис має бути запущена на захищеному хмарному сервісі або локально',
  'Also, the change will not be closed automatically if it was opened in the CheckboxCashier program': 'Також зміна не закриється автоматично, якщо вона була відкрита у програмі CheckboxКаса, мобільному додатку з підтримкою офлайн-режиму або інтеграцію, яка передає мітку пристрою X-Device-ID',
  'Send selected tax bills': 'Відправити вибрані накладні',
  'Tax bills sending': 'Відправка податкових накладних',
  'Send ': 'Відправити',
  Saved: 'Збережено',
  Sent: 'Відправлено',
  'Send report on email': 'Відправити звіт на e-mail',
  'Report will be send to emails': 'Звіт буде відправлений на вказані Вами емейли',
  'Allow login for employee': 'Дозволити касирам вхід на портал',
  'Allow employee login successfully set': 'Вхiд на портал касирам дозволено',
  'Allow employee login successfully unset': 'Вхiд на портал касирам заборонено',
  Act: 'Акт',
  Acts: 'Акти',
  'View invoices': 'Перегляд рахунків',
  'View acts': 'Перегляд актів',
  'Select invoice': 'Оберіть рахунок',
  'Select act': 'Оберіть акт',
  'Close shift by head cashier': 'Закрити зміну старшим касиром',
  'Select cashier and type of closing shift': 'Оберіть касира та спосіб закриття зміни',
  'Start date': 'Дата початку',
  'End date': 'Дата кінця',
  'Payed date': 'Дата оплати',
  'Date sent': 'Дата відправки',
  'Download PDF': 'Завантажити PDF',
  'Goods import results': 'Результати імпорту товарів',
  'There are errors in import file. Please check import file and try again': 'Виявлені помилки в файлі імпорту. Будь ласка, виправте їх та спробуйте ще раз.',
  'File imported successfully': 'Товари успішно імпортовано',
  'is identifier for good': 'унікальний ідентифікатор товару',
  'must be more than zero': 'більше нуля',
  'Add promo code': 'Додати промокод',
  'View promo codes': 'Переглянути промокоди',
  'Promo code creator': 'Хто створив',
  'Select start date': 'Оберіть дату початку',
  'Select end date': 'Оберіть дату кінця',
  'Promo code': 'Промокод',
  'Promo codes': 'Промокоди',
  'Promo code successfully applied': 'Промокод успішно застосовано',
  Refresh: 'Оновити',
  'New version available': 'Доступна нова версія',
  'Auth token is outdated. Renew Please': 'Час сесії минув. Будь ласка виконайте вхід ще раз',
  'Sum without discount': 'Сума без знижки',
  'Enable auto payment': 'Автоматично списувати кошти',
  'Disable automatic payment by card': 'Вимкнути автоматичну оплату картою',
  'Some error when trying withdraw money from your card': 'Сталася помилка при спробі здійснити автоматичну оплату. Будь ласка виконайте оплату ще раз',
  'Auto payment by card was disabled': 'Автоматичну оплату катрою вимкнено',
  'Need interaction': 'Потрібно встановити дані',
  Deactivated: 'Деактивована',
  'Cash register was deactivated in sfs': 'Каса деактивована в ДПС',
  'It need to user interaction to use this cash register': 'Для використання цієї каси потрібно вручну встановити торгову точку для неї',
  'if you need to add tax': 'якщо необхідно додати податок',
  Defrost: 'Розморозити',
  'Enter own payment type': 'Введіть спосіб оплати',
  'Funds will be automatically deducted each month': 'Кошти будуть автоматично списуватись кожного місяця (доступно лише при оплаті картою)',
  billingStatuses: {
    frozen: 'Організацію заморожено',
    disabled: 'Організацію відключено',
    in_debt: 'Борг',
    created: 'Рахунок створено',
    invoiced: 'Виставлений рахунок',
    trial: 'Тріальний період',
    payed: 'Рахунок сплачено',
    cc_active: 'Роботу продовжено'
  },
  'No outlet': 'Немає торгової точки',
  'Cash register is deactivated, so it will be not added to invoice': 'Каса деактивована користувачем',
  'Deactivated by user': 'Деактивовано користувачем',
  Tags: 'Теги',
  'Date range': 'Діапазон дат',
  'Round the amount': 'Округлити суму',
  'Round receipt sum hint': 'Згідно постанови НБУ №25 від 15.03.18 необхідно заокруглювати готівку до 10 копійок',
  'No text representation for check printing, please try again later or contact technical support': 'Текстове представлення для друку чека не знайдено, спробуйте пізніше або зверніться в техпідтримку',
  'You do not have cash registers to pay, go to': 'У вас немає кас для оплати, перейдіть в',
  'cash register creation menu': 'меню створення кас',
  'Are you sure to delete this order?': 'Ви дійсно бажаєте видалити дану заявку?',
  'Delete error': 'Видалити помилку',
  'Error order was deleted successfully': 'Помилкова заявка видалена успішно',
  'Payment made successfully': 'Оплата здійснена успішно',
  'Thank you for using Checkbox': 'Дякуємо, що користуєтесь Checkbox',
  'Log in to the portal': 'Перейти на портал',
  'Download Excel': 'Вивантажити у Excel',
  'Download ': 'Вивантажити',
  'Date of creation of the cash register (date range)': 'Дата створення каси (діапазон дат)',
  'To unload all cash registers leave the field blank': 'Для вивантаження всіх кас залиште поле пустим',
  'Download Invoices in Excel': 'Завантажити рахунки в Excel',
  'Cash register removal': 'Видалення каси',
  'Cash register modifying': 'Редагування каси',
  'Are you sure to delete cash register?': 'Ви дійсно бажаєте видалити касу "{number}"?',
  'Importantly! Before deleting the cash register, make sure that there are no open changes': 'Важливо! Перед видаленням каси, переконайтесь, що по ній немає відкритих змін. Після підтвердження каса буде деактивована в ДПС',
  'On removal in SFS': 'На видаленні в ДПС',
  'On modify in SFS': 'На редагуванні в ДПС',
  'The cash register is on removal in the SFS': 'Каса знаходиться на видаленні в ДПС',
  'The cash register is on modify in the SFS': 'Каса знаходиться на редагуванні в ДПС',
  'An error occurred while deleting in the traffic police': 'При видаленні в ДПС виникла помилка',
  'An error occurred while modifying in the traffic police': 'При редагуванні в ДПС виникла помилка',
  'Payment for cash registers': 'Оплата за каси',
  'Number of days at the new box office': 'Кількість днів по новим касам',
  'Months count': '{count} місяць | {count} місяці | {count} місяців',
  'Offline statistics': 'Статистика офлайну',
  Now: 'Зараз',
  hours: 'год.',
  'Date of act': 'Дата акту',
  'Warning! Another organization has now been selected. Refresh the page or select the organization you want': 'Увага! Зараз обрана інша організація. Оновіть сторінку або оберіть потрібну організацію',
  'Technical return report': 'Звіт по технічним поверненням',
  'The check was successfully sent': 'Чек успішно відправлено',
  'Employee was deactivated in sfs': 'Касир деактивований в ДПС',
  'The employee is on removal in the SFS': 'Касир знаходиться на видаленні в ДПС',
  'The employee is on modify in the SFS': 'Касир знаходиться на редагуванні в ДПС',
  'To use this cashier you need to manually set a login and password': 'Для використання цього касира потрібно вручну встановити логін та пароль',
  'Set data': 'Встановити дані',
  Set: 'Встановити',
  'Employee removal': 'Видалення касира',
  'Employee modifying': 'Редагування касира',
  'Are you sure to delete employee?': 'Ви дійсно бажаєте видалити касира "{name}"?',
  'Cashier data installation': 'Встановлення даних касира',
  'Cash register data installation': 'Встановлення даних каси',
  'Data successfully installed': 'Дані успішно встановлено',
  'Before starting work, we recommend that you read the "Rules of work with the cash register"': 'Перед початком роботи рекомендуємо ознайомитись з "Правилами роботи з касою"',
  Go: 'Перейти',
  'Employee login': 'Логін касира',
  'Employee name': 'ПІБ касира',
  'if you want to change access to the Deposit Sign': 'якщо хочете змінити доступи до ключу АЦСК Deposit Sign',
  'The EDS key does not match the selected cashier': 'Ключ ЕЦП не відповідає обраному касиру',
  'The cashier has been successfully edited': 'Касир успішно відредагований',
  entityActions: {
    create: {
      employeeorder: 'Касир успішно відправлений в ДПС',
      employeeorderDepositSign: 'Посилання на реєстрацію в DepositSign було відправлено на вказаний номер телефону',
      cashregisterorder: 'Каса успішно відправлена в ДПС',
      outletorder: 'Торгова точка успішно відправлена в ДПС'
    },
    modify: {
      employeeorder: 'Заявка на редагування касира успішно відправлена',
      cashregisterorder: 'Заявка на редагування каси успішно відправлена',
      outletorder: 'Заявка на редагування торгової точки успішно відправлена'
    },
    removal: {
      employeeorder: 'Заявка на видалення касира успішно відправлена',
      cashregisterorder: 'Заявка на видалення каси успішно відправлена',
      outletorder: 'Заявка на видалення торгової точки успішно відправлена'
    },
    keyReplacement: {
      employeeorder: 'Заявка на заміну ключа касира успішно відправлена'
    }
  },
  'Launch Checkbox Signature on a secure cloud service': 'Запустити CheckboxПідпис на захищеному хмарному сервісі',
  Upload: 'Завантажити',
  'Qualified provider trust services': 'Надавач послуги ЕЦП',
  'Autoselect ACSK Provider': 'Автовибір АЦСК',
  'Enter the EDS key password': 'Введіть пароль ключа ЕЦП',
  'Select the EDS key': 'Оберіть ключ ЕЦП',
  'Possible formats: *.dat, *.pfx, *.pk8, *.zs2, *.jks': 'Формати key-6.dat, *.pfx, *.pk8, *.zs2, *.jks',
  'Selected key does not match of cashier key': 'Обраний ключ не відповідає зареєстрованому касиру',
  'Key successfully upload to checkbox server': 'CheckboxПідпис на захищеному хмарному сервісі увімкнено',
  'Receipt settings': 'Налаштування чеків',
  'Receipt width': 'Ширина чека',
  'Receipt png width': 'Ширина картинки чека',
  'Receipt settings successfully set': 'Налаштування чеків успішно встановлені',
  'The width of the receipt is indicated in the number of characters': 'Ширина чека вказується в кількості символів (за замовчуванням 42)',
  'The width of the receipt png view is indicated in the number of characters': 'Ширина картинки чека вказується в кількості символів (за замовчуванням 30)',
  'Default value: 58': 'За замовчуванням 58',
  'Reconciliation act': 'Акт звірки',
  'Organization not found ': 'Організацію не знайдено',
  'Goods report will be send to emails': 'Звіт по товарам буде відправлений на вказані Вами емейли',
  'Emails to send reports. Example: email1@example.com, email2@example.com': 'Електронні адреси розділені \',\' на які буде відправлено звіт. Приклад: email1@example.com, email2@example.com',
  'Emails for send reports': 'Електронні адреси для відправки звіту',
  'Goods report': 'Звіт по товарах',
  'Payer number': 'Номер рахунку',
  'Payer code': 'Код платника',
  'Apply to all products': 'Застосувати до всіх товарів',
  'The tax rate will be applied to all products that do not have their own tax rate': 'Податкова ставка буде застосована до всіх товарів, які не мають власну податкову ставку (дана функція реалізована тільки для роботи на порталі)',
  'or set data manually': 'або вказати дані в ручну',
  yes: 'так',
  no: 'ні',
  'You do not have settings groups for reports yet': 'У вас поки що немає груп налаштувань для звітів',
  'Add group': 'Додати групу',
  'Group name': 'Назва групи',
  'Apply group': 'Застосувати групу',
  'Delete group': 'Видалити групу',
  'Group deleting': 'Видалення групи',
  'Are you sure you want to delete the group?': 'Ви дійсно бажаєте видалити групу?',
  Apply: 'Застосувати',
  'Group edited successfully': 'Групу успішно відредаговано',
  'Group added successfully': 'Групу успішно додано',
  'Group deleted successfully': 'Групу успішно видалено',
  'Read data from eds key': 'Вкажіть ключ ЕЦП для підпису чеків',
  'Outlet removal': 'Видалення торгової точки',
  'Outlet modifying': 'Редагування торгової точки',
  'Outlet was deactivated in sfs': 'Торгова точка деактивована в ДПС',
  'The outlet is on removal in the SFS': 'Торгова точка знаходиться на видаленні в ДПС',
  'The outlet is on modify in the SFS': 'Торгова точка знаходиться на редагуванні в ДПС',
  Deactivation: 'Деактивація',
  'Deactivated in SFS': 'Деактивовано в ДПС',
  'New password for employee': 'Новий пароль для касира',
  'Set if need to update employee password': 'Вкажіть, якщо потрібно оновити пароль касиру',
  'Select if you want to change the entity in the sfs': 'Виберіть, якщо хочете {action} {entity} в ДПС',
  Regeneration: 'Перегенерувати',
  'Invoice regeneration': 'Перегенерування рахунку',
  'Importantly! A promo code has been applied to this account!': 'Важливо! До даного рахунку застосований промокод!',
  'Sum without promocode': 'Сума без промокоду',
  'Invoice successfully regenerated': 'Рахунок успішно перегенеровано',
  Billing: 'Білінг',
  Billings: 'Білінги',
  'Choose setting for outlet creating': 'Прошу взяти на облік за неосновним місцем обліку за місцезнаходженням об\'єкту оподаткування',
  'Auto payment': 'Автоматична оплата',
  'Cash register price': 'Ціна за касу',
  'New cash register price': 'Ціна за нову касу (в день)',
  'Receipts limit': 'Ліміт чеків',
  'Take shifts into invoice': 'Рахувати наявність змін',
  'Cash register periods': 'Періоди кас',
  'Remove EDS Key': 'Зупинити CheckboxПідпис на захищеному хмарному сервісі',
  'Are you sure to delete eds key from checkbox srver': 'Ви впевнені що хочете зупинити CheckboxПідпис для даного ключа ЕЦП на захищеному хмарному сервісі',
  'Key successfully removed from checkbox server': 'CheckboxПідпис на захищеному хмарному сервісі зупинено для даного ключа',
  'Payment settings': 'Налаштування оплат',
  'Document flow service': 'Метод відправки актів',
  'User id in doc flow service': 'Email користувача для сервісу Вчасно / Dubidoc',
  'Email in vchasno service': 'Якщо метод відправки актів обрано як "Вчасно", додайте сюди емейл користувача на який зареєстровані в сервісі Вчасно',
  'Date of creation of the employee (date range)': 'Дата створення касира (діапазон дат)',
  'To unload all employees leave the field blank': 'Для вивантаження всіх касирів залиште поле порожнім',
  'Cash registers settings': 'Налаштування кас',
  'Auto withdrawal when shift close': 'Автоматично виносити кошти з каси при закритті зміни',
  'Auto withdrawal successfully set': 'Автоматичне винесення коштів успішно встановлено',
  'Auto withdrawal successfully unset': 'Автоматичне винесення коштів успішно деактивовано',
  'This data will be in receipt footer': 'Буде відображатись в нижньому колонтитулі чеку',
  'Need to wait 5 minutes that signature agent become active': 'Потрібно зачекати 5 хвилин для того, щоб агент підпису став активним',
  Warning: 'Увага',
  'We\'ve sent you an email with instructions on how to reset your password.': 'Ми надіслали вам електронного листа з інструкціями, як скинути пароль.',
  'Enter your email address and we\'ll send you a link to reset your password.': 'Введіть свою електронну адресу, і ми надішлемо вам посилання для скидання пароля.',
  'Select from classifier': 'Обрати з класифікатора',
  'Invalid credentials.': 'Невірні дані',
  'Enter manually': 'Ввести вручну',
  'Required field': 'Поле обов`язкове',
  'Add the product manually': 'Додати товар вручну',
  'Add a discount': 'Додати знижку',
  'Collapse menu': 'Згорнути меню',
  'Sort by': 'Сортувати',
  'Sign plugin interface not loaded': 'Інтерфейс плагіна підпису не завантажився. Будь ласка, оновіть сторінку',
  'Connect goods': 'Додати пов\'язаний товар',
  'Select goods': 'Оберіть товари',
  'Upon sale, these items will be automatically added to the check along with this item': 'При продажу дані товари будуть автоматично додаватись у чек разом з цим товаром',
  'Show related goods': 'Показати пов\'язані товари',
  'Show total sum': 'Показати загальну суму',
  'Check cashier key status...': 'Перевірка статусу ключа касира...',
  'Close shift by cashier': 'Закрити зміну касиром ',
  'Copy link': 'Копіювати посилання',
  'Your organization has not yet been entered into the payment system': 'Вашу організацію ще не внесено до системи платежів. Даний функціонал буде доступний на наступний день після реєстрації.',
  'Receipts amount for the current year': 'Кількість чеків за поточний рік',
  'Update data by sum operations for the current year': 'Оновити дані по сумі операцій за поточний рік',
  'The term for the EDS key of the given cashier will end in days': 'Термін дії ключа ЕЦП даного касира закінчується через {count}. Будь ласка створіть нового касира та деактивуйте поточного.',
  'Having ended the term of the EDS key of the given cashier': 'Завершився строк дії електронного ключа (печатки) касира. Отримайте новий ключ (печатку) у Вашому АЦСК та зареєструйте нового касира.',
  'Payment failed': 'Не вдалося здійснити оплату',
  'Changes in tax rates will be applied only in the next cash register shift': 'Зміни у податкових ставках будуть застосовані тільки у наступній касовій зміні.',
  'Add new cash register to invoice': 'Враховувати нові каси',
  'Sale goods': 'Продаж товару',
  'Add excise stamp': 'Додати акцизні марки',
  'Excise stamp': 'Акцизна марка',
  'Excise stamps of product': 'Акцизні марки товару',
  'Edit excise stamp': 'Редагувати акцизні марки',
  'Enter excise stamp': 'Введіть акцизну марку',
  'To add multiple excise stamps, enter a value in the field': 'Щоб додати декілька акцизних марок, введіть значення в поле',
  'Receipt editor': 'Редактор чека',
  'Receipt header': 'Верхній колонтитул чека',
  'Receipt footer': 'Нижній колонтитул чека',
  'Receipt logo': 'Логотип чека',
  'HTML receipt': 'Графічне представлення чека',
  'Text receipt': 'Текстове представлення чека',
  'Email list topic': 'Тема email листа',
  'HTML page title': 'Заголовок веб-сторінки',
  'Delete receipt logo': 'Видалити логотип чека',
  'Refund receipt': 'Повернути чек',
  'Scan barcode': 'Відсканувати штрих код',
  'Scan product barcode': 'Відсканувати штрих код товару',
  'Scan the barcode': 'Відскануйте штрих код',
  'No product found with this barcode': 'За таким штрих кодом товар не знайдено',
  'Scan again': 'Відсканувати знову',
  'Set referral code': 'Встановити реферальний код',
  'Referral code': 'Реферальний код',
  'Referral code successfully set': 'Реферальний код успішно встановлено',
  'To update the employees key (seal)': 'Якщо у Вас закінчується строк дії електронного ключа (печатки) касира – отримайте новий ключ (печатку) у Вашому АЦСК, натисніть "Дії" (три крапки) біля необхідного касира -> "Заміна ключа" або зареєструйте нового касира',
  'To receive a test data, please add your organization through EDS key': 'Для отримання тестових даних додайте, будь ласка, свою організацію за допомогою ключа ЕЦП',
  'Delete organization': 'Видалити організацію',
  'You must switch to another organization to uninstall': 'Для видалення потрібно переключитися на іншу організацію',
  'Organization successfully deleted': 'Організацію успішно видалено',
  'Date of deactivated': 'Дата деактивації',
  'Get UAH 100 on the account': 'Отримати 100 грн на рахунок',
  'Do you like working with Checkbox?': 'Подобається працювати з Checkbox?',
  'Tell your friends about Checkbox and get a discount for yourself and a friend': 'Розкажи про нас друзям і отримай знижку для себе та друга',
  'Your personal link': 'Ваше персональне посилання',
  'Send a letter of restructuring for the efforts of V and your friend deduct 100 UAH each to the Checkbox balance': 'Після реєстрації за посиланням Ви і Ваш друг отримаєте по 100 грн на баланс Checkbox',
  Referral: 'Реферал',
  Referrals: 'Реферали',
  'Registration date': 'Дата реєстрації',
  'Payment date': 'Дата виплати',
  'The invited friend will receive UAH 100 on the balance immediately after registration. Your balance will be charged automatically after the invited friend makes the first payment for using the Checkbox service': 'Запрошений друг отримає 100 грн на баланс одразу після реєстрації. На ваш баланс нарахування відбудеться автоматично після здійснення запрошеним другом першої оплати за користування сервісом Checkbox',
  'Expect payment': 'Очікуємо оплату',
  'Bonus accrued': 'Бонус нарахований',
  'Name of the Signatory of the documents': 'ПІБ підписанта документів',
  'Position of the Signatory of documents': 'Посада підписанта документів',
  'Reconciliation Checkbox act': 'Акт звірки Checkbox',
  'Include all cash registers in act': 'Завжди додавати всі каси в акт',
  'We automatically changed the letters to Latin. Please check that the data is correct': 'Ми автоматично змінили літери на латинські. Будь ласка, перевірте коректність даних',
  'Paper width': 'Ширина паперу',
  mm: 'мм',
  'Shift opened': 'Зміна відкрита',
  'Shift closed': 'Зміна закрита',
  'un.': 'од.',
  'Load more': 'Завантажити більше',
  Contacts: 'Контакти',
  Beta: 'Бета',
  'How to start': 'Як почати',
  'Here you will find all the necessary instructions to adjust the system to your needs': 'Тут ви знайдете всі потрібні інструкції для налаштування системи під ваші потреби',
  'Base settings': 'Базові налаштування',
  'Settings for your business': 'Налаштування для вашого бізнесу',
  'Create an outlet': 'Створіть торгову точку',
  'If you have previously registered a point of sale in the tax office, then after synchronization they will be displayed in Checkbox. All you have to do is convert them by clicking Actions → Convert to Checkbox': 'Якщо раніше Ви реєстрували торгову точку в кабінеті податкової, то після синхронізації вони відобразяться в Checkbox. Потрібно лише конвертувати їх, натиснуваши Дії → Конвертувати в Checkbox',
  'Add cash register ': 'Додайте касу',
  'The cash register is attached to the point of sale and goes to the traffic police for registration': 'Каса додається до торгової точки та йде на реєстрацію в ДПС',
  'Register employee': 'Зареєструйте касира',
  'You can download the EDS of the cashier or enter all the data manually': 'Ви можете завантажити ЕЦП касира або занести всі дані вручну',
  'Launch the Checkbox-Signature': 'Запустіть CheckboxПідпис на захищеному хмарному сервісі або локально',
  'Checkbox-Signature is required to fiscalize checks, close and open changes. If you created the cashier manually, you need to install Checkbox-Signature lolcano on your PC. Read more in': 'CheckboxПідпис потрібен для фіскалізації чеків, закриття і відкриття зміни. Якщо ви створювали касира вручну, потрібно встановити Checkbox-Підпис лолькано на ПК. Детальніше в',
  'No check view was found, please try again later or contact technical support': 'Представлення для друку чека не знайдено, спробуйте пізніше або зверніться в техпідтримку',
  Registration: 'Реєстрація',
  'Already have an account?': 'Вже є акаунт?',
  'Log in': 'Увійти',
  Later: 'Пізніше',
  'How to get an EDS key?': 'Як отримати ключ ЕЦП (КЕП)?',
  'You need to add an organization': 'Потрібно додати організацію',
  'Add organization': 'Додати організацію',
  'Organization successfully added': 'Організацію успішно додано',
  'If this is a subgroup, select the parent group': 'Якщо це підгрупа, оберіть материнську групу',
  'Price, ₴': 'Ціна, ₴',
  'Other actions': 'Інші дії',
  'Product group': 'Група товару',
  'Price, ₴ per unit.': 'Ціна, ₴ за од.',
  'Weight goods': 'Ваговий товар',
  'The product can be sold in parts. The minimum is 0.01': 'Товар можна продавати частинами. Мінімально – 0.01',
  'General information': 'Загальна інформація',
  Leftovers: 'Залишки',
  'Has related products': 'Має пов’язані товари',
  'Related products': 'Пов’язані товари',
  'Upon sale, these products will be automatically added with this product': 'При продажу вказані товари будуть автоматично додаватись разом з цим товаром',
  'Specify initial balances': 'Вказати початкові залишки',
  'When saving the goods, a delivery document will be created for the quantity of goods specified here': 'При збереженні товару буде створений документ постачання на вказану тут кількість товарів',
  'Initial balance, pcs': 'Початковий залишок, шт',
  'Initial balance, kg': 'Початковий залишок, кг',
  'Purchase price, ₴': 'Ціна закупки, ₴',
  Supplier: 'Постачальник',
  Suppliers: 'Постачальники',
  'The product (has a balance)': 'Товар (має залишок)',
  'Service (no balance)': 'Послуга (не має залишку)',
  'Add a new group': 'Додати нову групу',
  'All ': 'Все',
  Provider: 'Постачальник',
  'Add provider': 'Додати постачальника',
  'New provider': 'Новий постачальник',
  'Provider successfully added': 'Постачальника успішно додано',
  'Name ': 'Назва',
  'New supply': 'Нова поставка',
  'Make supply': 'Внести поставку',
  'Add supply': 'Додати поставку',
  'Quantity, od': 'Кількість, од',
  'Quantity, kg': 'Кількість, кг',
  Time: 'Час',
  'Write off': 'Списати',
  'New write-off': 'Нове списання',
  'Write off product': 'Списати товар',
  'Delivery added successfully': 'Поставку здійснено успішно',
  'Write off added successfully': 'Списання здійснено успішно',
  'Advanced functionality of goods': 'Розширений функціонал товарів',
  'Advanced product functionality included': 'Розширений функціонал товарів увімкнено',
  'Extended product functionality is disabled': 'Розширений функціонал товарів вимкнено',
  Supply: 'Поставка',
  'Supply ': 'Залишок',
  'Write-off': 'Списання',
  'Delivery details': 'Дані про поставку',
  'Open balance details': 'Відкрити деталі про залишок',
  'Detailing the current balance': 'Деталізація поточного залишку',
  'Acquiring Monobank': 'Еквайринг Monobank',
  'Monobank acquiring is enabled': 'Еквайринг Monobank увімкнено',
  'Monobank acquiring is disabled': 'Еквайринг Monobank вимкнено',
  'Monobank token': 'Monobank токен',
  Order: 'Замовлення',
  'Link for payment': 'Посилання для оплати',
  'Created ': 'Створено',
  'In processing': 'В обробці',
  Payed: 'Оплачено',
  'Awaiting payment': 'Очікує оплату',
  'Date of delivery': 'Дата постачання',
  'Leftovers are used': 'Використовувати залишки для товарів',
  'Settings have been saved': 'Налаштування збережені',
  'Not enough leftovers': 'Недостатньо залишків',
  'Add leftovers': 'Додати залишки',
  'Disable the use of leftovers': 'Вимкнути використання залишків',
  'You are trying to sell more items than your inventory': 'Ви намагаєтесь продати кількість товарів, що перевищує кількість залишків',
  'Separation of goods by retail outlets': 'Розділення товарів по торговим точкам',
  'Select a Default Branch': 'Оберіть торгову точку за замовчуванням',
  'Use product separation by retail outlets': 'Використовувати розділення товарів по торговим точкам',
  'You do not need to go to the tax office - when registering a point of sale (form 20-OPP), cash register (form 1-PRRO) and cashier (form 5-PRRO) all data is automatically sent to the traffic police': 'В податкову ходити не треба – при реєстрації торгової точки (форма 20-ОПП), каси (форма 1-ПРРО) і касира (форма 5-ПРРО) всі дані автоматично відправляються в ДПС',
  Clear: 'Зрозуміло',
  'Frequently Asked Questions': 'Часті Питання',
  'Import goods': 'Імпорт товарів',
  'Export goods': 'Експорт товарів',
  'Select import source': 'Оберіть тип файлу імпорту',
  'Upload file': 'Завантажте файл',
  'Excel file': 'Файл Excel',
  'Csv file': 'Файл CSV',
  'Select file': 'Оберіть файл',
  'Download example': 'Завантажити приклад',
  'View instructions': 'Переглянути інструкцію',
  Import: 'Імпортувати',
  'There are errors in import file': 'Виявлені помилки в файлі імпорту',
  Settings: 'Налаштування',
  'Authorize by cashier': 'Авторизація касиром',
  'Product removal': 'Видалення товару',
  'Are you sure to delete product?': 'Ви дійсно бажаєте видалити товар "{name}"?',
  'Delete group?': 'Видалити групу "{name}"?',
  'Warning! The product has leftovers that will also be removed': 'Увага! Товар має залишки, які також видаляться',
  'Product successfully deleted': 'Товар успішно видалено',
  'Group successfully deleted': 'Групу успішно видалено',
  'Write off and delete': 'Списати і видалити',
  'If the group has goods with balances, you can move them to another group, or delete and write off': 'Якщо в групі є товари с залишками, можна їх перемісти в іншу групу, або видалити і списати зі складу',
  'Move to another group': 'Перемістити в іншу групу',
  'Fiscal numbers of cash registers that could not work during the failure 28.12-05.01': 'Фіскальні номери кас, які не могли працювати під час збою 28.12-05.01',
  'Start at 4000': 'Починаються на 4000',
  'Sorry bonus period': 'Період дії соррі-бонусу',
  'What system did you sell through?': 'Через яку систему ви проводили продажі?',
  'Portal (personal account my.checkbox.ua)': 'Портал (особистий кабінет my.checkbox.ua)',
  'Checkbox mobile application': 'Мобільний додаток Checkbox',
  'Thank you for filling out the form. Sori bonus will be issued during the day.': 'Дякуємо за заповнення форми. Сорі бонус буде видано протягом доби.',
  'Sorry bonus requests': 'Запити на соррі-бонуси',
  'Sorry bonus': 'Соррі-бонус',
  'Sorry bonuses': 'Соррі-бонуси',
  'Confirm bonus': 'Підтвердити бонус',
  'Decline bonus': 'Відхилити бонус',
  'Add sorry bonus': 'Додати соррі-бонуси',
  'User period': 'Вказаний період',
  Delete: 'Видалити',
  'Disable cash register / cashier': 'Вимкнути тестові касу/касира',
  'One by one': 'Поштучно',
  Measurable: 'Вимірна',
  'Enable cash register / cashier': 'Ввімкнути тестові касу/касира',
  'Promo code value': 'Кількість кас',
  'Promo code months': 'Період дії (міс.)',
  'Test data': 'Тестові дані',
  'Cashiers will be able to log in to the portal with the login and password you specified when registering the cashier': 'Касири зможуть входити на портал з логіном і паролем, які ви вказували при реєстрації касира',
  'Reminders will be sent to your email address daily at the specified time': 'Нагадування буде відправлятись на Вашу електронну адресу щоденно у вказаний час',
  'Local community': 'територіальну громаду',
  Settlement: 'Населений пункт',
  'Settlement district': 'район',
  Katottg: 'Код за КАТОТТГ',
  'Acquiring token successfully set': 'Токен доступу до Monobank еквайрингу успішно встановлено',
  'Set monobank token': 'Встановити Monobank токен',
  'NP token was saved': 'Токен Нова Пошта було збережено',
  'Add NP token': 'Додати токен Нова Пошта',
  'NP token': 'Токен Нова Пошта',
  'View eTTN': 'Переглянути накладну',
  'eTTN number': 'Номер накладної',
  'ettn order': 'Експрес накладної',
  'eTTN orders': 'Експрес накладні',
  'Sender phone': 'Телефон відправника',
  'eTTN recipient': 'Контакт отримувача',
  'eTTN description': 'Опис відправлення',
  'Enter a NP token': 'Вкажіть токен Нова Пошта',
  'Enter a sender phone number': 'Вкажіть номер телефону відправника',
  'Enter the sender phone number!': 'Вкажіть номер телефону відправника!',
  'Unfortunately, we cannot obtain information on invoices created in the branch without the sender phone number.': 'На жаль, ми не можемо отримати інформацію за накладними, створеними у відділенні, без номера телефону відправника. Вкажіть номер для цієї накладної або додайте його в налаштуваннях.',
  'Click to view the error': 'Натисніть щоб переглянути помилку',
  'Error in submitting an application to the DPS': 'Помилка подання заявки до ДПС',
  'Document ': 'Квитанція',
  'The most common mistakes': 'Найпоширеніші помилки',
  'How to fix': 'Як виправити',
  'View order': 'Переглянути замовлення',
  'Date created': 'Дата створення',
  'Organization name': 'Найменування організації',
  'The type of taxation': 'Тип оподаткування',
  'Single tax payer': 'Платник єдиного податку',
  'VAT payer': 'Платник ПДВ',
  'But, unfortunately, some data are not available in the traffic police, so we suggest entering them manually': 'Але, на жаль, деякі дані відсутні в ДПС, тому потрібно ввести їх вручну',
  'Found qr codes for this token. Select one if need': 'Знайдено qr - касси для даного токена. Оберіть одну з них якщо продаєте offline',
  'Found new qr codes for this token. Select one if need': 'Знайдено нові qr - касси для даного токена. Оберіть одну з них якщо портібно',
  'Acquiring settings saved': 'Налаштування еквайрингу збережено',
  'Found QR cash registers': 'Знайдені QR каси',
  'Payment QR code': 'QR код для оплати',
  'Qr code scale': 'Розмір QR коду в чеку (%)',
  'Relative size in percent or receipt qrcode': 'Розмір QR коду в чеку в відсотках відносно ширини чеку (за замовчуванням 75)',
  Run: 'Запустити',
  ettn: 'Експрес-накладна',
  'Financial report': 'Фінансовий звіт',
  'Table settings': 'Налаштування таблиці',
  Notification: 'Сповіщення',
  'New notification': 'Нове сповіщення',
  Create: 'Створити',
  Title: 'Заголовок',
  Message: 'Повідомлення',
  'Date the notification was sent': 'Дата відправки сповіщення',
  Priority: 'Пріоритет',
  Low: 'Низький',
  Middle: 'Середній',
  High: 'Високий',
  Draft: 'Чернетка',
  Published: 'Опубліковано',
  'Edit notification': 'Редагування сповіщення',
  'There are no notifications yet': 'Наразі немає сповіщень',
  'Alert successfully created': 'Сповіщення успішно створено',
  'Alert successfully edited': 'Сповіщення успішно відредаговано',
  Logout: 'Вихід',
  'All notifications': 'Усі сповіщення',
  'There are no new notifications': 'Нових сповіщень немає',
  'Purchase price': 'Ціна закупки',
  'Retail price': 'Роздрібна ціна',
  'Sold out': 'Продано',
  'Sales amount (purchase)': 'Сума продажу (закупка)',
  'Sales amount (retail)': 'Сума продажу (роздріб)',
  'Gross income': 'Валовий дохід',
  Unit: 'Одиниця виміру',
  'Products and groups': 'Товари та групи',
  'Filter by products and groups': 'Фільтр по товарам та групам',
  Groups: 'Групи',
  'Download csv': 'Вивантажити у CSV',
  Markup: 'Націнка',
  'The amount of gross income': 'Сума валового доходу',
  'Edit outlet': 'Редагувати торгову точку',
  'I am a vat payer': 'Є платником ПДВ',
  'Creating a deposit': 'Створення застави',
  'Redeem deposit': 'Повернення застави',
  'For example, add the contract number to the check': 'Наприклад, додати до чеку номер договору',
  'Useful map': 'Корисна мапа',
  Transfer: 'Перенести',
  'Money transfer': 'Перенесення коштів',
  'Payment transfer': 'Перенесення платежу',
  'Payment transfers': 'Переноси платежів',
  'Postpone payment': 'Перенести платіж',
  'USREOU of the recipient': 'ЄДРПОУ отримувача',
  'Payment request successfully submitted': 'Заявка на перенос платежу успішно відправлена',
  'Unfortunately, you do not have enough funds in your account': 'На жаль, у вас недостатньо коштів на рахунку',
  Sender: 'Відправник',
  Recipient: 'Отримувач',
  Decline: 'Відхилено',
  'Confirm payment transfer': 'Підтвердити перенос платежу',
  'Decline payment transfer': 'Відхилити перенос платежу',
  'There are no transfer fees': 'Платежі для переносу відсутні',
  'Supplies sum (uah)': 'Сума залишків (грн)',
  'Tax reports': 'Звіти до ДПС',
  'Extended reports': 'Розширені звіти',
  'Document number': 'Номер документу',
  'Document name': 'Назва документу',
  'Creating a report': 'Створення звіту',
  'Select the document to create': 'Оберіть документ для створення',
  'Reporting document': 'Звітний документ',
  'Reporting document new': 'Звітний документ новий',
  'Document status': 'Стан документа',
  '_number group': '{number} група',
  'The amount of sales for the current _value': 'Сума продажів за звітний {value}',
  month: 'місяць',
  quarter: 'квартал',
  'Send report': 'Відправити звіт',
  'The report was successfully sent to the State Tax Service': 'Звіт успішно відправлено до ДПС',
  'Download the receipt': 'Завантажити квитанцію',
  'Download the receipt №2': 'Завантажити квитанцію №2',
  'Download report + receipt №2': 'Завантажити звіт + квитанцію №2',
  'The result from the beginning of the year': 'Підсумок з початку року',
  'Total amount': 'Підсумкова сума',
  'The amount of the single tax': 'Сума єдиного податку',
  'Sorry, but the reporting period has not yet come': 'Звітний період ще не настав або немає доступних звітів для вашої групи',
  Generate: 'Згенерувати',
  'Generate test data': 'Згенерувати тестову касу та касира',
  'Count of test users': 'Кількість тестових кас та касирів',
  'Test data successfully generated': 'Тестові дані успішно згенеровані',
  'No vat': 'Без ПДВ',
  '_num quarter': '{number} квартал',
  'Current quarter': 'Поточний квартал',
  'Current year': 'Поточний рік',
  'Select period': 'Обрати період',
  'Select year': 'Оберіть рік',
  'Generate barcode': 'Згенерувати штрихкод',
  'Billing SMS': 'Оплата за СМС повідомлення',
  'Enable SMS Billing': 'Смс модуль',
  'Enable sms module for organization': 'Відправка чеків по смс',
  'Send to sms': 'Відправити по смс',
  KVED: 'КВЕД',
  'View the report': 'Перегляд звіту',
  'Supplier deleting': 'Видалення постачальника',
  'The supplier _supplier will be removed': 'Постачальника "{supplier}" буде видалено',
  'In order to use product accounting, go to settings': 'Для того щоб використовувати облік товарів, перейдіть до налаштувань',
  'Supplies settings': 'Налаштування залишків',
  'To use goods accounting, you must enable the use of balances in the settings': 'Щоб використовувати функцію керування залишками товарів, необхідно увімкнути дане налаштування.',
  'Key replacement': 'Заміна ключа',
  'Employees key replacement': 'Заміна ключа ЕЦП для підпису чеків',
  Balance: 'Баланс',
  'On balance': 'На балансі',
  'Pay by': 'Сплатити до',
  'for period': 'за період',
  'Sms left': 'Кількість sms',
  'Sms Balance': 'Баланс для sms',
  'Sms Services': 'SMS-послуги',
  'Invoice will be issued': 'Рахунок буде виставлений',
  'Invoice has been issued': 'Рахунок виставлений',
  'Mark all as read': 'Відмітити всі прочитаними',
  'Generate link': 'Згенерувати посилання',
  'Your personal Telegram Bot': 'Ваш персональний Telegram Bot',
  'We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key': 'Ми не змогли отримати дані з вашого ключа ЕЦП перевірте його правильність або оберіть інший ключ ЕЦП',
  Analytics: 'Аналітика',
  Sales: 'Продажі',
  'Offline counter': 'Лічильник офлайн',
  Profit: 'Виручка',
  'All profit': 'Вся виручка',
  'Average receipt': 'Середній чек',
  'the difference from the previous period': 'різниця за попередній період',
  'Payment in cash': 'Оплата готівкою',
  'Payment by card': 'Оплата карткою',
  Filter: 'Фільтр',
  'Show more': 'Показати ще',
  'Clear filter': 'Очистити фільтр',
  January: 'Січень',
  February: 'Лютий',
  March: 'Березень',
  April: 'Квітень',
  May: 'Травень',
  June: 'Червень',
  July: 'Липень',
  August: 'Серпень',
  September: 'Вересень',
  October: 'Жовтень',
  November: 'Листопад',
  December: 'Грудень',
  'You chose': 'Ви обрали',
  'Current week': 'Поточний тиждень',
  'Current month': 'Поточний місяць',
  Yesterday: 'Вчора',
  Today: 'Сьогодні',
  'File is not valid': 'Файл не валідний',
  'The file contains invalid data. Please check the file and try again.': 'Файл містить некоректні дані. Будь ласка перевірте файл та спробуйте ще раз.',
  'An error occurred when deleting the cashier. Please try again or contact an administrator': 'Виникла помилка при видалені касира. Спробуйте ще раз або зв\'яжіться з адміністратором',
  'Acquiring settings successfully updated!': 'Налаштування еквайрингу успішно збережено',
  'Connect Concord acquiring': 'Еквайринг у мобільному додатку',
  'Acceptance of contactless payment cards works through the NFC of your mobile device.': 'Прийом безконтактних оплат карткою працює через NFC вашого мобільного пристрою.',
  'Instructions to connect': 'Інструкція з підключення',
  'CSV file with organizations': 'CSV файл з організаціями',
  'Upload CSV file': 'Завантажити CSV файл',
  'To start working, you must first be authorized by cashier': 'Для продовження роботи Вам спочатку необхідно авторизуватися касиром',
  'If you have comments or suggestions for the work of the portal, you can leave your comment': 'Якщо у вас є пропозиції по роботі порталу, ви можете залишити свій коментар',
  Suggestions: 'Пропозиції',
  'Thanks for helping us get better! We will definitely consider your offer': 'Дякуємо за те, що допомагаєте нам стати краще! Ми обов\'язково розглянемо вашу пропозицію',
  'Follow the announcements, perhaps your offer will be added to the updated one': 'Слідкуйте за анонсами, можливо саме ваша пропозиція буде додана в оновлені',
  Left: 'Залишити',
  Review: 'Відгук',
  Reviews: 'Відгуки',
  Page: 'Сторінка',
  'Status offline': 'Статус офлайн',
  'Continuous session': 'Безперервна сесія',
  'Since the beginning of the month': 'З початку місяця',
  'Offline codes are used': 'Використано офлайн-кодів',
  left: 'лишилось',
  'pieces.': 'шт.',
  'Offline statistic': 'Офлайн статистика',
  Offline: 'Офлайн',
  Online: 'Онлайн',
  'Online application': 'Online звернення',
  'the start of an active session': 'початок активної сесії',
  'Bank terminal': 'Банківський термінал',
  'Bank terminal settings': 'Налаштування банківського терміналу для каси {fiscalNumber}',
  'IP address': 'IP-адреса',
  Port: 'Порт',
  'Terminal ID': 'ID терміналу',
  Connect: 'Підключити',
  'Not active': 'Не активна',
  'Cash register activation': 'Активація каси',
  'Confirmation of checkout activation': 'Підтвердіть активацію каси',
  'Cash register activated successfully': 'Касу успішно активовано',
  'Before activation, you need to set the cash register data': 'Перед активацією потрібно встановити дані каси',
  'According to this key, no FOP or LLC is registered in the tax office': 'За цим ключем в податковій не зареєстровано жодного ФОП або ТОВ',
  'Reserve cash register': 'Резервна каса',
  'Shifts report': 'Звіт по робочим змінам',
  'Extended tax code': 'Розширений код податку',
  'It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents': 'Неможливо знайти ІПН директора організації. Перевірте ключ ЕЦП яким підписуєте документи',
  Modules: 'Модулі',
  Acquiring: 'Еквайринг',
  'Graphic receipt': 'Графічний чек',
  'Text receipt ': 'Текстовий чек',
  'Change login password': 'Змінити пароль входу',
  'Edit profile': 'Редагувати профіль',
  'Add product manually': 'Додати товар вручну',
  'Cash register balance': 'Баланс каси',
  'Editing the graphic display of the check': 'Редагування графічного відображення чека',
  'Check width (in thousands of characters)': 'Ширина чека (в кіл-сті символів)',
  'Width of the check image (in thousands of characters)': 'Ширина картинки чека (в кіл-сті символів)',
  'Editing the text display of the check': 'Редагування текстового відображення чека',
  'Receipt settings is saved successfully': 'Налаштування чеку успішно збережено',
  'Login for acquiring': 'Логін для еквайрингу',
  'Shifts settings': 'Налаштування змін',
  'Acquiring settings': 'Налаштування еквайрингу',
  'Shifts settings successfully saved': 'Налаштування змін успішно збережено',
  'To create a financial report, you must enable the use of balances.': 'Щоб створити фінансовий звіт, необхідно увімкнути використання залишків.',
  'The module settings have been saved successfully': 'Налаштування модулів успішно збережено',
  'Admin auth logs': 'Логування дій адміністратора',
  'Would you like to take this review into job?': 'Бажаєте опрацювати цей відгук?',
  'This reviews successfully processed?': 'Цей відгук успішно оброблено?',
  'Review successfully taken into job': 'Відгук взято в роботу',
  'Review successfully closed': 'Відгук позначено, як опрацьований',
  'Take into work': 'Взяти в роботу',
  'Close feedback': 'Закрити відгук',
  Event: 'Подія',
  Logs: 'Логування',
  'Test cash register': 'Тестова каса',
  'Use this cash register to test issuing checks': 'Використовуйте цю касу для тестування видачі чеків',
  'Search by receipt number': 'Пошук за номером чека',
  'Emergency close shifts': 'Аварійне закриття зміни',
  'Short description': 'Короткий опис проблеми',
  'The request to emergency closing shifts was created successfully': 'Запит на аварійне закриття зміни успішно створено',
  Addition: 'Додаток',
  'Year ': 'Року',
  Digit: 'Число',
  Month: 'Місяць',
  Year: 'Рік',
  Percent: 'Відсоток',
  'Appendix on EUV': 'Додаток по ЄСВ',
  'Bonus balance': 'Бонусний рахунок',
  'Through the EDS key': 'Через ключ ЕЦП',
  'Upload the cashier\'s EDS key or electronic seal that will be used for signature': 'Оберіть файл ключ ЕЦП для підпису чеків або печатки (зазвичай це файли з розширенням DAT, ZS2, JKS) на вашому пристрої',
  'Generate a DepositSign key': 'Створити ключ DepositSign',
  'Enter certificate data manually': 'Внести дані сертифіката вручну',
  'EDS key': 'Ключ ЕЦП',
  'Signature of DepositSign': 'Підпис DepositSign',
  Verification: 'Верифікація',
  'Token received': 'Токен отримано',
  'A signature is required': 'Потрібен підпис',
  'The key is generated': 'Ключ генерується',
  'Acceptance of DepositSign Terms': 'Прийняття умов DepositSign',
  'Review and confirm the DepositSign Terms of Service. After approval, we will generate and add to the system the EDS key created through our DepositSign partners to your account': 'Огляньте і підтвердіть умови використання послуг DepositSign. Після погодження ми згенеруємо і підкріпимо в систему ключ ЕЦП, створений через наших партнерів DepositSign, до вашого кабінету',
  'The document was not signed': 'Документ не був підписаний 😔',
  'Something went wrong and the document could not be signed. Please try again': 'Щось пішло не так і документ не вдалось підписати. Спробуйте ще раз, будь ласка',
  'Sign again': 'Підписати повторно',
  'Agree and sign': 'Погодитись і підписати',
  'DepositSign Service Agreement': 'Договір про надання послуги від DepositSign',
  'DepositSign key': 'Ключ DepositSign',
  'Checkbox key': 'Ключ Checkbox',
  'The key is launched on a secure cloud service. The signature agent is not running': 'Ключ запущено на захищеному хмарному сервісі. Агент підпису не запущено',
  'The key is launched on a secure cloud service. The signing agent is running': 'Ключ запущено на захищеному хмарному сервісі. Агент підпису працює',
  'Indicators of economic activity': 'Щомісячні авансові внески',
  'Amount of income for the reporting period': 'Обсяг доходу за звітний період',
  'The total amount of income for the reporting period': 'Загальна сума доходу за звітний період',
  'The amount of a single contribution': 'Сума єдиного внеску',
  'It is necessary to fill out an application for EUV': 'Потрібно заповнити додаток по ЄСВ',
  'The number of employees': 'Чисельність найманих працівників',
  'To be paid': 'До сплати',
  Signed: 'Підписано',
  'Add TAP2Phone': 'Додати TAP2Phone',
  'TAP2Phone settings': 'Налаштування TAP2Phone',
  'Connected to cash register': 'Підключений до каси',
  Bank: 'Банк',
  'TAP2Phone setup successfully created': 'Налаштування TAP2Phone успішно створено',
  'TAP2Phone setup successfully edited': 'Налаштування TAP2Phone успішно відредаговано',
  'TAP2Phone setup successfully connected': 'Налаштування TAP2Phone успішно підключено',
  'TAP2Phone setup successfully deleted': 'Налаштування TAP2Phone успішно видалено',
  'Are you sure you want to delete TAP2Phone settings?': 'Ви дійсно бажаєте видалити налаштування TAP2Phone?',
  'The number of excise stamps does not correspond to the number of goods:': 'Необхідно додати акцизні марки до наступних товарів:',
  'Enter the password from DepositSign': 'Введіть пароль від DepositSign',
  'Change act signatory': 'Змінити підписанта',
  Signatory: 'Підписант',
  'The signer has been successfully changed': 'Підписанта успішно змінено',
  'Act number': 'Номер акту',
  'View the error': 'Переглянути помилку',
  'Edit group': 'Редагувати групу',
  'A test check can only be returned by a test cashier': 'Тестовий чек можна повернути лише тестовим касиром',
  'POS-terminals': 'POS-термінали',
  'POS-terminal': 'POS-термінал',
  'Add POS-terminal': 'Додати POS-термінал',
  'Select bank': 'Оберіть банк',
  Tariffs: 'Тарифи',
  'How to register an A-Bank card': 'Як зареєструватися в А-Банк',
  'I got a card': 'Я отримав карту',
  'Checking the information': 'Перевіряю інформацію',
  'Sign the application': 'Підписати заяву',
  'Opening an FOP account': 'Підпишіть онлайн договір відкриття рахунку ФОП',
  'We could not get confirmation from the bank about the availability of the card': 'Ми не змогли отримати підтвердження від банку про наявність карти',
  Repeat: 'Повторити',
  'The application is under consideration at the bank': 'Заява на розгляді у банку',
  'Unfortunately, you received a refusal from the bank to open a FOP account': 'На жаль, ви отримали відмову від банку у відкритті рахунку ФОП',
  'But you can still try to get services from another bank': 'Але ви все ще можете спробувати отримати послуги в іншому банку',
  'It will take no more than 2 minutes': 'Це займе не більше 2-х хвилин',
  'Choose another bank': 'Обрати інший банк',
  'Application for the use of acquiring': 'Заява на використання еквайрингу',
  'Submit an application': 'Подати заяву',
  'Terminals count': 'Кількість POS терміналів',
  'The main type of activity': 'Основний вид діяльності',
  'You already have a terminal of this bank:': 'У вас вже є термінал цього банку:',
  'Connect your terminal to the cash register': 'Підключити ваш термінал до каси',
  'Connect your classic or Android terminal to the Checkbox system.': 'Підключіть ваш класичний чи Android термінал до системи Checkbox.',
  'Order a new terminal': 'Замовити новий термінал',
  'Order a new POS terminal from the bank and track the delivery status.': 'Замовляйте новий POS-термінал від банку, та відслідковуйте статус доставки.',
  'Terminal name': 'Назва терміналу',
  'Deactivated ': 'Деактивований',
  'Are you sure you want to delete bank terminal settings?': 'Ви дійсно бажаєте видалити налаштування POS-терміналу?',
  'Bank terminal settings have been successfully created': 'Налаштування POS-терміналу успішно створені',
  'Bank terminal settings have been successfully edited': 'Налаштування POS-терміналу успішно відредаговані',
  'Bank terminal settings have been successfully deleted': 'Налаштування POS-терміналу успішно видалені',
  'POS-terminal editing': 'Редагування POS-терміналу',
  'Outlet settings': 'Налаштування торгової точки',
  'Sale from': 'Продаж з',
  'Sale to': 'Продаж до',
  'Restrictions on the sale of alcoholic beverages': 'Обмеження продажу алкогольних напоїв',
  'The point of sale settings have been saved successfully': 'Налаштування торгової точки успішно збережені',
  'Division by retail outlets': 'Розділення по торговим точкам',
  'The setting of prices by trading points has been successfully saved': 'Налаштування цін по торговим точкам успішно збережено',
  'The product is not tied to any of the retail outlets': 'Товар не привʼязаний до жодної з торгових точок',
  'Do not block': 'Відсутність блокування',
  'Choose which POS terminal you want to get': 'Оберіть, який POS-термінал ви хочете отримати',
  'Acquiring order': 'Заявка на еквайринг',
  'Acquiring orders': 'Заявки на еквайринг',
  'An error occurred while signing the document. Please try again or contact your administrator': 'Виникла помилка при підписанні документу. Спробуйте ще раз або зв\'яжіться з адміністратором',
  New: 'Новий',
  'Under consideration at the bank': 'На розгляді у банку',
  'The application has been accepted': 'Заяву прийнято',
  'The application for the use of acquiring has been successfully signed and sent': 'Заяву на використання еквайрингу успішно підписано та відправлено',
  'You can specify multiple barcodes using a ","': 'Ви можете вказати декілька штрихкодів через знак ","',
  'Unfortunately, the number of cloud KEPs from Deposit Sign should not exceed the number of Active cash registers': 'На жаль, кількість хмарних КЕП від Deposit Sign не має перевищувати кількість Активних кас',
  'The application for the use of acquiring has been successfully approved by the bank': 'Заяву на підключення еквайрингу успішно схвалено банком',
  'An error occurred while applying for acquiring': 'При подачі заявки на використання еквайрингу виникла помилка. Спробуйте пізніше або зв\'яжіться з адміністратором',
  'To deactivate a point of sale, the state of the taxation object cannot be "Operated"': 'Для деактивації торгової точки стан об\'єкту оподаткування не може бути "експлуатується"',
  'Set vip state': 'Встановити VIP',
  'Unset vip state': 'Прибрати VIP',
  'Are you sure to unset vip state?': 'Ви впевнені що хочете прибрати VIP?',
  'Are you sure to set vip state?': 'Ви впевнені що хочете встановити VIP?',
  'All organizations': 'Всі організації',
  'Start balance': 'Початковий баланс',
  'Acts sum': 'Сума актів',
  'Payments sum': 'Сума платежів',
  'Refunds sum': 'Сума повернень',
  'End balance': 'Кінцевий баланс',
  'Client balances': 'Баланси по клієнтам',
  'To order a POS terminal, you need to perform several steps:': 'Для замовлення POS-терміналу вам потрібно виконати декілька кроків:',
  'Install the ABank24 mobile application and register': 'Встановити мобільний застосунок ABank24 та пройти реєстрацію',
  'Sign an application for opening a FOP account': 'Підписати заяву про відкриття рахунку ФОП',
  'Sign the application for connecting acquiring': 'Підписати заяву на підключення еквайрингу',
  Soon: 'Скоро',
  'Become bank client': 'Стати клієнтом банку',
  'Open FOP': 'Відкрити рахунок ФОП',
  'Unfortunately, you are not yet a client of A-Bank, so to order a POS terminal you need to perform several steps:': 'На жаль, ви ще не є клієнтом А-Банку, тож для замовлення POS-терміналу вам потрібно виконати декілька кроків:',
  'Sign the form about opening a FOP account': 'Підпишіть заяву про відкриття рахунку ФОП',
  'Sign the form for connecting acquiring': 'Підпишіть заяву на підключення еквайрингу',
  'I have registered': 'Я зареєструвався',
  'Create a report for the mall': 'Сформувати звіт для ТРЦ',
  'Connect your Tap to Phone': 'Підключити ваш термінал у смартфоні',
  'Connect your Tap to Phone to Checkbox system': 'Підключіть ваш термінал у смартфоні до системи Checkbox',
  'Register new Tap to Phone': 'Зареєструвати новий термінал у смартфоні',
  'Register new Tap to Phone from bank and connect to Checkbox system': 'Зареєструйте новий термінал у смартфоні від банку та підключіть до системи Checkbox',
  'Add Tap to Phone': 'Додати термінал у смартфоні',
  'Upload document': 'Загрузити документ',
  'Upload all needed documents': 'Завантажте всі необхідні документи',
  'Download survey': 'Завантажити бланк-анкету',
  'For the juridical and individual type': 'Для юридичних та фізичних осіб',
  'What is confirmed by the authority of the person who will conclude the contract on behalf of:': 'Що підтверджують повноваження особи, що буде укладати договір від імені:',
  'juridical person type (minutes of the higher management body of the legal entity or the order on the election/appointment of the head of the Partner, power of attorney)': 'юридичної особи (протокол вищого органу управління юридичної особи або наказ про обрання/призначення керівника Партнера, довіреність), копія його паспорту/документу, що посвідчує особу фізичних осіб, які підлягають ідентифікації та довідки податкового органу про присвоєння',
  'registration number of the account': 'реєстраційного номеру облікової',
  'taxpayer cards (if available)': 'картки платника податків (за наявності)',
  'The application is being considered': 'В процесі розгляду заявки',
  'Tap to phone editing': 'Редагування терміналу у смартфоні',
  'Tap to phone settings': 'Налаштування терміналу у смартфоні',
  Connected: 'Підключений',
  Disconnected: 'Не підключений',
  Connectedly: 'Підключено',
  Disconnectedly: 'Відключено',
  'According to the search results, no organization was found': 'За результатами пошуку жодної організації не було знайдено',
  'Sell price': 'Ціна продажу',
  'The main ones': 'Основні',
  'Additional ': 'Додаткові',
  Refunds: 'Повернення коштів',
  'Create refund': 'Створити повернення коштів',
  'New refund': 'Нове повернення коштів',
  'Refund application successfully created': 'Заявка на повернення коштів успішно створена',
  'Refund application successfully signed and sent': 'Заявка на повернення коштів успішно підписана та відправлена',
  'Request date': 'Дата запиту',
  'You have a refund request.': 'У вас є запит на повернення коштів.',
  'Click to confirm': 'Натисніть щоб підтвердити',
  'Refund confirmation': 'Підтвердження повернення коштів',
  'Payment order date': 'Дата платіжного доручення',
  'Bank account': 'Банківський рахунок',
  'Payment order number': 'Номер платіжного доручення',
  'Recipient full name': 'Повне ім\'я одержувача',
  'Recipient edrpou': 'ЄДРПОУ одержувача',
  'Sign and send': 'Підписати та відправити',
  'Waiting for signature': 'Очікує на підпис',
  Approved: 'Затверджено',
  Declined: 'Відхилено',
  'Are you sure you want to confirm your refund request?': 'Ви впевнені, що хочете підтвердити заявку на повернення коштів?',
  'Are you sure you want to decline your refund request?': 'Ви впевнені, що хочете відхилити заявку на повернення коштів?',
  'Are you sure you want to delete your refund request?': 'Ви впевнені, що хочете видалити заявку на повернення коштів?',
  'The refund request has been confirmed': 'Заявку на повернення коштів підтверджено',
  'Refund request rejected': 'Заявку на повернення коштів відхилено',
  'Refund request deleted': 'Заявку на повернення коштів видалено',
  'Signed refund document': 'Підписаний документ на повернення коштів',
  'Read document': 'Переглянути документ',
  'Successfully paid': 'Успішно сплачено',
  'Payment date ': 'Дата платежу',
  'Add a tax group': 'Додати податкову групу',
  'Editing the tax rate': 'Редагування податкової ставки',
  'Choose which tax groups you are interested in or create your own by clicking': 'Оберіть, які податкові групи вас цікавлять або самому створіть натиснувши',
  'New tax rate': 'Нова податкова ставка',
  'Create a tax rate': 'Створити податкову ставку',
  'Tax rates have been successfully saved': 'Податкові ставки успішно збережені',
  'Access token': 'Токен доступу',
  'Available terminals': 'Доступні термінали',
  'KATOTTG Code (Same as outlet address)': 'Код КАТОТТГ (має відповідати адресі обраної торгової точки окрім назви вулиці)',
  'Add EPZ data': 'Додати дані ЕПЗ',
  'Change EPZ data': 'Змінити дані ЕПЗ',
  'EPZ data': 'Дані ЕПЗ',
  'Card mask': 'Маска картки',
  'Auth code': 'Код авторизації',
  RRN: 'RRN',
  'Payment system': 'Платіжна система',
  'Owner name': 'Ім\'я власника',
  Terminal: 'Термінал',
  'Turnover settings': 'Налаштування обороту',
  'Turnover setting': 'Налаштування обороту',
  'Day number': 'Номер дня',
  'Last send': 'Останнє відправлення',
  Tag: 'Тег',
  'The number of tax rates should not exceed 8. Delete unnecessary ones or correct already created ones': 'Кількість податкових ставок не повинна перевищувати 8. Видаліть непотрібні або відкорегуйте вже створені',
  'Deadline overdue': 'Термін прострочено',
  'You have not completed your application within 24 hours. The application can be deleted in the "Actions" menu': 'Ви не закінчили подання заявки протягом 24 годин. Заявку можна видалити в меню "Дії"',
  'Delete employee': 'Видалити касира',
  'Specify balances for new products': 'Вказати залишки для нових товарів',
  'This value is already in use': 'Це значення вже використовується',
  'Set SSI setting': 'Встановити SSI налаштування',
  'Are you sure you want to install SSI settings?': 'Ви впевнені, що хочете встановити SSI налаштування?',
  'SSI setup successfully installed': 'Налаштування SSI успішно встановлено',
  'Send invoice reminder': 'Нагадування про рахунок',
  News: 'Новина',
  'Connecting the portal with the terminal!': 'Поєднання порталу з терміналом!',
  'We have the possibility of combining the portal with your terminal for sales using acquiring': 'У нас є можливість поєднання порталу з вашим терміналом для продажів з використанням еквайрингу',
  'Remind me later': 'Нагадати пізніше',
  'Don\'t remind me anymore': 'Більше не нагадувати',
  'More details': 'Детальніше',
  'Set labels': 'Додати мітки',
  'Set label for organization': 'Встановити мітку для організації',
  Labels: 'Мітки',
  'New label': 'Нова мітка',
  'Add label': 'Створити мітку',
  'Labels successfully set for organization': 'Мітки успішно додані до організації',
  Merchants: 'Мерчанти',
  Merchant: 'Мерчант',
  'Unlink terminal': 'Відв\'язати термінал',
  Unlink: 'Відв\'язати',
  'Terminal successfully unlinked': 'Термінал успішно відв\'язано',
  'Receipt number': 'Номер чеку',
  'Activate sales restrictions': 'Активувати обмеження продажу',
  'Time limits for the sale of alcoholic products:': 'Обмеження часу для продажу алкогольної продукції:',
  'Download receipts': 'Завантажити чеки',
  'Wait for the archive to be created': 'Зачекайте, поки буде сформовано архів',
  'It may take a few minutes': 'Це може зайняти декілька хвилин',
  'No data available': 'Немає даних',
  'The application for the use of acquiring has been successfully deleted': 'Заява на використання еквайрингу успішно видалена',
  'Do you really want to delete the acquiring application?': 'Ви дійсно бажаєте видалити заяву на використання еквайрингу?',
  'Send by Email/SMS': 'Відправити Email/SMS',
  'Date activated': 'Дата активації',
  'Date created in SFS': 'Дата створення в ДПС',
  'Cancel receipt': 'Відміна чеку',
  'Select type of receipt cancellation': 'Оберіть спосіб відміни чеку',
  'Cancel receipt by cashier': 'Відмінити чек касиром ',
  'Receipt canceled successfully': 'Чек успішно відмінено',
  'Signatory position': 'Посада підписанта',
  'Created reports': 'Створені звіти',
  'Creating report': 'Створення звіту',
  'Prices/Discounts': 'Ціни/знижки',
  Discounts: 'Знижки',
  Prices: 'Ціни',
  'Added by': 'Додано',
  'Offline status': 'Статус офлайн',
  'Active  ': 'Активні',
  Archived: 'Архівні',
  Downloaded: 'Завантажено',
  'This report will be based on the supply of goods created during the specified time period.': 'Даний звіт будуватиметься за постачанням товару, створеним у зазначений період часу.',
  'That is, if you specify a range for a week - the report will include goods written off from deliveries that were created during this week.': 'Тобто, якщо ви вкажете діапазон за тиждень - до звіту увійдуть товари списані з поставок, які створені протягом цього тижня. Фінансовий звіт не відобразить всіх проданих товарів за цей період (як це робить звіт по товарах), якщо вони списувалися з поставок, які не увійшли до цього інтервалу дат (наприклад, створених раніше).',
  'View in DPS': 'Переглянути у ДПС',
  'Change (money)': 'Решта',
  Shares: 'Акції',
  'Terminal from Oschadbank in your smartphone': 'Термінал в вашому смартфоні від Ощадбанку',
  'Not now': 'Не зараз',
  'Come back': 'Повернутись назад',
  Step: 'Крок',
  'Await a call from bank': 'Очікуйте дзвінка від Банку',
  'The application for the provision of acquiring services has been sent!': 'Заяву на надання послуг еквайрингу надіслано!',
  'Tap2Phone from Oschadbank': 'Tap2Phone від Ощадбанк',
  'Terminal in your smartphone': 'Термінал у вашому смартфоні',
  'Accept card payments on your smartphone': 'Приймайте оплати картами на смартфон',
  'Connect Tap to Phone from Oschadbank': 'Підключайте термінал у смартфоні від Ощадбанк',
  'POS-terminal from Abank': 'POS-термінал від Абанку',
  'You get modern banking terminal on Android with checkbox app downloaded': 'Ви отримаєте сучасний банківський термінал на Android зі встановленим додатком Checkbox',
  'Terms of use of the terminal from ABank:': 'Умови використання терміналу від АБанк:',
  'Install Abank24 app': 'Встановіть застосунок ABank24',
  'Install mobile app and go through registration': 'Встановіть мобільний застосунок та пройдіть реєстрацію',
  'The process will not be rejected, we will inform you about the bank`s decision': 'Процес не буде відхилено, ми вас проінформуємо про рішення банку',
  'Close window': 'Закрити вікно',
  'Get bank`s terminal and start working': 'Отримайте термінал від банку та починайте працювати',
  'As soon as the bank makes a decision, you will receive a notification on': 'Як тільки банк прийме рішення, ви отримаєте сповіщення на',
  service: 'Послуга',
  'Connect Abank terminal from checkbox cabinet': 'Підключайте термінал Абанк з кабінету Checkbox',
  'Without visiting a bank branch': 'Без відвідування відділення банку',
  'Connect terminal from Oschadbank to your smartphone': 'Підключайте термінал у смартфоні від Ощадбанк',
  'View cash register activation logs': 'Переглянути логи активації каси',
  'Cash register activation logs': 'Логи активації каси',
  'From mode': 'З статусу',
  'To mode': 'В статус',
  'Current cash register price': 'Поточна ціна (за касу)',
  'New price per cash register': 'Нова ціна (за касу)',
  'Add new price': 'Додати нову ціну',
  'Add new discount': 'Додати нову знижку',
  'Deactivate discount': 'Деактивувати знижку',
  'Discount (in percentage)': 'Знижка у відсотках (%)',
  Open: 'Відкрити',
  'All products': 'Усі товари',
  Image: 'Зображення',
  'Upload an image to illustrate the folder': 'Завантажте зображення, щоб проілюструвати папку',
  'Upload an image to illustrate the product': 'Завантажте зображення, щоб проілюструвати товар',
  'PNG or JPG (recommendation 150×100px)': 'PNG або JPG (рекомендація 150×100px)',
  'Your contacts which we are going to send to bank, please check information': 'Контакти які ми передаємо в банк, будь ласка перевірте',
  'Print ': 'Друк',
  'Print settings': 'Налаштування друку',
  'Product code': 'Код товару',
  'Product name': 'Назва товару',
  'Price tags quantity': 'Кількість етикеток',
  'It is necessary to select at least one field': 'Необхідно обрати мінімум одне поле',
  'One label each': 'По одній етикетці',
  'Total quantity in stock': 'Загальна кількість на складі',
  'Specify the quantity': 'Вказати кількість',
  'Payment SMS': 'Платіж смс',
  'Payments SMS': 'Платежі смс',
  'Transfer date': 'Дата зарахування на рахунок',
  'Search by phone number, ettn numbers': 'Пошук по телефону, накладним',
  'Renew key': 'Оновити ключ',
  'Your application is on verification. It can take some time.': 'На ваш вказаний номер телефону було надіслано сповіщення, для продовження реєстрації. Якщо сповіщення не прийшло на ваш номер телефону, будь ласка, зверніться до служби підтримки Checkbox.',
  'Nothing found': 'Нічого не знайдено',
  'Check if request is correct and try again': 'Перевірте правильність написання запиту в пошуку та повторіть заново',
  'The product is not tied to the selected outlet. If you continue, we will automatically link the product to this outlet': 'Товар не привʼязаний до обраної торгової точки. Якщо ви продовжите, ми автоматично привʼяжемо товар до цієї торгової точки',
  'Unlock the transition to CBM': 'Розблокувати перехід в СВМ',
  Unlock: 'Розблокувати',
  'Transition to CBM successfully unlocked': 'Перехід до CBM успішно розблоковано',
  'Go to Check by mono': 'Перейти до Check by mono',
  'Report for mall': 'Звіт для ТРЦ',
  'Receipt size': 'Розмір чеку',
  'Date of generation': 'Дата генерації',
  'Auto-reporting': 'Авто-звітування',
  'Add report': 'Додати звіт',
  'Report name': 'Назва звіту',
  'Report period': 'Період звіту',
  'For day': 'За день',
  'Report settings': 'Налаштування звіту',
  Link: 'Посилання',
  Copy: 'Копіювати',
  Subscribers: 'Підписники',
  'Add subscriber': 'Додати підписника',
  'Send automatically': 'Надсилати автоматично',
  'The report will be sent to subscribers automatically': 'Звіт буде надсилатися підписникам автоматично',
  Host: 'Хост',
  Directory: 'Директорія',
  'For two days': 'За два дні',
  'For three days': 'За три дні',
  'Edit report': 'Редагувати звіт',
  'Report on all FOP': 'Звіт по всім ФОП',
  'Report successfully deleted': 'Звіт успішно видалено',
  'Report successfully created': 'Звіт успішно створено',
  'Report successfully updated': 'Звіт успішно відредаговано',
  'FTP report settings saved successfully': 'FTP налаштування звіту успішно збережені',
  'E-mail report settings saved successfully': 'E-mail налаштування звіту успішно збережені',
  'File sent successfully': 'Файл успішно відправлено',
  'An error occurred while generating the report file': 'При генерації файлу звіту виникла помилка',
  'Are you sure you want to delete report?': 'Ви дійсно бажаєте видалити звіт?',
  'Send report ': 'Надіслати звіт',
  'The file is downloading, please wait': 'Завантаження файлу, будь ласка, зачекайте',
  'Only offline receipts': 'Тільки оффлайн чеки',
  'Can`t create receipt for eTTN order with status done': 'Накладна з цим номером вже фіскалізована',
  'Can`t create receipt for eTTN order with status created': 'Накладна з цим номером вже фіскалізована',
  'Can`t create receipt for eTTN order with status cancelled': 'Накладна з цим номером вже фіскалізована',
  'Can`t create receipt for eTTN order with status done_without_sms': 'Накладна з цим номером вже фіскалізована',
  'Can`t create receipt for eTTN order with status returned': 'Накладна з цим номером вже фіскалізована',
  Prepayment: 'Передоплата',
  Prepayments: 'Передоплати',
  'Prepayment sum': 'Сума передоплати',
  Postpayment: 'Післяоплата',
  'Total to pay, ₴': 'Всього до сплати, ₴',
  'Prepayment sum, ₴': 'Сума передоплати, ₴',
  'Postpayment sum, ₴': 'Сума післяоплати, ₴',
  'Make prepayment': 'Зробити передоплату',
  'Make postpayment': 'Зробити післяоплату',
  ettnAfterPaymentError: 'Для даного замовлення вже створено експрес-накладну.',
  monoAfterPaymentError: 'Для даного замовлення вже створено замовлення з номером {acquiringNumber}.',
  Pay: 'Сплатити',
  'Postpayment sum': 'Сума післяоплати',
  Previous: 'Попередній',
  'Next one': 'Наступний',
  'Order number': 'Номер замовлення',
  'Search by order number': 'Пошук по номеру замовлення',
  'Output the type of object of taxation in the check': 'Виводити тип об\'єкту оподаткування у чек',
  'Set EasyPay settings': 'Встановити налаштування EasyPay',
  'EasyPay settings': 'Налаштування EasyPay',
  'EasyPay key': 'Ключ EasyPay',
  'The request was created successfully': 'Запит створено успішно',
  'EasyPay settings have been saved successfully': 'Налаштування EasyPay успішно збережені',
  'Groups of goods': 'Групи товарів',
  'Search by groups': 'Пошук по групам',
  'Search by goods': 'Пошук по товарам',
  'You do not have settings groups yet': 'У вас поки що немає груп',
  'Replace key': 'Замінити ключ',
  'Unfortunately, you do not have the rights to replace the cashier`s key. Please contact your account administrator': 'У вас, на жаль, немає прав для заміни ключа касира. Зверніться, будь ласка, до адміністратора вашого акаунта',
  'Cashier`s key with login': 'Ключ касира з логіном',
  'Cashier message reminder': 'Ключ касира з логіном {login} дійсний ще {days} днів і закінчиться {endDate}. Будь ласка, замініть ключ завчасно, щоб безперервно працювати з касою.',
  'Please replace the EDS key to continue working with the cash register.': 'Будь ласка, замініть ключ, щоб надалі працювати з касою.',
  'This cashier`s EDS key has expired': 'Термін дії ключа ЕЦП даного касира закінчився',
  'Days left': 'Залишилось {daysLeft} днів',
  'Cashier`s EDS key has expired message reminder': 'Ключ касира з логіном {login} був чинний до {endDate}. Будь ласка, замініть ключ, щоб надалі працювати з касою.',
  'Choose a convenient payment method, enter the amount and click "Add"': 'Оберіть зручний спосіб оплати,  вкажіть суму та натисніть “Додати”',
  'Other payment type': 'Інший спосіб оплати',
  'Add new type': 'Додати новий спосіб',
  'Payments by IBAN': 'Платежі по IBAN',
  Appointment: 'Призначення',
  'To issue a receipt, please confirm below that the buyer has made a bank transfer': 'Щоб видати чек, будь ласка, підтвердьте нижче, що покупець зробив переказ на рахунок',
  'To pay, the client needs to scan this QR code': 'Для оплати клієнту потрібно відсканувати даний QR-код',
  'Add account number': 'Додати номер рахунку',
  'Payment for goods and services': 'Оплата за товари та послуги',
  'Transfer received': 'Переказ отримано',
  'Copy payment link': 'Копіювати посилання на оплату',
  'Copy details': 'Копіювати реквізити',
  'Iban is not connected in Acquiring page': 'Не підключений IBAN в Еквайрингу',
  'You can use QR generation for IBAN payments by going to the Acquiring page': 'Ви можете використати генерацію QR для платежів по IBAN, перейшовши у розділ Еквайринг',
  'Go to Acquiring': 'Перейти в Еквайринг',
  'IBAN of the account': 'IBAN рахунку',
  'Account name': 'Назва рахунку',
  'Enter account number': 'Вкажіть номер рахунку',
  'Add your current account number, we will pull all other bank details automatically': 'Додайте номер свого розрахункового рахунку, всі інші банківські реквизити ми підтянемо автоматично',
  'An error occurred when sending the cashier to the DPS. Please try again or contact an administrator': 'Виникла помилка при відправленні касира до ДПС. Спробуйте ще раз або зв\'яжіться з адміністратором',
  'Iban has successfully added!': 'Iban реквізити успішно додано!',
  'Iban has successfully updated!': 'Iban реквізити успішно оновлено!',
  'Iban has successfully deleted!': 'Iban реквізити успішно видалено!',
  'Add your current account number, which will be used for IBAN payment': 'Додайте номер свого розрахункового рахунку, який буде використовуватись при оплаті за IBAN',
  'QR acquiring': 'QR еквайринг',
  'Add QR acquiring': 'Додати QR еквайринг',
  Terminals: 'Термінали',
  'Email was updated': 'Email було оновлено',
  'Setup email': 'Вказати email',
  'Auto payment by card has been enabled': 'Автоматичну оплату картою увімкнено',
  'Not enough funds': 'Недостатньо коштів',
  'There are not enough funds on your balance to send SMS': 'На вашому балансі недостатньо коштів для відправлення SMS',
  'You can continue issuing the check, but the SMS will not be sent': 'Ви можете продовжити видачу чека, але SMS не буде відправлено',
  'Top up the balance': '↑  Поповнити баланс',
  'Are you sure you want to delete QR settings?': 'Ви дійсно бажаєте видалити налаштування QR?',
  'Are you sure you want to delete Internet settings?': 'Ви дійсно бажаєте видалити оплату за посиланням?',
  'Internet setup successfully deleted': 'Оплату за посиланням успішно видалено',
  'Pay by link': 'Оплата за посиланням',
  'Add pay by link': 'Додати оплату за посиланням',
  'Edit QR acquiring': 'Редагування QR еквайрингу',
  'Edit pay by link': 'Редагування оплати за посиланням',
  'QR acquiring has been successfully saved': 'QR еквайринг успішно підключений',
  'Internet acquiring has been successfully saved': 'Оплата за посиланням успішно підключена',
  'QR acquiring settings updated': 'Налаштування QR еквайрингу успішно відредаговано',
  'Internet acquiring settings updated': 'Налаштування оплати за посиланням успішно відредаговано',
  'Select qr code for cash register by default': 'Оберіть QR код для оплати за замовчуванням',
  'Select QR cash registers:': 'Оберіть QR для каси:',
  'QR cash register': 'QR каси',
  'Now you have access to use new type of payment from clients "Payment by link"': 'Тепер вам доступний новий вид прийому оплат від клієнтів за допомогою “Оплати за посиланням”',
  'Now you have access to use new type of payment from clients QR-code': 'Тепер вам доступний новий вид прийому оплат від клієнтів за допомогою QR-коду.',
  Understand: 'Зрозуміло',
  'QR setup successfully deleted': 'Налаштування QR успішно видалено',
  'Other options': 'Інші способи',
  'A valid DepositSign key': 'Діючий ключ DepositSign',
  'Read key': 'Зчитати ключ',
  'Check the read key information': 'Перевірте інформацію про зчитаний ключ',
  RNOCPP: 'РНОКПП',
  Certificates: 'Сертифікати',
  'EDS (DSTU 4145)': 'ЕЦП (ДСТУ 4145)',
  'Key distribution protocols (DSTU 4145)': 'Протоколи розподілу ключів (ДСТУ 4145)',
  'The certificate issued': 'Сертифікат виданий',
  'Serial number': 'Серійний номер',
  'The certificate is valid': 'Сертифікат чинний',
  from: 'з',
  to: 'до',
  'Come up with the cashier\'s password and login': 'Вигадайте пароль та логін касира',
  'Electronic signature of the director': 'Підпис ЕЦП директора',
  'We could not read the key data. Please try again or choose a different key': 'Ми не змогли зчитати дані ключа. Будь ласка, спробуйте ще раз або оберіть інший ключ',
  'An error occurred while signing the document. Please try again or choose a different key': 'Виникла помилка під час підписання документу. Будь ласка, спробуйте ще раз або оберіть інший ключ',
  'The request to DPS was successfully sent': 'Запит у ДПС успішно відправлено',
  'Request sent successfully': 'Запит успішно відправлено',
  'Wait for a response about the change in the registration status of the cashier key': 'Очікуйте відповідь про зміну статусу реєстрації ключа касира',
  'You have successfully created a cashier': 'Ви успішно створили касира',
  'You were already downloading the director\'s EDS signature as part of the session, so we took it from the cache. Wait for the operation to complete': 'Ви вже завантажували підпис ЕЦП директора в рамках сесії, тому ми взяли його з кешу. Очікуйте завершення операції',
  'Load another key': 'Завантажити інший ключ',
  Recommended: 'Рекомендовано',
  'Yes (recommended)': 'Так (рекомендуємо)',
  'DepositSign data': 'Дані DepositSign',
  Fiscalize: 'Фіскалізувати',
  'Enter the data of the EPZ manually': 'Ввести дані ЕПЗ вручну',
  'Specify a new EDS key for signing checks': 'Вкажіть новий ключ ЕЦП для підпису чеків',
  'After successful registration, the status of the new cashier will change to "Active", and the old one will be deactivated': 'Після успішної реєстрації - статус нового касира зміниться на "Активний", а старого буде деактивовано',
  'Generating a DepositSign key': 'Створення ключа DepositSign',
  Acquire: 'Еквайр',
  'EasyPay settings have been updated successfully': 'Налаштування EasyPay успішно оновлено',
  'Edit EasyPay settings': 'Редагування EasyPay налаштувань',
  'The cash register is offline. To deactivate the cash register, contact the 3rd line of support for cash withdrawal online': 'Каса знаходиться в режимі офлайн. Щоб деактивувати касу, зверніться на 3 лінію підтримки для виводу каси в онлайн',
  'Select the EDS key of the director': 'Оберіть ключ ЕЦП директора',
  'Payment service': 'Платіжний сервіс',
  'No available QR codes were found for the selected terminal. Contact the banks support': 'За обраним терміналом не знайдено доступних QR-кодів. Зверніться в підтримку банку',
  'Create tag': 'Створити тег',
  'Create new tag': 'Створити новий тег',
  'Tag name': 'Назва тегу',
  'Manager name': 'Ім`я менедрежа',
  'Tag has been created successfully!': 'Тег успішно створено!',
  'Click or scan QR code to enter': 'Натисніть або відскануйте QR код для входу',
  'Unfortunately, you don`t have any terminals available yet. Go to Monobank`s office and register your first terminal.': 'На жаль, у вас ще немає доступних терміналів. Перейдіть у кабінет Монобанку, та зареєструйте свій перший термінал.',
  'Scan QR-code with phone camera or monobank app': 'Відскануйте QR-код камерою телефону або у застосунку Monobank.',
  'Important!': 'Важливо!',
  'App credentials should be the same as FOP data of user': 'Додаток має належати ФОП до якого ви намагаєтесь підключити еквайринг.',
  Revoke: 'Відв\'язати',
  'If you made a mistake with the FOP account when scanning the QR, unlink your current monobank account': 'Якщо ви помилились кабінетом ФОП при скануванні QR, відв\'яжіть ваш поточний акаунт monobank',
  'Choose bank which you want to connect and click "Continue"': 'Оберіть банк, який ви хочете підключити, та натисніть "Продовжити"',
  'Click or scan QR-code to login and follow instructions': 'Натисніть або відскануйте QR-код для входу та дотримуйтесь інструкцій',
  'This window allows you to choose the cash register and terminal you are connected to, or unlink your mono account and re-connect': 'Це вікно дозволяє вам обрати касу та термінал, до якої ви підключені, або відв\'язати свій обліковий запис моно та підключити заново',
  'Unfortunately, you don`t have any terminals available yet': 'На жаль, у вас ще немає доступних терміналів',
  'Go to Monobank`s office and register your first terminal': 'Перейдіть у кабінет Монобанку, та зареєструйте свій перший термінал',
  'Token has been revoked successfully': 'Токен успішно відв\'язано',
  'Copy key in EasyPay account and choose cash register you want to connect internet acquiring': 'Скопіюйте ключ у особистому кабінеті EasyPay, та оберіть касу для якої необхідно підключити інтернет еквайринг',
  'Connect your Tap to Phone to the Checkbox system or register a new one': 'Підключіть ваш термінал у смартфоні до системи Checkbox або зареєструйте новий',
  'Tap to phone has been successfully saved': 'Термінал у смартфоні успішно підключений',
  'Now you have a new way of accepting payments from customers using Tap to Phone': 'Тепер вам доступний новий вид прийому оплат від клієнтів за допомогою терміналу у смартфоні',
  'Check you contact data, which goes to bank and click "Send"': 'Перевірте контактні дані ваші, які ми передаємо в банк та натисніть “Надіслати”',
  'Contacts data': 'Контактні дані',
  'The bank will contact you within 2 days. Visit a bank branch to sign all the necessary documents.': 'Банк з Вами звʼяжеться протягом 2-х днів. Відвідайте відділення банку, щоб підписати всі необхідні документи.',
  'After that, you will be able to configure the connection on the web portal in the "Acquiring" section and use the service!': 'Після цього ви зможите налаштувати підключення на веб-порталі в розділі "Еквайринг" та користуватися послугою!',
  'This window allows you to specify a login for acquiring, select available terminals to which cash registers are connected': 'Це вікно дозволяє вам вказати логін для еквайрингу, обрати доступні термінали до якої підключені каси',
  'These documents are required for authorization without coming to bank': 'Ці документи необхідні для того, щоб підтвердити особу без відвідування банку',
  'Download acceptable document formats *.pdf *.jpg *.png *.doc': 'Завантажте документи форматі *.pdf *.jpg *.png *.doc',
  'This window allows you to check the cash register and the terminal you are connected to, or unlink the mono account and reconnect': 'Це вікно дозволяє вам обрати касу та термінал, до якої ви підключені, або відв\'язати свій обліковий запис моно та підключити заново',
  'This window allows you to change the cash register for the terminal you are connected to': 'Це вікно дозволяє вам змінити касу до якої підключений даний термінал',
  'This window allows you to change EasyPay key': 'Це вікно дозволяє вам змінити ключ EasyPay',
  'Bank date': 'Банківська дата',
  'Signing the application with a digital electronic key': 'Підпис заяви ключем ЕЦП',
  'Signing the contract with an electronic digital signature key': 'Підпис договору ключем ЕЦП',
  signPlugin: {
    sync: '<p>Щоб синхронізувати дані з ДПС, потрібно підписати запит ключем ЕЦП</p><p>Ви можете зробити це декількома способами: за допомогою файлового ключа ЕЦП або через SmartID</p>',
    requestToSfs: '<p>Підготували запит в ДПС, потрібно його підписати ключем ЕЦП</p><p>Ви можете зробити це декількома способами: за допомогою файлового ключа ЕЦП або через SmartID</p>',
    request: '<p>Підготували запит, який потрібно підписати ключем ЕЦП</p><p>Ви можете зробити це декількома способами: за допомогою файлового ключа ЕЦП або через SmartID</p>',
    syncDataWithSfs: 'Синхронізувати дані з ДПС',
    directorSign: 'Це вікно використовується для накладання підпису ФОП/директора ТОВ, власника акаунту Checkbox',
    smartId: '<p>Натисність або зчитайте QR-код сканером у застосунку Приват24 та дотримуйтесь інструкцій</p><p>Зверніть увагу, що сканування QR-коду відбувається декілька разів</p>',
    readOrgDataFromSfs: 'Щоб не вводити дані про компанію вручну, ми отримаємо їх з податкової. Оберіть зручний для вас спосіб підпису та дотримуйтесь інструкцій',
    edsWindow: 'Це вікно використовується для накладання підпису ЕЦП',
    employeeFileKey: 'Файловий ключ ЕЦП касира',
    addOrg: 'Додавання організації'
  },
  'Branch setup': 'Налаштування філії',
  'Name of the main organization': 'Найменування головної організації',
  'ITN of the main organization': 'ІПН головної організації',
  'Branch number': 'Номер філії',
  'EDRPOU code of the main enterprise': 'ЄДРПОУ головної організації',
  'Branch office info successfully deleted!': 'Інформацію про філію успішно видалено!',
  'Branch office info successfully updated!': 'Інформацію про філію успішно оновлено!',
  'Branch office info successfully created!': 'Інформацію про філію успішно створено',
  'Tap to Phone': 'Термінал у смартфоні',
  'You already have a signed contract, are you sure you want to sign another one?': 'У Вас вже є підписаний договір, Ви впевнені що хочете додати ще?',
  'Send to manual check': 'Відправити на ручну перевірку',
  'eTTN was successfully sent for manual check': 'eTTN було успішно відправлено на ручну перевірку',
  'First, add a point of sale': 'Спершу додайте торгову точку',
  'You have one or more identical excise stamps in the product': 'Ви маєте одну або декілька однакових акцизних марок в товарі',
  'You have one or more identical excise stamps in the receipt': 'Ви маєте одну або декілька однакових акцизних марок в чеку',
  'Balance type': 'Тип баланса',
  Demo: 'Демо',
  'If you need a tax invoice for the main organization': 'Якщо потрібно податкову накладну на головну організацію',
  'Individual tax number of the main organization': 'Індивідуальний податковий номер головної організації',
  'The numerical number of the branch registered with the DPS is indicated': 'Зазначається числовий номер філії зареєстрований у ДПС',
  'Tax number of the taxpayer of the main organization': 'Податковий номер платника податку головної організації',
  'Edit information': 'Редагувати інформацію',
  'To change the tax rate, it is necessary to reopen the change': 'Для зміни податкової ставки, необхідно перевідкрити зміну',
  'The amount of the discount cannot be greater than the amount of the check': 'Сума знижки не може бути більшою за суму чеку',
  'The amount of the discount cannot be greater than the price of the product': 'Сума знижки не може бути більшою за ціну товару',
  'Discount value can not be negative': 'Значення знижки не може бути від\'ємним',
  'Are you sure you want to unlink terminal?': 'Ви впевнені, що бажаєте від’єднати термінал?',
  registrationPromotionText: 'Бажаєте приєднатися до акції "Переходьте з РРО на Checkbox. Безкоштовно хоч 7 років!"?',
  Promotional: 'Акційна',
  RRO: 'РРО',
  'Promotional cash register': 'Акційна каса',
  'Promotional cash registers': 'Акційні каси',
  'Action "Replacements of RRO"': 'Акція "Заміни РРО" 🔥',
  'Action "Replacements of RRO" ': 'Акція "Заміни РРО"',
  'Select RSO': 'Обрати РРО',
  'Switch from RSO to Checkbox Free for at least 7 years!': 'Переходьте з РРО на Checkbox безкоштовно хоч 7 років!',
  'To participate in the promotion, you need to have active PPOs, which you can exchange for the opportunity to use Checkbox for free.': 'Щоб взяти участь в акції, вам потрібно мати активні РРО, які ви можете обміняти на можливість безкоштовного користування Checkbox.',
  'Take part in the promotion': 'Взяти участь в акції',
  'Take part': 'Взяти участь',
  'Choose the RSO that will take part in the promotion': 'Оберіть РРО, які візьмуть участь в акції',
  'We will automatically pull up all registered PPOs': 'Ми автоматично підтягнемо всі зареєстровані РРО',
  'Select cash registers': 'Оберіть каси',
  'you agree to the': 'ви погоджуєтесь з',
  'conditions of the promotion': 'умовами акції',
  'Select the PROs that will participate and agree to the terms of the promotion': 'Оберіть РРО, які візьмуть участь та погодьтеся з умовами акції',
  Chosen: 'Обрано',
  'Free to': 'Безкоштовна до',
  'We are creating new cash registers (PRRO)': 'Створюємо нові каси (ПРРО)',
  'Please do not close this window until the process is complete': 'Будь ласка, не закривайте це вікно до закінчення процесу',
  'The application for participation in the promotion has been created': 'Заявка на участь в акції створена',
  'The number of selected promotional cash registers': 'Кількість обраних акційних кас',
  'Bonus: After completing the settings, we have prepared a "Step-by-step guide for deactivating PPO" for you, which will be sent to your email.': '🎁 Бонус: Після завершення налаштувань ми підготували для вас "Покроковий гайд з деактивації РРО", який надійде на ваш email.',
  'Order created': 'Заявка створена',
  'The application has been created and is waiting to be sent to the DPS': 'Заявка створена та очікує відправки в ДПС',
  'On registration at the DPS': 'На реєстрації в ДПС',
  'Confirm sending the application for creating a cash register to the DPS': 'Підтвердіть відправку заявки на створення каси до ДПС',
  'Are you sure you want to refund prepayment receipt?': 'Ви впевнені, що бажаєте повернути чек передплати?',
  'EDS key has not full name.  Please, choose other EDS key': 'Ключ ЕЦП не містить ПІБ. Будь ласка, оберіть інший ключ',
  Platform: 'Платформа',
  'Unfortunately, you are not yet registered in the system': 'На жаль, ви ще не зареєстровані в системі',
  'Free for at least 7 years!': 'Безкоштовно хоч 7 років!',
  'Unfortunately, the public registers do not contain information about your RSO.': 'На жаль, в публічних реєстрах не знайдено інформації про наявні у вас РРО.',
  'Registration number of the object of taxation': 'Реєстраційний номер об\'єкту оподаткування',
  'VIN code': 'Номер кузова або номер шасі',
  'Quantity of SMS notifications': 'Кількість SMS для сповіщення',
  'Reminder about the end of SMS messages': 'Нагадування про закінчення смс повідомлень',
  'if the number of sms is less': 'якщо кількість смс менш як',
  'Admin users': 'Співробітники',
  'Admin user': 'Співробітник',
  'Add admin user': 'Додати співробітника',
  'Edit admin user': 'Редагування співробітника',
  'Employee successfully added': 'Співробітника успішно додано',
  'Employee edited successfully': 'Співробітника успішно відредаговано',
  'Employee deleted successfully': 'Співробітника успішно видалено',
  'Are you sure you want to delete the employee?': 'Ви дійсно бажаєте видалити співробітника?',
  'Print on the Android terminal': 'Роздрукувати на Android терміналі',
  'Display on the Android terminal': 'Відобразити на Android терміналі',
  'Print a check on the Android terminal (automatically)': 'Друкувати чек на Android терміналі (автоматично)',
  'Display the check on the Android terminal (automatically)': 'Відобразити чек на Android терміналі (автоматично)',
  'Edit RozetkaPay settings': 'Редагування RozetkaPay налаштувань',
  'RozetkaPay settings have been updated successfully': 'Налаштування RozetkaPay успішно оновлено',
  'RozetkaPay settings have been saved successfully': 'Налаштування RozetkaPay успішно збережені',
  'Enter the login and password to connect to Internet acquiring from RozetkaPay': 'Вкажіть логін та пароль для підключення інтернет еквайрингу від RozetkaPay',
  'This window allows you to change your RozetkaPay login and password': 'Це вікно дозволяє вам змінити логін та пароль RozetkaPay',
  Stay: 'Залишитись',
  Courier: 'Кур\'єр',
  Contact: 'Контакт',
  Delivery: 'Доставка',
  'Additional roles': 'Додаткові ролі',
  'Delivery order': 'Доставка замовлення',
  'Create an order': 'Сформувати замовлення',
  'Create a delivery': 'Створити доставку',
  'Accept payment now': 'Оплата зараз',
  'Add courier details': 'Додайте дані для кур\'єра',
  'Add customer details': 'Додайте дані про клієнта',
  'Accept payment on delivery': 'Оплата при доставці',
  'Enter data for courier delivery': 'Вкажіть дані для кур\'єрської доставки',
  'Delivery order successfully deleted': 'Замовлення на доставку видалено',
  'Permissions was successfully updated': 'Дозволи були успішно оновлені',
  'Create a delivery order for this sale': 'Створити замовлення на доставку цього продажу',
  'Are you sure you want to delete delivery order?': 'Ви впевнені, що хочете видалити замовлення на доставку?',
  'Are you sure you want to cancel delivery order?': 'Ви впевнені, що хочете скасувати замовлення на доставку?',
  'The delivery order has been successfully created': 'Замовлення на доставку успішно створено',
  'In this module, you will be able to create a delivery order that will be available to your couriers in the application': 'У цьому модулі ви зможете створити замовлення на доставку, яка буде доступна вашим кур\'єрам у застосунку',
  'A list of all your orders for which delivery was processed is available in the "Delivery" section': 'Список всіх ваших замовлень для яких оформлювалася доставка, доступна в розділі "Доставка"',
  'Add a comment for the courier': 'Додайте коментар для кур\'єра',
  'Choose a courier': 'Оберіть кур\'єра',
  'Delivery time': 'Час доставки',
  'Client name': 'Клієнт',
  'Connect without app': 'Підключити без додатку',
  'Enter monobank token': 'Введіть монобанк токен',
  'Mark as cash register refund': 'Відмітити як каса повернення',
  Confirmation: 'Підтвердження',
  'Are you sure you want to mark this cash register as a refund cash register?': 'Ви впевнені, що бажаєте позначити цю касу як касу повернення?',
  'Cash register marked as refund cash register': 'Каса відмічена як каса повернення',
  Republic: 'Республіка',
  'Report for the Republic shopping center': 'Звіт для ТРЦ Республіка',
  'Add a report for shopping centers': 'Додати звіт для ТРЦ',
  'If you have difficulty with the quality of printing a check, please use': 'Якщо у вас виникають складнощі із якістю друку чека, будь ласка, скористайтесь',
  'Instructions for setting parameters': 'Інструкцією з налаштування параметрів',
  'Vehicle license plate': 'Держномер',
  'Car number of vehicle license plate': 'Номерний знак транспортного засобу',
  'Edit vehicle license plate number': 'Редагувати держномер',
  'Add promo code rro share': 'Додати промокод "Перехід з рро"',
  'Backing up goods': 'Завантаження копії товарів',
  'Deleting all goods': 'Видалення всіх товарів',
  'Set GoodTires setting': 'Встановити GoodTires налаштування',
  'Are you sure you want to install GoodTires settings?': 'Ви впевнені, що хочете встановити GoodTires налаштування?',
  'GoodTires setup successfully installed': 'Налаштування GoodTires успішно встановлено',
  'Privatbank token': 'Приватбанк токен',
  'This window allows you to specify a terminal token and connect to cash register': 'Це вікно дозволяє вам вказати токен приватбанк терміналу та підключити до доступної каси',
  'Short periodic report': 'Спрощений звіт',
  'Are you sure to delete select goods': 'Ви впевнені, що хочете видалити обрані товари?',
  'Are you sure you want to delete the selected deliveries?': 'Ви впевнені, що хочете видалити обрані доставки?',
  'Deleting select goods': 'Видалення обраних товарів',
  'Deleting select deliveries': 'Видалення обраних доставок',
  'Selected goods successfully deleted': 'Обрані товари успішно видаленні',
  'Selected deliveries successfully deleted': 'Обрані доставки успішно видаленні',
  'Accounting automation': 'Автоматизація бухгалтерії',
  'Accounting automations': 'Автоматизація бухгалтерії',
  'Last executed at': 'Останній запуск',
  'Execution Count': 'Кількість запусків',
  'Acts file status': 'Статус файлів актів',
  'Sending tax bill status': 'Статус відправки ПН',
  'Tips from mono': 'Чайові від моно',
  'Select an employees': 'Виберіть співробітника',
  'Select an employee to tip': 'Виберіть співробітника для чайових',
  'Please note that orders in the "Completed" status will not be deleted.': 'Зверніть увагу, що замовлення в статусі "Виконано" не будуть видалені.',
  Skip: 'Пропустити',
  'Return the check via PayLink': 'Повернути чек за допомогою Android PayLink',
  'The return operation will be performed through the PayLink terminal': 'Операція повернення буде здійснена через Android POS-термінал з PayLink',
  'Fill out the form to receive the terminal': 'Заповніть форму для отримання терміналу',
  'You need to choose a value': 'Потрібно обрати значення',
  'Sending notifications': 'Відправлення сповіщень',
  'Order equipment': 'Замовити обладнання',
  'Order ': 'Замовити',
  'Leave your phone number. The manager will contact you to place the order within 5-10 minutes': 'Залиште ваш номер телефону. Менеджер звяжеться з вами для оформлення замовлення протягом 5-10 хв',
  'Your order request has been accepted. The manager will call you within 5-10 minutes!': 'Ваша заявка на замовлення прийнята. Менеджер зателефонує вам протягом 5-10 хв!',
  'Apply online': 'Подайте онлайн заявку',
  'Enter information about the company': 'Вкажіть інформацію про компанію',
  'Upload client documents': 'Завантажте документи клієнта',
  'Get a POS terminal without unnecessary visits to the bank': 'Отримайте POS-термінал від Checkbox by RozetkaPay',
  'In order not to go to the bank branch several times, we will now apply for a new POS terminal online': 'Лише декілька кліків – і новенький POS-термінал вже у вас. Це дозволить вам швидко почати приймати оплати картками без необхідності відвідувати банки чи фінансові установи',
  'Company data': 'Дані компанії',
  'Financial data': 'Фінансові дані',
  'up to UAH 400000': 'до 400 000 грн',
  'more than UAH 400000': 'більше 400 000 грн',
  'Number of employees': 'К-сть працівників',
  'Email for receipt of documents': 'Email для отримання актів',
  'IBAN of your account': 'IBAN вашого рахунку',
  'The amount of annual income from the report': 'Сума річного доходу зі звіту',
  'License number': 'Номер ліцензії',
  'You can specify multiple licenses using the "," sign': 'Ви можете вказати декілька ліцензій через знак ","',
  'I have a license': 'У мене є ліцензія (наприклад: на продаж алкоголю, тютюну тощо)',
  'My company is a newly established individual entrepreneur': 'Моя компанія — новостворена фізична особа підприємець',
  'Planned monthly turnover': 'Запланований місячний оборот',
  'For this you will need': 'Для цього потрібно буде',
  'Fill in the company data': 'Заповнити дані про компанію',
  'Download the necessary documents for the bank': 'Завантажити необхідні документи для отримання POS-терміналу',
  'Sign the application with an electronic signature': 'Підписати заявку електронним підписом',
  'This information is necessary for filling out the bank client questionnaire. Having completed information about your business allows you not to go to the bank': 'Ця інформація необхідна для складання анкети-клієнта. Наявність заповненої інформації про ваш бізнес дозволяє отримати POS-термінал онлайн',
  'These documents are necessary in order to confirm the identity without visiting the bank': 'Ці документи необхідні для того, щоб підтвердити особу та забезпечити безпеку',
  'Download documents in *.pdf *.jpg *.png *.doc *.docx format': 'Завантажте документи в форматі *.pdf *.jpg *.png *.doc',
  'The presence of uploaded financial documents increases the success of the application by 80% and removes additional questions from the bank': 'Наявність завантажених фінансових документів збільшує успішність заявки на 80%',
  'I have RNOCPP (IPN)': 'У мене є РНОКПП (ІПН)',
  'Type of passport': 'Вид паспорту',
  'Passport booklet': 'Паспорт-книжечка',
  'ID card': 'ID-картка',
  'Required ': 'Обовʼязкові',
  'A copy of all completed pages of the passport': 'Копія всіх заповнених сторінок паспорту',
  'A copy of both sides of the passport': 'Копія обох сторін паспорту',
  'A copy of the RNOCPP (IPN)': 'Копія РНОКПП (ІПН)',
  'If available': 'За наявності',
  'Extract from the register of taxpayers': 'Витяг з реєстру платника податків',
  'Copy of license/permit': 'Копія ліцензії/дозволу',
  'Upload financial documents': 'Завантажте фінансові документи',
  'Latest annual financial statements': 'Остання річна фінансова звітність',
  'Tax declaration and Appendix 1': 'Податкова декларація та Додаток 1',
  'Receipt No. 2 for the last annual report': 'Квитанція Nº2 по останній річній звітності',
  'Financial statements for the last reporting period': 'Фінансова звітність за останній звітний період',
  'Tax declaration': 'Податкова декларація',
  'Receipt No. 2 for the last reporting period': 'Квитанція Nº2 за останній звітний період',
  'The application has been successfully created': 'Заявка успішно сформована',
  'Good work, all that remains is to sign the completed application': 'Гарна робота, залишилося підписати сформовану заявку',
  'To do this, click "Sign" and sign using an electronic key (ECS/KEP)': 'Для цього натисніть "Підписати" і підпишіть за допомогою електронного ключа(ЕЦП/КЕП)',
  Sign: 'Підписати',
  'Ready to sign': 'Готово до підписання',
  'Continue the order': 'Продовжити замовлення',
  'You have already applied for a RozetkaPay terminal': 'Ви вже подавали заявку на отримання терміналу RozetkaPay',
  'Connect the payment via the checkout link': 'Підключити оплату за посиланням до каси',
  'Order payment via the link': 'Зареєструватися для отримання інтернет еквайрингу оплата за посиланням',
  'Click to view order status': 'Натисніть, щоб переглянути статус замовлення',
  'Application submission': 'Подача заявки',
  'The application is pending': 'Заявка на розгляді',
  'Expect a call to confirm delivery details': 'Очікуйте дзвінка для уточнення деталей доставки',
  'The application is approved': 'Заявка схвалена',
  'Order progress': 'Прогрес замовлення',
  'Order declined': 'Заявку відхилено',
  'Order error': 'Помилка заявки',
  'Extract from the EDDR regarding the place of registration': 'Витяг з ЄДДР щодо місця реєстрації',
  'The application for obtaining a POS terminal has been successfully submitted to the RozetkaPay': 'Заявка на отримання POS-термінала успішно створена',
  'Please wait, your application is under consideration, a RozetkaPay specialist will contact you within a day': 'Наразі вона знаходиться на розгляді, що може зайняти до 24 годин. Будь ласка, зачекайте, поки йде підтвердження. Ви завжди можете дізнатися статус заявки, натиснувши на її статус',
  Free: 'Безкоштовно',
  'Create a free EDS key in a few steps using the DepositSign cloud service.': 'Створіть безкоштовний ключ ЕЦП у кілька кроків за допомогою хмарного сервісу DepositSign.',
  '"Newly established company" means that the company is registered in the current year and has not yet submitted its annual financial statements': '"Новостворена компанія" - це означає, що компанія зареєстрована в поточному році і ще не подавала фінансову річну звітність',
  publicKeyIdVerificationError: 'Даний ключ не знайдено в кабінеті ДПС. Будь ласка, оберіть ключ яким ви підписуєте звіти в ДПС. У разі невірного вибору ви отримаєте помилку "Першого документа" та почнете процедуру заново',
  'Disable 100% commission (3 months - 100%)': 'Відключити нарахування 100% комісії (3 місяці - 100%)',
  'Order POS-terminal': 'Замовити POS-термінал',
  'Enter the license number for a certain type of business activity': 'Вкажіть номер ліцензії на здійснення певного виду підприємницької діяльності',
  'Enter the FOP account number': 'Вкажіть номер рахунку ФОП',
  'Please include your current account number that will be used to receive payments': 'Будь ласка, додайте номер свого розрахункового рахунку, який буде використовуватись для отримання оплат',
  'Confirm deletion': 'Підтвердити видалення',
  'All test data, including checks and changes, will be deleted': 'Всі тестові дані, включаючи чеки та зміни, будуть видалені',
  'Confirm order deletion from {date}': 'Підтвердіть видалення замовлення від {date}',
  'Order from {date}': 'Замовлення від {date}',
  'The order was successfully deleted': 'Замовлення успішно видалено',
  'Confirm deletion of express invoice {number}': 'Підтвердіть видалення експрес накладної {number}',
  'Express waybill {number}': 'Експрес накладна {number}',
  'The express waybill has been successfully deleted': 'Експрес накладну успішно видалено',
  'Confirm removal of tax rate "{symbol}"': 'Підтвердіть видалення податкової ставки "{symbol}"',
  'The tax rate has been successfully deleted': 'Податкову ставку успішно видалено',
  'Confirm deletion of user "{fullName}"': 'Підтвердіть видалення користувача "{fullName}"',
  'User deleted successfully': 'Користувача успішно видалено',
  'Are you sure you want to activate the employee?': 'Ви впевнені, що хочете активувати обраного користувача?',
  'Pay attention!': 'Зверніть увагу!',
  'It is necessary to first select VAT and then Excise. Otherwise, the tax calculation will be done incorrectly.': 'Необхідно спочатку обирати \'ПДВ\', а потім \'Акциз\'. Інакше прорахунок податку буде виконаний невірно.',
  'How to create a cash register?': 'Як створити касу?',
  'How to create a sales point?': 'Як створити торгову точку?',
  'Page under development': 'Сторінка у розробці',
  'Start of work': 'Початок роботи',
  'Description of the steps to start working with the software cash register': 'Опис кроків для початку роботи з програмною касою',
  'Video instruction': 'Відео інструкція',
  'Registration of a point of sale': 'Реєстрація торгової точки',
  'Cash register registration': 'Реєстрація каси',
  'Cashier registration': 'Реєстрація касира',
  'Cashier\'s key replacement': 'Заміна ключа касира',
  '"The first document is valid" error': 'Помилка "Діє перший документ"',
  'The most common mistakes during registration': 'Найпоширеніші помилки під час реєстрації',
  'Mobile application': 'Мобільний додаток',
  'Checkbox is a mobile application of software RSO. The main user of the application is the cashier. Access is via a personal login and password': 'Сheckbox – мобільний додаток програмного РРО. Основним користувачем додатку є касир. Доступ відбувається за персональним логіном та паролем',
  'For Android': 'Для Android',
  'Android and POS terminals': 'Android та POS-терміналів',
  Integrations: 'Інтеграції',
  'List of integrations': 'Список інтеграцій',
  'API integrations': 'Апі інтеграції',
  'Description of the Checkbox API': 'Опис API Checkbox',
  'How to register a point of sale': 'Як зареєструвати торгову точку',
  'How to register a cash register': 'Як зареєструвати касу',
  'How to register a cashier': 'Як зареєструвати касира',
  'You are already participating in the promotion. The number of debited RSO cash registers will be taken into account in the next invoice issued.': 'Ви вже приймаєте участь в акції. Кількість дективованих кас РРО буде врахована в наступному виставленому рахунку.',
  'How to replace the cashier\'s key': 'Як замінити ключ касиру',
  'Software RSO. Personal account': 'Програмний РРО. Особистий кабінет',
  'Add points of sale to the map': 'Додати торгові точки на мапу',
  'Cash register checks are signed by EDP.': 'Чеки програмної каси підписуються ЕЦП.',
  'A personal key or an electronic seal of the company can be used as an EDS.': 'В якості ЕЦП можуть використовуватись особистий ключ або електронна печатка компанії.',
  'If the cashiers do not yet have personal keys, you can order electronic company seals for them.': 'Якщо в касирів ще немає особистих ключів, ви можете замовити для них еклектронні печатки компанії.',
  'How to get EDS quickly': 'Як швидко отримати ЕЦП',
  'order from Deposit Sign is a cloud storage of EDS.': 'замовити в Deposit Sign – це хмарне зберігання ЕЦП.',
  'In case of using Deposit Sign, you will not need to install the Signing Agent, the keys will be stored in the cloud storage.': 'В разі використання Deposit Sign, вам не потрібно буде встановлювати Агент Підпису, ключі будуть зберігатись у хмарному сховищі.',
  'in Privat24': 'в Приват24',
  'in any other ACSC from': 'в будь-якому іншому АЦСК з',
  list: 'переліку',
  'Please select an organization tag to download invoices': 'Будь ласка, оберіть тег організації для завантаження рахунків',
  'Here you can see your balances for checkbox services and top up them': 'Тут ви можете бачити ваші баланси за послуги checkbox та поповнити їх',
  'Top up SMS balance': 'Поповнення рахунку SMS',
  'Dear user, we draw your attention to the fact that you pay the bank for the use of Checkbox services, together with the payment for the use of the bank\'s acquiring services.': 'Шановний користувач, звертаємо вашу увагу на те, що за використання сервісів Чекбокс ви сплачуєте банку, разом  з оплатою за використання банківских послуг з еквайрингу.',
  'Getting started with a cash register can seem like something difficult and "dangerous".': 'Початок роботи з касою може здаватися чимось складним і "штрафонебезпечним".',
  'In fact, there are just a few simple rules, following which you will be able to issue checks and not worry about checks and fines. We will list them below.': 'Насправді, є всього кілька простих правил, дотримуючись яких ви зможете видавати чеки і не турбуватися про перевірки і штрафи. Ми наведемо їх нижче.',
  'Here are some terms we will use': 'Ось декілька термінів, які ми будемо використовувати',
  'it is not a fiscal check intended to control the operation of the cash register. It shows all operations (totals) performed during the shift. The X-report can be taken at any time and in any number during working hours': 'це не фіскальний чек, призначений для контролю роботи каси. Він показує всі операції (підсумкові суми), проведені протягом зміни. Х-звіт можна знімати в будь-який час і в будь-якій кількості протягом робочого часу',
  'this is a fiscal summary document that must be generated on the same day when the shift was opened and done by 11:59 p.m. Forming the Z-report means completing the cashier shift and handing over the revenue. Only legal entities submit revenue to the company\'s cash register. If the shift was not opened, then there is no need to generate a Z-report every day': 'це фіскальний підсумковий документ, який повинен сформуватись в той же день коли зміна була відкрита та зробити до 23:59. Формування Z-звіту означає завершення касової зміни і здачу виручки. Виручку до каси підприємства здають тільки юридичні особи. Якщо зміна не була відкрита, то в такому випадку немає необхідності формувати Z-звіт кожен день',
  'Depositing and issuing cash': 'Внесення і видача готівки',
  'official (non-fiscal) operations when you deposit change at the cash register or collect cash': 'службові (нефіскальні) операції, коли ви вносите розмінні гроші в касу або інкасувати готівку',
  'fiscal memory report for any period since the start of operation of the settlement operations recorder': 'звіт фіскальної пам\'яті за будь-який період з початку експлуатації реєстратора розрахункових операцій',
  'Work with the cash register during the day': 'Робота з касою протягом дня',
  'At the beginning of the working day, make an X-report': 'На початку робочого дня зробіть X-звіт',
  'After the X report, check the value in the "Revenue" field': 'Після Х-звіту перевірте значення в полі "Виручка"',
  'In this column on the X-report there should be UAH 0.00, if the amount is not UAH 0.00, it means that no Z-report was made for the previous day. In this case, you need to make a Z-report': 'В цій графі на Х-звіті повинно бути 0.00 грн., якщо сума не 0.00 грн, значить, за попередній день не зроблено Z-звіт. У такому випадку потрібно зробити Z-звіт',
  'After that, you can issue checks': 'Після цього можна видавати чеки',
  'Rules of sale of goods': 'Правила продажу товару',
  'Issue receipts after each sale': 'Видавайте чеки після кожного продажу',
  'Refunds can be made using the fiscal number of the check. If the amount in the check is more than 100 hryvnias, a return must be made': 'Повернення можна зробити по фіскальному номеру чека. Якщо сума в чеку більше 100 грн, при проведенні повернення потрібно зробити',
  'act of return': 'акт повернення',
  'act of false return': 'акт помилкового повернення',
  'Closing the shift': 'Закриття зміни',
  'At the end of the working day (before 00:00), close the shift. After closing the shift, a Z-report will be generated for you. You can print or view in the menu: "Working changes"': 'В кінці робочого дня (до 00:00) закрийте зміну. Після закритя зміни Вам буде згенеровано Z-звіт. Ви зможете роздрукувати, або переглянути в меню: "Робочі зміни"',
  'Possible discrepancies/errors when working with the cash register': 'Можливі розбіжності/помилки при роботі з касою',
  'The amount in the cash register (the amount of sales) is more than in the X-report - perhaps a check was not issued. Check all checks in the "Check Archive" menu, if any check is missing, issue that check': 'Сума в касі (сума продажів) більше ніж у Х-звіті – можливо не видали чек. Перевірте всі чеки в меню «Архів чеків», якщо якогось чеку не вистачає, видайте цей чек',
  'The amount in the cash register (the amount of sales) is less than the amount in the X-report - an extra check may have been printed. If so, make a refund for the amount of the extra check': 'Сума в касі (сума продажів) менше ніж сума в Х-звіті – можливо надруковано зайвий чек. Якщо так – виконайте повернення на суму зайвого чека',
  'The same check is printed twice in a row, what should I do? Make a return on all items in the check': 'Один і той же чек роздрукований два рази поспіль, що робити? Зробіть повернення на всі позиції в чеку',
  'Rules for filling out KORO': 'Правила заповнення КОРО',
  'Just kidding:) When working with pRSO, it is not necessary to keep and fill in accounting books of settlement operations': 'Жарт:) Працюючи з пРРО, вести і заповнювати книги обліку розрахункових операцій не потрібно',
  'there are not enough funds in the cash register': 'в касі недостатньо коштів',
  'DPS is unavailable! Cash register works offline! For the first launch of the cash register, you need to get offline codes. Please repeat the operation in 20-30 minutes after the DPS is restored.': 'ДПС недоступний! Каса працює в офлайні! Для першого запуску каси необхідно отримати офлайн коди. Прохання повторити операцію через 20-30 хвилин після відновлення роботи ДПС.',
  'The token is not valid. Please generate a new token and specify it in the settings, or extend the validity of this token': 'Токен не дійсний. Будь ласка сгенеруйте новий токен та вкажіть його у налаштуваннях, або продовжіть термін дії цього токена',
  'The password for the test cashier is similar to his login': 'Пароль для тестового касира аналогічний його логіну',
  'Use this cashier to test issuing checks': 'Використовуйте цього касира для тестування видачі чеків',
  'Currently, the work of the DPS is not stable, therefore the check processing time has been increased. In this regard, there may be significant delays in issuing checks. We apologize for the temporary inconvenience.': 'Зараз робота дпс не стабільна тому збільшений час обробки чеку. В звʼязку з цим можуть бути значні затримки при видачі чеків. Приносимо вибачення за тимчасові незручності.',
  'A test mode has been added to the portal. Now all users have the opportunity to work with the test cash register and cashier. Go to the appropriate sections on the portal, and select the appropriate data marked test.': 'На порталі додано тестовий режим. Тепер у всіх користувачів з\'явилась можливість працювати з тестовою касою та касиром. Перейдіть у відповідні розділи на порталі, та виберіть відповідні дані з поміткою тест.',
  'Attention, new function!': 'Увага, нова функція!',
  'How to test': 'Як протестувати',
  'Owner\'s card': 'Картка власника',
  'Piece by piece': 'Поштучний',
  'Measurable ': 'Вимірний',
  'This organization has a promotional code': 'У даної організації є промокод',
  'Before starting the configuration, it is necessary to sign the contract with Nova Pay': 'Перед початком налаштування необхідно обов\'язково підписати договір із Nova Pay',
  'Go to the personal account of Nova Poshta - Settings - Security and generate an API key for integrations - Copy the generated key': 'Перейдіть в особистий кабінет Нової Пошти → Налаштування → Безпека та згенеруйте ключ API для інтеграцій → Скопіюйте створений ключ',
  'Done. If everything is configured correctly - the "Express invoices" button will appear in the "Sales" section': 'Готово 🙌 Якщо все налаштовано коректно - в розділі “Продаж” з\'явиться кнопка “Експрес накладні”',
  'Detailed instructions': 'Детальна інструкція',
  'To register a new cashier, click': 'Для реєстрації нового касира, натисніть',
  'and use the key of the director of your organization - "Add cashier" and download the key of your cashier.': 'та скористайтесь ключем директора Вашої організації → "Додати касира" та завантажте ключ Вашого касира.',
  'If your cashier\'s electronic key (seal) expires - get a new key (seal) at your ATM - click': 'Якщо у Вас закінчується строк дії електронного ключа (печатки) касира - отримайте новий ключ (печатку) у Вашому АЦСК → натисніть',
  'and use the key of the director of your organization. Next, click "Actions" (three dots) next to the required cashier - "Key replacement" and download the new key of your cashier.': 'та скористайтесь ключем директора Вашої організації. Далі натисніть "Дії" (три крапки) біля необхідного касира → "Заміна ключа" та завантажте новий ключ Вашого касира.',
  'Pay attention': 'Зверніть увагу',
  'creating an express waybill check does not work with subscription checks': 'створення чеку експрес накладної не працює із чеками передплати',
  'The amount of the check with discounts and allowances must completely match the amount specified in the created Nova Poshta invoice, and must also completely match the value of the Payment Control amount.': 'Сума чека зі знижками та надбавками має повністю збігатися із сумою, вказаною в створеній накладній Нової Пошти, а також повинна повністю збігатися із значенням суми Контролю оплати.',
  'Add a card for automatic debiting': 'Додайте карту для автоматичного списання коштів',
  'Payment for using the Checkbox service': 'Оплата за користування сервісом Checkbox',
  'Top-up amount': 'Сума поповнення',
  'Enter the top-up amount': 'Введіть суму поповнення',
  'The invoice has been generated': 'Рахунок сформовано',
  tel: 'тел',
  'Add a new price': 'Додайте нову ціну',
  'Add a new discount': 'Додайте нову знижку',
  'Deactivate the discount': 'Деактивуйте знижку',
  'This information is incomplete and not suitable for reporting': 'Дана інформація не повна та не підходить для подачі звітності',
  'Correct information is in the section': 'Коректна інформація знаходиться у розділі',
  'where you can generate a report for the selected period with all the data': 'де можна сформувати звіт за обраний період з усіма даними',
  minutes: 'хв',
  'Commission on the amount of transactions': 'Комісія від суми транзакцій',
  'Terminal rental': 'Оренда терміналу',
  'Commission on the amount of transactions for Android POS (with mobile application Checkbox)': 'Комісія від суми транзакцій за Android POS (з моб. додатком Checkbox)',
  'Android POS rental cost': 'Вартість оренди Android POS',
  'uah/month': 'грн/міс',
  'Download the app from': 'Завантажуйте застосунок з',
  'acquisition fee': 'комісія за еквайринг',
  'Application: Get a POS terminal': 'Заявка: Отримати POS термінал',
  IE: 'ФОП',
  'TIN/EDRPOU': 'ІПН/ЄДРПОУ',
  'If you have any questions, you can contact support at the number': 'Якщо у вас виникли питання, ви можете звернутись до підтримки за номером',
  'or in messengers': 'або в месенджери',
  'Copy of RNOKPP (TIN)': 'Копія РНОКПП (ІПН)',
  'Certificate of the tax authority on the assignment of the registration number of the taxpayer\'s registration card': 'Довідка податкового органу про присвоєння реєстраційного номеру облікової картки платника податків',
  'Passport/Document': 'Паспорт/Документ',
  'Passport of a citizen of Ukraine/passport of a citizen of a foreign state/identity document': 'Паспорт громадянина України/паспорт громадянина іноземної держави/документ, що посвідчує особу',
  'Extract from the Unified State Register': 'Виписка з Єдиного державного реєстру',
  'Description of the documents submitted by the applicant': 'Опис документів, що подаються заявником для проведення державної реєстрації в Єдиному державному реєстрі юридичних осіб, фізичних осіб - підприємців та громадських формувань з зазначенням коду доступу та реєстраційним номером справи',
  'Passports/documents': 'Паспорти/документи',
  'Passports/documents confirming the authority of the person': 'Паспорти/документи, що підтвреджують повноваження особи, що буде укладати договір від імені юридичної особи (протокол вищого органу управління юридичної особи або наказ про обрання/призначення керівника Партнера, довіреність), копія його паспорту/документу, що посвідчує особу фізичних осіб, які підлягають ідентифікації та додвідки податкового органу про присвоєння реєстраційного номеру облікової картки платника податків (за наявності)',
  'Get the opportunity to receive card payments on your smartphone. Send funds to any bank convenient for you.': 'Отримайте можливість отримувати платежі карткою на вашому смартфоні. Відправляйте кошти на будь-який зручний для вас банк.',
  'The lowest commission': 'Найнижча комісія',
  'for acquiring among all banks - 1.3%. No rent or other additional payments.': 'за еквайринг серед усіх банків - 1.3%. Жодної оренди чи інших додаткових платежів.',
  'The service is only available for Android mobile devices with NFC': 'Послуга доступна лише для мобільних пристроїв на Android з NFC',
  'I give my consent to the processing and transfer of personal data, including IE data and the name of the product, to FC "EVO" LLC (RozetkaPay)': 'Я даю згоду на обробку та передачу персональних даних, що включає в себе дані ФОП та найменування товару, до ТОВ "ФК ЕВО" (RozetkaPay)',
  'With EDS file key or secure media': 'Через файловий ключ ЕЦП або захищений носій',
  'Select the EDS key file (usually files with the extension DAT, ZS2, JKS) on your device or use a secure media': 'Оберіть файл ключа ЕЦП (зазвичай це файли з розширенням DAT, ZS2, JKS) на вашому пристрої або скористайтеся захищеним носієм',
  'With Diia.Signature': 'Через Дія.Підпис',
  'With SmartID': 'Через SmartID',
  'A request has been prepared in the DPS, it needs to be signed with the EDS key': 'Підготували запит в ДПС, потрібно його підписати ключем ЕЦП',
  'You can do this in several ways: with the EDS file key or with SmartID': 'Ви можете зробити це декількома способами: за допомогою файлового ключа ЕЦП або через SmartID',
  'To submit documents to the DPS, you must use the EDS "key of the first document"': 'Для подачі документів у ДПС необхідно використовувати ЕЦП “ключ першого документа”',
  'The key of the first document': 'Ключ першого документа',
  'this is the EDS key that was used to sign the first document (application, request, report, etc.) in the DPS': 'це ЕЦП ключ, яким був підписаний перший документ (заява, запит, звіт тощо) у ДПС',
  'Click or scan the QR code in the Privat24 application and follow the instructions': 'Натисніть або зчитайте QR-код сканером у застосунку Приват24 та дотримуйтесь інструкцій',
  'Please note that the QR code is scanned several times': 'Зверніть увагу, що сканування QR-коду відбувається декілька разів',
  'Scan the QR code with the scanner in the Action app and follow the instructions': 'Зчитайте QR-код сканером у застосунку Дія й дотримуйтесь інструкцій',
  'You already downloaded the director\'s EDS signature as part of the session, so we took it from the cache. Wait for the operation to complete': 'Ви вже завантажували підпис ЕЦП директора в рамках сесії, тому ми взяли його з кешу. Очікуйте завершення операції',
  'File carrier': 'Файловий носій',
  'flash disk, CD disk, SD card, etc': 'flash-диск, CD-диск, SD-карта тощо',
  'Protected carriers': 'Захищені носії',
  'electronic key Almaz-1K, Crystal-1, Hryada-301, ID card, etc': 'е.ключ Алмаз-1К, Кристал-1, Гряда-301, ID-карта тощо',
  'Type of private key carrier': 'Тип носія особистого ключа',
  'EDS service provider': 'Надавач послуги ЕЦП',
  'Personal key carrier': 'Носій особистого ключа',
  'Key protection password': 'Пароль захисту ключа',
  'Signature of the request to the DPS': 'Підпис запиту до ДПС',
  'Read the key via SmartID': 'Зчитати ключ через SmartID',
  'Signature via SmartID': 'Підпис через SmartID',
  'Read the key through Diia.Signature': 'Зчитати ключ через Дія.Підпис',
  'Signature via Diia.Signature': 'Підпис через Дія.Підпис',
  'EDS file key': 'Файловий ключ ЕЦП',
  'Determine automatically': 'Визначити автоматично',
  'The web signature library is not supported by your browser or OS': 'Бібліотека web-підпису не підтримується в вашому браузері або ОС',
  'The web signature library needs updating. Please install the update by': 'Бібліотека web-підпису потребує оновлення. Будь ласка, встановіть оновлення за',
  'link ': 'посиланням',
  'The web signature library requires the web extension to be installed. Please install the web extension by': 'Бібліотека web-підпису потребує встановлення web-розширення. Будь ласка, встановіть web-розширення за',
  'and refresh the page': 'та оновіть сторінку',
  'The web signature library needs to be installed. Please install the library by': 'Бібліотека web-підпису потребує встановлення. Будь ласка, встановіть бібліотеку за',
  'The field "Choose EDS key" must have a value': 'Поле "Оберіть ключ ЕЦП" повинно мати значення',
  'To add a cashier, you need the EDS key of the employee or his ID, and the “key of the first document”': 'Щоб додати касира, потрібен ЕЦП ключ співробітника або його ідентифікатор, та “ключ першого документа”',
  'If you don\'t have a cashier\'s key, you can': 'Якщо у вас немає ключа касира, то ви можете',
  'Generate a key via DepositSign': 'Створити ключ через DepositSign',
  'is a cloud-based service provider from DepositSign for Checkbox cashier registration and DepositSign check signing': 'це хмарний КЕП від DepositSign для реєстрації касира Checkbox та підпису чеків DepositSign',
  'Select the EDS key that will be used for cash register operation and check signing': 'Оберіть ключ ЕЦП, який буде використовуватись для роботи каси та підпису чеків',
  'To register, you need to specify the name of the cashier, as well as come up with a login and password, which will be used to log in to the cashier in the mobile application or on': 'Для реєстрації потрібно вказати назву касира, а також придумати логін і пароль, котрий буде використовуватись для входу касира в мобільний додаток або на',
  CheckboxSignature: 'CheckboxПідпис',
  'the key that is automatically used for fiscal operations (we recommend choosing "Yes")': 'ключ, який автоматично використовується для фіскальних операцій (рекомендуємо обирати “Так”)',
  'If you do not use CheckboxSignature on a secure cloud service, then you will need to run the key in the browser\'s temporary cache or use CheckboxSignature locally': 'Якщо не використовувати CheckboxПідпис на захищеному хмарному сервісі, тоді вам необхідно буде ключ запускати в тимчасовий кеш браузера або використовувати CheckboxПідпис локально',
  'the data must be taken from the DepositSign office': 'дані необхідно взяти з кабінету DepositSign',
  'To register, you need to specify the name of the cashier, phone number, and also come up with a login and password, which will be used to log in to the cashier in the mobile application or on': 'Для реєстрації потрібно вказати назву касира, номер телефону, а також придумати логін і пароль, котрий буде використовуватись для входу касира в мобільний додаток або на',
  'This window from the DPS is used for superimposing the signature of the FOP/Director of the LLC, the owner of the Checkbox account': 'Це вікно від ДПС, використовується для накладання підпису ФОПа/директора ТОВа, власника акаунту Checkbox',
  'To submit documents to the DPS, it is necessary to use the EDS "key of the first document"': 'Для подачі документів у ДПС, необхідно використовувати ЕЦП “ключ першого документа”',
  'If you already have a new cashier key, choose to read the data via EDS key': 'Якщо у вас вже є новий ключ касира оберіть зчитати дані через ключ ЕЦП',
  'Or create a DepositSign cloud key - the key will be generated automatically, avoiding the need to separately obtain and store a file EDS on a personal PC': 'Або створити хмарний ключ DepositSign – ключ буде створено автоматично, уникнувши необхідності окремо отримувати та зберігати файловий ЕЦП на особистому ПК',
  'Choose the method convenient for you and follow the instructions': 'Оберіть зручний для вас спосіб та дотримуйтесь інструкцій',
  'Choose a new EDS key that will be used for the operation of the cash register and signing of checks': 'Оберіть новий ключ ЕЦП який буде використовуватись для роботи каси та підпису чеків',
  'It is important that you enter a current phone number to which you can receive SMS messages and continue registration!': 'Важливо, щоб ви вказали актуальний номер телефону, на який ви можете отримати смс-повідомлення і продовжити реєстрацію!',
  'Your DepositSign key has been revoked, in order to continue with cashier registration, you must go through the key generation procedure again.': 'Ваш ключ від DepositSign був відкликаний, щоб продовжити реєстрацію касира, необхідно пройти процедуру створення ключа повторно.',
  'Confirm action': 'Підтвердити дію',
  'The request to change the key from DepositSign has been successfully submitted.': 'Заявку на зміну ключа від DepositSign було успішно подано.',
  d: 'д',
  h: 'г',
  m: 'хв',
  'If the file is large, the import may take several minutes': 'При великому обсязі файлу, імпорт може тривати кілька хвилин',
  Ok: 'Ок',
  'Start working on the portal': 'Розпочати роботу на порталі',
  'If you plan to issue a small amount of checks, you can do it right here in your personal account': 'Якщо ви плануєте видавати невелику кількість чеків, це можна робити просто тут, в особистому кабінеті',
  'Add a product or service to a section': 'Додайте товар чи послугу у розділі',
  'In the section': 'У розділі',
  'open the change and create a check': 'відкрийте зміну та створіть чек',
  'Download the mobile app': 'Завантажте мобільний додаток',
  'It will come in handy if you need to create checks away from your computer': 'Стане в нагоді, якщо вам потрібно створювати чеки далеко від комп\'ютера',
  'Install the Checkbox app': 'Встановіть додаток Checkbox',
  'Log in using the cashier login and password you created earlier': 'Авторизуйтесь за допомогою логіна та пароля касира, які створили раніше',
  'In the application settings, specify the cash register license key that was created in the menu': 'В налаштуваннях додатка вкажіть ключ ліцензії каси, яка була створена в меню',
  'Integrate with CRM and CMS': 'Інтегруйтесь з CRM та CMS',
  'You will be able to issue receipts simply from your CRM or CMS if it is in this list of ready integrations': 'Ви зможете видавати чеки просто зі своєї CRM або CMS, якщо вона є у цьому списку готових інтеграцій',
  'Integration with': 'Інтеграція з',
  'API connection': 'Підключення по API',
  'If there are no ready solutions for integration with your front-end system, you can create your own using the API': 'Якщо немає готових рішень по інтеграції з вашою фронт-системою, ви можете створити власне за допомогою API',
  'API specification': 'Специфікація API',
  'Interactive map useful map': 'Інтерактивна карта корисна мапа',
  'Other features Checkbox': 'Інші можливості Checkbox',
  'This means that you are not signing the application in the DPS with the main key of the director.': 'Це означає, що ви підписуєте заяву у ДПС не головним ключем директора.',
  'To avoid this error, there are two options': 'Щоб уникнути цієї помилки, є два варіанти',
  'Refresh the browser page, and then re-sign the application with a valid director\'s key, if available. This may be your previous, older key that you used to report to the DPS.': 'Оновити сторінку браузера, після чого заново підписати заяву чинним ключем директора, якщо він є в наявності. Це може бути ваш попередній, старіший ключ, який ви використовували для подачі звітності у ДПС.',
  'If the old key is not available, it is necessary to recall it by contacting the ATSSK in which you received the key and wait from 4 to 24 hours (in most cases, synchronization occurs after 00:00) and try to submit the application again, specifying the new key.': 'Якщо старого ключа немає в наявності, потрібно відкликати його, звернувшись до АЦСК в якому ви отримували ключ та зачекати від 4 до 24 годин (в більшості випадків синхронізація відбувається після 00:00) і спробувати подати заяву повторно, вказавши новий ключ.',
  'In order to revoke certificates, you need to use': 'Для того щоб відкликати сертифікати потрібно скористатись',
  'instruction ': 'інструкцією',
  'Read more': 'Читати далі',
  'We cannot receive information from the SMS service': 'Не можемо отримати інформацію з сервісу смс',
  'Specify the number of SMS for notification': 'Вкажіть кількість SMS для сповіщення',
  'Data for filling out and sending a tax invoice': 'Дані для заповнення та відправлення податкової накладної',
  'Payment method not selected. Choose a payment method': 'Спосіб оплати не обрано. Оберіть спосіб оплати',
  'The postpaid amount is greater than the postpaid amount for the product. Edit postpaid payments': 'Сума післяплати більша, ніж сума післяплати за товар. Відредагуйте післяплати',
  'Please note that according to Clause 1 of NBU Resolution No. 210 dated 06.06.2013, the maximum amount of settlements between an individual and an enterprise (entrepreneur) within one day is': 'Зверніть увагу, що згідно п. 1 постанови НБУ від 06.06.2013 р. №210, гранична сума розрахунків між фізичною особою та підприємством (підприємцем) протягом одного дня в розмірі',
  '50000 (fifty thousand)': '50000 (п’ятдесяти тисяч)',
  'hryvnias are prohibited': 'гривень заборонені',
  'You can use the mixed type of payment "Cash + Card"': 'Ви можете скористатись змішаним типом оплати "Готівка + Карта"',
  'Continue payment in cash': 'Продовжити оплату готівкою',
  'Partial payment': 'Часткова оплата',
  Paid: 'Сплачено',
  Canceled: 'Відмінений',
  'Your working outlets': 'Ваші працюючі торгові точки',
  'The application has been successfully sent': 'Заявка успішно відправлена',
  'Application for placement of retail outlets on the interactive map': 'Заявка на розміщення торгових точок на інтерактивній мапі',
  'You can change the name': 'Ви можете змінити назву',
  'We have determined the point on the map automatically, but you can change it': 'Ми визначили точку на карті автоматично, але ви можете змінити ії',
  'Field of activity': 'Сфера діяльності',
  Website: 'Вебсайт',
  Instagram: 'Інстаграм',
  Facebook: 'Фейсбук',
  'Brief description of the outlet': 'Короткий опис торгової точки',
  'For example, an Italian restaurant, a children\'s toy store': 'Наприклад, ресторан італійської кухні, магазин дитячих іграшок',
  'Unfortunately, we were unable to automatically determine the location of your outlet. Please select on the map': 'На жаль, ми не змогли автоматично визначити місцезнаходження вашої торгової точки. Оберіть, будь ласка, на карті',
  Products: 'Продукти',
  'Household goods': 'Побутові товари',
  Cars: 'Автомобілі',
  Tails: 'Хвостикам',
  Money: 'Гроші',
  Machinery: 'Техніка',
  Beauty: 'Краса',
  Post: 'Пошта',
  Sport: 'Спорт',
  Health: 'Здоровʼя',
  'Clothes, shoes': 'Одяг, взуття',
  VAT: 'ПДВ',
  'Excise duty': 'Акцизний збір',
  'Without VAT': 'Без ПДВ',
  'Receipt note': 'Відмітка про одержання',
  'stamp of the controlling authority': 'штамп контролюючого органу',
  'to the tax declaration of a single tax payer - an individual - an entrepreneur': 'до податкової декларації платника єдиного податку - фізичної особи - підприємця',
  'to the tax declaration of the payer of the single tax of the third group for the period of military and state of emergency in Ukraine': 'до податкової декларації платника єдиного податку третьої групи на період дії воєнного, надзвичайного стану в Україні',
  Statements: 'Відомості',
  'about the amount of the accumulated income of the insured persons and the amount of the accumulated single contribution': 'про суми нарахованого доходу застрахованих осіб та суми нарахованого єдиного внеску',
  Reportable: 'Звітна',
  'Reportable new': 'Звітна нова',
  'Registration number of the taxpayer\'s registration card or series (if available) and passport number': 'Реєстраційний номер облікової картки платника податків або серія (за наявності) та номер паспорта',
  'Surname, first name, patronymic (if available)': 'Прізвище, ім\'я, по батькові (за наявності)',
  'Tax (reporting) period': 'Податковий (звітний) період',
  'Code of the main type of economic activity': 'Код основного виду економічної діяльності',
  'The period of stay of an individual - an entrepreneur on the simplified taxation system': 'Період перебування фізичної особи - підприємця на спрощеній системі оподаткування',
  'Category code of the insured person': 'Код категорії застрахованої особи',
  From: 'З',
  To: 'По',
  'DETERMINATION OF THE AMOUNT OF ACCRUED INCOME OF THE INSURED PERSONS AND THE AMOUNT OF THE ACCRUED SINGLE CONTRIBUTION': 'ВИЗНАЧЕННЯ СУМ НАРАХОВАНОГО ДОХОДУ ЗАСТРАХОВАНИХ ОСІБ ТА СУМИ НАРАХОВАНОГО ЄДИНОГО ВНЕСКУ',
  'An independently determined amount of income for which a single contribution is calculated, taking into account the maximum amount': 'Самостійно визначена сума доходу, на яку нараховується єдиний внесок з урахуванням максимальної величини',
  'The size of the single contribution, percentage': 'Розмір єдиного внеску, відсоток',
  'Amount of accrued single contribution (column 3 x column 4)': 'Сума нарахованого єдиного внеску (графа 3 х графа 4)',
  'The amount of the single contribution payable to non-budget accounts, according to the data of the reporting (tax) period (column 2 x column 3) hryvnias, kopecks': 'Сума єдиного внеску, яка підлягає сплаті на небюджетні рахунки, за даними звітного (податкового) періоду (графа 2 х графа 3) грн, коп',
  '1 quarter': '1 квартал',
  'six months': 'півріччя',
  'three quarters': 'три квартали',
  year: 'рік',
  'The series (if available) and the passport number are indicated by natural persons': 'Серію (за наявності) та номер паспорта зазначають фізичні особи, які через релігійні переконання відмовляються від прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це відповідний контролюючий орган і мають відмітку в паспорті.',
  'The category code of the insured person is "6" - a natural person - an entrepreneur on the simplified taxation system.': 'Зазначається код категорії застрахованої особи "6" - фізична особа - підприємець на спрощеній системі оподаткування.',
  'The amount of the single contribution established by law is indicated.': 'Зазначається розмір єдиного внеску, встановлений законом.',
  'The given information is correct': 'Наведена інформація є вірною',
  'A natural person is a payer of a single contribution or an authorized person': 'Фізична особа – платник єдиного внеску або уповноважена особа',
  'Submitted and filled out by natural persons - entrepreneurs - payers of the single tax of the first - third groups': 'Подаються та заповнюються фізичними особами - підприємцями - платниками єдиного податку першої - третьої груп, відповідно до пунктів 296.2 та 296.3 статті 296 глави 1 розділу XIV Податкового кодексу України та які, є платниками єдиного внеску відповідно до пункту 4 частини першої статті 4 Закону України "Про збір та облік єдиного внеску на загальнообов\'язкове державне соціальне страхування". Додаток 1 не подається та не заповнюється зазначеними платниками, за умови дотримання ними вимог, визначених частинами 4 та 6 статті 4 Закону України "Про збір та облік єдиного внеску на загальнообов\'язкове державне соціальне страхування", що дають право на звільнення таких осіб від сплати за себе єдиного внеску. Такі особи можуть подавати Додаток 1 виключно за умови їх добровільної участі у системі загальнообов\'язкового державного соціального страхування.',
  'In case of submission of a new declaration with corrected indicators before the end of the deadline': 'У разі подання нової декларації з виправленими показниками до закінчення граничного строку подання декларації за такий самий звітний період зазначається тип декларації "Звітна нова". При цьому обов’язково заповнюються всі рядки та колонки розділу 9 Додатку 1 за період визначений у графі 8.',
  goodsReportWarning: 'Увага! Звіт по товарах не враховує знижки та надбавки на весь чек, тому загальна сума, порахована за цим звітом, буде відрізнятися від загальної суми, порахованої по Z-звітам на суму знижок і надбавок.',
  excisesReportWarning: 'Увага! Звіт з акцизів не враховує знижки та надбавки на весь чек, тому загальна сума, підрахована за цим звітом, відрізнятиметься від загальної суми, розрахованої за Z-звітами на суму знижок та надбавок',
  taxReports: {
    thirdFive: 'Податкова декларація платника єдиного податку – фізичної особи – підприємця',
    thirdTwo: 'Податкова декларація платника єдиного податку третьої групи на період дії воєнного, надзвичайного стану в Україні',
    second: 'Податкова декларація платника єдиного податку – фізичної особи – підприємця, у якого податковий (звітний) період рік'
  },
  TOTAL: 'УСЬОГО',
  'Field Retail outlet guilty mother meaning': 'Поле Торгова точка повинно мати значення',
  'Field Type PRRO guilty mother meaning': 'Поле Тип ПРРО повинно мати значення',
  'In order to take part in the promotion, you need to provide details of your organization. After pressing the button': 'Для того щоб прийняти участь в акції, вам необхідно вказати дані вашої організації. Після натискання кнопки',
  'You can immediately go to the organization section and synchronize data with STS': 'ви відразу можете перейти у розділ організації та синхронізувати дані з ДПС',
  'If you have a food shortage or need assistance, please contact our support service, contact details are listed on the Checkbox website.': 'Якщо виникли питання чи необхідна допомога, звертайтеся до нашої служби підтримки, контакти якої зазначені на сайті Checkbox.',
  'Alternatively, from now on you can go to the software registration section and sign up for a free trial period of 1 month.': 'Але ви завжди можете перейти у розділ реєстрації програмних кас та підключити безкоштовний тестовий період на 1 місяць.',
  'In order to take part in the promotion, you need to deactivate the old PPO by pulling': 'Для того щоб взяти участи в акції, потрібно деактивувати старі РРО протягом',
  '1 month from the date of registration of the software.': '1 місяця з дати реєстрації програмної каси.',
  'We automatically need to be able to safely deal with such a number of Checkbox software programs, as long as the PPO has been deactivated on the lines until the end of their term of action.': 'Ми автоматично надамо можливість безкоштовно користуватися такою кілкістю програмних кас Checkbox, скільки було деактивовано РРО на строк до кінця їх терміну дії.',
  'We found out in public registries that you have': 'Ми знайшли в публічних реєстрах, що у вас є',
  'active ': 'дійсний',
  'active  ': 'дійсні',
  yet: 'ще',
  'That is, you can use the free PRRO Checkbox': 'Тобто ви можете користуватись безоплатним ПРРО Checkbox',
  'After pressing the button': 'Після натискання кнопки',
  'you can immediately go to the registration section of software cash registers': 'ви відразу можете перейти у розділ реєстрації програмних кас',
  acting: 'діючий',
  'acting ': 'діючі',
  'acting  ': 'діючих',
  months: 'місяці',
  'months ': 'місяців',
  'Go to Checkbox!': 'Переходьте на Checkbox!',
  'Only until June 30': 'Тільки до 30 червня',
  'Free for at least 7 years': 'Безкоштовно хоч 7 років',
  'Switch to Checkbox, work for free until the end of the term of your PPO!': 'Переходьте на Checkbox, працюйте безкоштовно до кінця терміну використання вашого РРО!',
  'Become the superhero of your business and switch to Checkbox!': 'Станьте супергероєм свого бізнесу та переходьте на Checkbox!',
  'AT LEAST 7 YEARS!': 'ХОЧ 7 РОКІВ!',
  'Value your time and switch to Checkbox!': 'Цінуйте свій час та переходьте на Checkbox!',
  'Tired of waiting for a PRO repairman?': 'Набридло чекати майстра по ремонту РРО?',
  'Is the PRO constantly buggy?': 'РРО постійно глючить?',
  'Already fed up with PRO?': 'РРО вже набрид?',
  'Tired of PRO': 'Втомилися від РРО',
  'which is constantly breaking': 'який постійно ламається',
  'it\'s already a dinosaur': 'це вжеee динозавр',
  'Provide this link to the customer, after payment the check will be issued automatically. You can view the payment status in the "Acquiring" menu.': 'Надайте це посилання клієнту, після оплати чек буде видано автоматично. Переглянути статус оплати можна в меню "Еквайринг".',
  'Please wait': 'Зачекайте, будь ласка',
  'A check for the order has been created': 'Чек на замовлення створено',
  'with a POS terminal': 'через POS-термінал',
  Expecting: 'Очікується',
  'Follow the prompts in the terminal': 'Слідкуйте за підказками на терміналі',
  'Error when': 'Помилка при',
  returns: 'поверненні',
  'payment ': 'оплаті',
  'Check the terminal settings': 'Перевірте налаштування термінала',
  'Payment successful': 'Оплата успішна',
  'Issuance of a fiscal check': 'Видача фіскального чеку',
  'Error when issuing a check': 'Помилка при видачі чеку',
  'The fiscal check has been successfully created': 'Фіскальний чек успішно створений',
  'Connection with the terminal is lost': 'Зв\'язок з терміналом втрачено',
  'In order for you to be able to try the service before registering the cash register, we have prepared for you a demo access to the workplace so that you can evaluate the work in': 'Для того, щоб Ви змогли спробувати сервіс до реєстрації каси, ми підготували для вас демо доступ робочого місця, щоб ви змогли оцінити роботу в',
  'For this': 'Для цього',
  'Log in as a test cashier and cashier': 'Авторизуйтесь тестовим касиром та касою',
  'Add the product to the check': 'Додайте товар в чек',
  'Click "Issue check"': 'Натисніть "Видати чек"',
  Start: 'Почати',
  'To pay, the client needs to scan this QR code or use the QR code provided by Monobank.': 'Для оплати клієнту потрібно відсканувати даний QR код або скористатися QR кодом наданим Monobank.',
  'Payment is pending': 'Очікується оплата',
  'Cancel order': 'Скасувати замовлення',
  'Payment error': 'Помилка при оплаті',
  'The order has been cancelled': 'Замовлення скасовано',
  'The signature agent for the selected cashier is not running. To work with the checkout, start the signature agent': 'Агент підпису для вибраного касира не запущений. Для роботи з касою запустіть агент підпису',
  'Do you really want to make payment on': 'Ви дійсно бажаєте провести операцію на',
  hryvnas: 'гривень',
  'Add the goods to the receipt first': 'Додайте спочатку товари до чеку',
  'The size of the discount on goods exceeds the cost of the goods. Edit discounts': 'Розмір знижки на товари перевищує вартість товару. Відредагуйте знижки',
  'The amount of payment for the goods is less than the amount of the check. Edit payment': 'Розмір оплати за товари менший, ніж сума чеку. Відредагуйте оплату',
  'The amount of payment for goods is greater than the amount of the check. Edit payment': 'Розмір оплати за товари більший, ніж сума чеку. Відредагуйте оплату',
  'We are sorry, but the cashier\'s signature has been blocked': 'Нам шкода, але підпис касира був заблокований Вашим АЦСК. Потрібно отримати новий ключ (печатку) у АЦСК, далі натисніть "Дії" (три крапки) біля необхідного касира -> "Заміна ключа" або зареєструйте нового касира. Додатково пропонуємо скористатись швидкою та безкоштовною можливістю створення касира від наших партнерів DepositSign (кнопка “Додати касира”).',
  'The waiting time for issuing a check has been exceeded. After some time, check the check availability on the "Check Archive" page': 'Час очікування видачі чеку перевищено. Через деякий час перевірте наявність чеку на сторінці "Архів чеків"',
  Bonuses: 'Бонуси',
  'By details (IBAN)': 'За реквізитами (IBAN)',
  Cashless: 'Безготівкова',
  'Confirm deactivation': 'Підтвердити деактивацію',
  'Cashier {name} will be deactivated in DPS': 'Касир {name} буде деактивований в ДПС',
  'Head cashier': 'Старший касир',
  'Test cashier': 'Тестовий касир',
  'The key is launched on a secure cloud service': 'Ключ запущено на захищеному хмарному сервісі',
  'Termination of work': 'Припинення роботи',
  Registered: 'Зареєстровано',
  'Awaiting signature': 'Очікує підпису',
  Updating: 'Оновлення',
  Deletion: 'Видалення',
  'Receipt is pending': 'Очікується видача чека',
  'Canceled ': 'Відмінено',
  Expired: 'Закінчився термін дії',
  'This order has expired': 'Термін дії даного замовлення вичерпано',
  'Fiscalized without SMS': 'Фіскалізовано без смс',
  'Receipt returned': 'Чек повернуто',
  Fiscalized: 'Фіскалізовано',
  'Online Mode': 'Online-Режим',
  'Offline Mode': 'Offline-Режим',
  'waiting to be processed': 'чекає на обробку',
  'not scattered': 'не рознесений',
  counted: 'зараховано',
  'Data signature for key verification in STS': 'Підпис даних для верифікації ключа в ДПС',
  'WARNING! When using excise tax, it is absolutely necessary to issue a license for the sale of alcohol or tobacco.': 'УВАГА! При використанні акцизного податку, обов\'язково необхідно оформити ліцензію на продаж алкоголю чи тютюну.',
  'Partially canceled': 'Частково відмінений',
  'Saved ': 'Збережений',
  'Waiting to be processed': 'Чекає на обробку',
  Executed: 'Виконаний',
  apt: 'кв',
  created: 'створено',
  'pending signature': 'очікує підпис',
  signed: 'підписано',
  approved: 'схвалено',
  declined: 'відхилено',
  'error ': 'помилка',
  returned: 'повернено',
  'New ': 'Нове',
  Processed: 'Опрацьовано',
  'At work': 'В роботі',
  'Sent to STS': 'Відправлено до ДПС',
  'We are waiting for the processing of the report at the STS. After 5-10 minutes, click the button "Refresh data"': 'Очікуємо на обробку звіту у ДПС. Через 5-10 хвилин натисніть кнопку "Оновити дані"',
  Accepted: 'Прийнято',
  'Six months': 'Півріччя',
  'Three quarters': 'Три квартали',
  'Signature of the document to be sent to the DPS': 'Підпис документу для відправки в ДПС',
  'Signature of data received from DPS': 'Підпис отриманих даних від ДПС',
  'Encrypted data signature': 'Підпис шифрованих даних',
  'Super admin': 'Супер адмін',
  Administrator: 'Aдміністратор',
  'Emergency cashier': 'Аварійний касир',
  Accountant: 'Бухгалтер',
  'Billing manager': 'Білінг менеджер',
  'Partner manager': 'Партнер менеджер',
  'Client manager': 'Клієнтський менеджер',
  'Employee manager': 'Менеджер співробітників',
  'Accountant of automation': 'Бухгалтер автоматизації',
  'Accounting automation developer': 'Розробник автоматизації бухгалтерії',
  'Act from': 'Акт від',
  'from ': 'від',
  'Set payment settings': 'Встановити налаштування оплат',
  Launched: 'Запущено',
  'Needs verification': 'Потребує перевірки',
  'Critical startup error': 'Критична помилка при запуску',
  'It has errors when checking manually': 'Має помилки при ручній перевірці',
  'It has errors during automatic proofreading': 'Має помилки при автоматичній пепервірці',
  'No errors': 'Помилок не має',
  'Startup error': 'Помилка при запуску',
  'Added to queue': 'Додано в чергу',
  'Files generated': 'Файли згенеровано',
  'TI sent': 'ПН відправлено',
  'Specify the result of the check': 'Вказати результат перевірки',
  'Successfully set': 'Успішно встановлено',
  'Set status fixed': 'Встановити статус виправлено',
  'Set status to "Requires review" for this automation (date {date})?': 'Встановити статус "Потребує перевірки" для цієї автоматизації (дата {date})?',
  'Generate act files': 'Згенерувати файли актів',
  'Start generation of act files with date {date}?': 'Запустити генерацію файлів актів з датою {date}?',
  'Generation of acts has started': 'Генерацію актів розпочато',
  'Send tax invoices': 'Відправити податкові накладні',
  'Start start sending TI with date {date}?': 'Запустити запустити відправку ПН з датою {date}?',
  'Tax invoices have been sent': 'Відправку податкових накладних розпочато',
  'Launch details from': 'Деталі запуску від',
  'Results of generation of act files': 'Результати генерації файлів актів',
  'Results of sending TI': 'Результати відправки ПН',
  'Sent ': 'Відправлений',
  'Not a VAT payer': 'Не платник ПДВ',
  'Juridical person, VAT payer': 'Юридична особа, платник ПДВ',
  'IE, VAT payer with code': 'ФОП, платник ПДВ з кодом',
  'Tax number 9 characters': 'Податковий номер 9 знаків',
  'An ID card is used': 'Використовується ІД картка',
  'The bill of lading is not subject to provision': 'Накладна не підлягає наданню',
  'Bill of lading cannot be provided (HORIG1) (null/0/1)': 'Накладна не підлягає наданню (HORIG1) (null/0/1)',
  'The reason is not provided': 'Причина не надання',
  'Reason not provided (HTYPR, null/01/02)': 'Причина не надання (HTYPR, null/01/02)',
  'Send invoice': 'Відправити накладну',
  'Organization of the recipient': 'Організація отримувача',
  'Recipient\'s name': 'Ім\'я отримувача',
  'Bill of lading is not subject to provision (HORIG1)': 'Накладна не підлягає наданню (HORIG1)',
  'Bill of lading cannot be issued for reason (reason type HTYPR)': 'Накладна не підлягає наданню по причині (тип причини HTYPR)',
  'Id in the tax office': 'Id в податковій',
  'Paid ': 'Сплачений',
  'Not paid ': 'Не сплачений',
  'Partially paid': 'Сплачений частково',
  'Free of charge': 'Вільний від оплати',
  'Exempt from payment': 'Звільнити від оплати',
  'Are you sure you want to release the invoice from {date}?': 'Ви впевнені що хочете звільнити від оплати рахунок від {date}?',
  'The account has been successfully released from payment': 'Рахунок успішно звільнено від оплати',
  'An error occurred': 'Сталася помилка',
  'Test shift': 'Тестова зміна',
  'Add a label': 'Додати мітку',
  'Label successfully created': 'Мітка успішно створена',
  'To Email': 'На Email',
  'In the Vchasno service': 'В сервіс Вчасно',
  'In the Dubidoc service': 'В сервіс Dubidoc',
  'Continued by the call center': 'Продовжено колл центром',
  Frozen: 'Заморожено',
  'No cash registers': 'Немає кас',
  Stationary: 'Стаціонарний',
  Movable: 'Пересувний',
  'Self-service cash register': 'Каса самообслуговування',
  'Internet trade': 'Інтернет торгівля',
  outletStanObject: {
    construction: 'Будується / готується до введення в експлуатацію',
    operated: 'Експлуатується',
    tempNoOperation: 'Тимчасово не експлуатується',
    unusable: 'Непридатний до експлуатації',
    returnedToOwner: 'Об\'єкт відчужено / повернено власнику',
    repurposing: 'Зміна призначення / перепрофілювання',
    renting: 'орендується',
    forRent: 'Здається у оренду'
  },
  outletTypeOfRights: {
    premised: 'приміщення у власності',
    permanentUse: 'право постійного користування',
    forRent: 'приміщення в оренді'
  },
  'Repeat order': 'Повторити заявку',
  'Confirm the resending of the application to the STS': 'Підтвердіть повторну відправку заявки до ДПС',
  'New function': 'Нові функції'
}
